
























import { defineComponent, ref, watch } from '@vue/composition-api'
import ChooseNumber from '@/components/ChooseNumber/index.vue'

const InputNumberIncDec = defineComponent({
  components: {
    ChooseNumber
  },
  props: {
    value: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const showChooseNumber = ref(false)
    const valueInput = ref(props.value)
    watch(props, () => {
      valueInput.value = props.value
    })
    const update = (action: string, newValue: number) => {
      if (action === 'ok' || action === 'next' || action === 'prev') {
        valueInput.value = newValue
        emit('update', valueInput.value)
      }
      showChooseNumber.value = false
    }
    const inc = (): void => {
      valueInput.value += 1
      emit('update', valueInput.value)
    }
    const dec = (): void => {
      valueInput.value -= 1
      emit('update', valueInput.value)
    }

    return { showChooseNumber, valueInput, inc, dec, update }
  }
})

export default InputNumberIncDec
