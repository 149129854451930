































































































































































import { defineComponent, Ref, ref, computed, toRefs, ComputedRef } from '@vue/composition-api'
import { convertToWidthCharacter, endpoints, checkIsOnPC, constants } from 'utils'
import Vue from 'vue'
import { api } from 'plugins'
// avoid circular import (in same folder)
import LoaderComponent from '../LoaderComponent/index.vue'
import ItemDialog from '../ItemDialog/index.vue'
import VarietyDialog from '../VarietyDialog/index.vue'
import SizeDialog from '../SizeDialog/index.vue'
import CustomerDialog from '../CustomerDialog/index.vue'
import AddOrEditQualityDialog from '../QualityDialog/index.vue'
import AddOrEditUnitDialog from '../UnitDialog/index.vue'
import BoxTypeDialog from '../BoxTypeDialog/index.vue'

interface Property {
  id: number
  code: number
  name: string
  searchStr: string
}

interface SetUp {
  next: () => void
  prev: () => void
  query: Ref<string>
  searchItems: ComputedRef<Array<any>>
  selectItem: (item: Property) => void
  addMaster: (value: string) => void
  showItemDialog: Ref<boolean>
  itemProp: Ref<unknown>
  showVarietyDialog: Ref<boolean>
  varietyProp: Ref<unknown>
  showCustomerDialog: Ref<boolean>
  customerProp: Ref<unknown>
  showSizeDialog: Ref<boolean>
  sizeProp: Ref<unknown>
  showQualityDialog: Ref<boolean>
  qualityProp: Ref<unknown>
  showUnitDialog: Ref<boolean>
  unitProp: Ref<unknown>
  showBoxTypeDialog: Ref<boolean>
  boxTypeProp: Ref<unknown>
  currentValue: Ref<Property>
  firstPressUpDown: Ref<boolean>
  getNewData: (data: string) => void
  onTyping: (input: any) => void
  updateSearch: (input: any) => void
  HEIGHT_ITEM: number
  isOnPC: boolean
  textField: any
  deleteCurrentValue: () => void
}

const ChooseProperty = defineComponent({
  components: {
    LoaderComponent,
    ItemDialog,
    CustomerDialog,
    SizeDialog,
    AddOrEditQualityDialog,
    AddOrEditUnitDialog,
    VarietyDialog,
    BoxTypeDialog
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    currentItem: {
      type: Object,
      required: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    qualityGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    nurseryCompanies: {
      type: Array,
      required: false,
      default: () => []
    },
    customerGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    groupDataMaster: {
      type: Array,
      required: false,
      default: () => []
    },
    itemSelectVariety: {
      type: Number,
      required: false,
      default: 1
    },
    currentProperty: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit, refs, root }): SetUp {
    const { $store } = root
    const { items } = toRefs(props)
    const query = ref('')

    const showItemDialog = ref(false)
    const itemProp = ref({})
    const showVarietyDialog = ref(false)
    const varietyProp = ref({})
    const showCustomerDialog = ref(false)
    const customerProp = ref({})
    const showSizeDialog = ref(false)
    const sizeProp = ref({})
    const showQualityDialog = ref(false)
    const qualityProp = ref({})
    const showUnitDialog = ref(false)
    const unitProp = ref({})
    const showBoxTypeDialog = ref(false)
    const boxTypeProp = ref({})
    const currentValue = ref({} as unknown as Property)
    const positionScroll = ref(0)
    const HEIGHT_ITEM = 50
    const positionScrollUp = ref(4)
    const firstPressUpDown = ref(true)
    const isOnPC = checkIsOnPC()
    const textField: any = ref(null)

    const searchItems = computed(() => {
      const text = convertToWidthCharacter(query.value.trim().toLowerCase(), 'full')
      const searchResultStartsWith = items.value.filter((item: any) => {
        const searchStr = item?.searchStr || item?.name || ''
        return searchStr.toLowerCase().match(`^${text}|\\|${text}`)
      })
      const searchResultIncludes = items.value.filter((item: any) => {
        const searchStr = item?.searchStr || item?.name || ''
        return (
          searchStr.toLowerCase().includes(text) &&
          !searchStr.toLowerCase().match(`^${text}|\\|${text}`)
        )
      })
      const searchResult = [...searchResultStartsWith, ...searchResultIncludes]

      if (
        props.currentItem &&
        searchResult.findIndex((element: any) => element.id === props.currentItem?.id) > -1
      ) {
        const result = searchResult.filter((element: any) => element.id !== props.currentItem?.id)
        result.unshift(props.currentItem)
        return result
      }
      return searchResult.filter((item: any) => item !== null)
    })

    const selectItem = (item: Property) => {
      query.value = ''
      emit('on-selected', 'next', item)
      const scroller = refs.scroller as Vue
      scroller.$el.scrollTop = 0
      positionScrollUp.value = 4
      firstPressUpDown.value = true
      currentValue.value = {} as unknown as Property
    }

    const next = () => {
      query.value = ''
      if (Object.keys(currentValue.value).length) {
        selectItem(currentValue.value)
      } else {
        emit('on-change', 'next')
      }
      const scroller = refs.scroller as Vue
      scroller.$el.scrollTop = 0
      positionScrollUp.value = 4
      firstPressUpDown.value = true
      currentValue.value = {} as unknown as Property
    }

    const prev = () => {
      query.value = ''
      if (Object.keys(currentValue.value).length) {
        emit('on-selected', 'prev', currentValue.value)
      } else {
        emit('on-change', 'prev')
      }
      const scroller = refs.scroller as Vue
      scroller.$el.scrollTop = 0
      positionScrollUp.value = 4
      firstPressUpDown.value = true
      currentValue.value = {} as unknown as Property
    }

    const deleteCurrentValue = () => {
      currentValue.value = {} as unknown as Property
      emit('on-selected', 'delete', currentValue.value)
    }

    const getNewData = async (data: string) => {
      query.value = ''
      emit('reload', data)
    }

    const addMaster = async (value: string) => {
      console.log(props.currentProperty)
      switch (props.currentProperty) {
        case 'customer':
          showCustomerDialog.value = true
          customerProp.value = {
            name: value,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            shortName: null,
            tel: null,
            fax: null,
            email: null,
            invoiceEmail: null,
            code: null,
            zipCode: null,
            address1: null,
            address2: null,
            invoiceMethod: null,
            invoiceEmailDestination: null,
            allowPreSale: true,
            levelCustomer: 'market',
            theirCode: null
          }
          break
        case 'item':
          showItemDialog.value = true
          itemProp.value = {
            name: value,
            shortName: null,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            nameEng: null,
            defaultUnit: {},
            units: [],
            sizeGroup: 1,
            qualityGroup: 1
          }
          break
        case 'variety':
          showVarietyDialog.value = true
          varietyProp.value = {
            name: value,
            nameEng: null,
            orderNum: null,
            searchStr: null,
            shortName: null,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            item: props.itemSelectVariety,
            sizeGroup: 1,
            qualityGroup: 1
          }
          break
        case 'size':
          showSizeDialog.value = true
          sizeProp.value = {
            name: value,
            nameEng: null,
            orderNum: null,
            searchStr: null,
            shortName: null,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            sizeGroup: 1
          }
          break
        case 'quality':
          showQualityDialog.value = true
          qualityProp.value = {
            name: value,
            nameEng: null,
            orderNum: null,
            searchStr: null,
            shortName: null,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            qualityGroup: 1
          }
          break
        case 'unit':
          showUnitDialog.value = true
          unitProp.value = {
            name: value,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data,
            shortName: ''
          }
          break
        case 'boxType':
          showBoxTypeDialog.value = true
          boxTypeProp.value = {
            name: value,
            yomi: (await api.get(`${endpoints.TO_YOMI}?text=${value}`)).data
          }
          break
        default:
      }
    }
    const selectItemByKeyBoard = (keyCode: number) => {
      if (
        props.currentItem &&
        firstPressUpDown.value &&
        searchItems.value.includes(props.currentItem)
      ) {
        currentValue.value = props.currentItem as unknown as Property
        firstPressUpDown.value = false
      }
      const currentIndex = searchItems.value.findIndex(
        (element: any) => element.id === currentValue.value.id
      )
      if (
        (currentIndex === 0 && keyCode === 38) ||
        (currentIndex === searchItems.value.length - 1 && keyCode === 40)
      ) {
        // no change
        console.log('no change')
      } else if (currentIndex === -1) {
        if (searchItems.value.length > 0) {
          currentValue.value = searchItems.value[0] as unknown as Property
        } else {
          console.log('no change')
        }
      } else if (keyCode === 40) {
        // Press down
        currentValue.value = searchItems.value[currentIndex + 1] as unknown as Property
        positionScroll.value = (currentIndex - 2) * HEIGHT_ITEM
        positionScrollUp.value = 4
      } else if (keyCode === 38) {
        // Press up
        currentValue.value = searchItems.value[currentIndex - 1] as unknown as Property
        if (positionScrollUp.value > 1) {
          positionScrollUp.value -= 1
        }
        positionScroll.value = (currentIndex - positionScrollUp.value) * HEIGHT_ITEM
      }
      // Scroll to position
      const scroller = refs.scroller as Vue
      scroller.$el.scrollTop = positionScroll.value
    }
    const onTyping = (event: any) => {
      if (event.keyCode === 27) {
        // Press ESC
        emit('input', false)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        next()
      } else if (event.keyCode === 13 && event.shiftKey) {
        // Press shift + Enter
        prev()
      } else if (event.keyCode === 39) {
        // Press next
        // next()
      } else if (event.keyCode === 37) {
        // Press previous
        // prev()
      } else if (event.keyCode === 40 || event.keyCode === 38) {
        // Press down or up
        selectItemByKeyBoard(event.keyCode)
      } else {
        firstPressUpDown.value = false
      }
    }
    const updateSearch = () => {
      if (searchItems.value.length > 0) {
        currentValue.value = searchItems.value[0] as unknown as Property
      } else {
        currentValue.value = {} as unknown as Property
      }
    }

    return {
      next,
      prev,
      query,
      searchItems,
      selectItem,
      addMaster,
      showItemDialog,
      itemProp,
      showVarietyDialog,
      varietyProp,
      showCustomerDialog,
      customerProp,
      showSizeDialog,
      sizeProp,
      showQualityDialog,
      qualityProp,
      showUnitDialog,
      unitProp,
      getNewData,
      showBoxTypeDialog,
      boxTypeProp,
      onTyping,
      currentValue,
      HEIGHT_ITEM,
      firstPressUpDown,
      isOnPC,
      updateSearch,
      textField,
      deleteCurrentValue
    }
  }
})

export default ChooseProperty
