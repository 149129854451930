import { api } from 'plugins'
import { toCamelCase, endpoints } from 'utils'

interface State {
  info: Record<string, unknown> | null
}

export const user = {
  state: {
    info: null
  },
  mutations: {
    setInfoState(state: State, value: Record<string, unknown>): void {
      state.info = value
    },
    resetInfoState(state: State): void {
      state.info = null
    }
  },
  actions: {},
  getters: {
    async getUserInfo(state: State): Promise<Record<string, unknown> | null> {
      if (!state.info) {
        const { data: response } = await api.get(`${endpoints.MEMBER}current`)
        state.info = toCamelCase(response)
      }

      return state.info
    }
  }
}
