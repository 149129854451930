





























































































































import { defineComponent, Ref, ref, computed, ComputedRef } from '@vue/composition-api'
import { CommonProperty, DeliveryState, Customer, OrderType } from 'typings'
import { defaultObject, formatNumber } from 'utils'
import { snakeCase } from 'lodash'

import CreateCommonForm from '../CreateCommonForm/index.vue'
import ChooseNumber from '../ChooseNumber/index.vue'
import ChooseProperty from '../ChooseProperty/index.vue'
import SelectInputRow from '../SelectInputRow/index.vue'
import SingleDetailItem from '../SingleDetailItem/index.vue'

interface SetUp {
  numberDialog: Ref<boolean>
  dialog: Ref<boolean>
  modal: Ref<boolean>
  setPropertyName: Ref<string>
  chooseProperty: (property: string) => void
  choosePropertyItems: Ref<Array<CommonProperty>>
  selectProperty: (action: string) => void
  update: (action: string, value: CommonProperty | number) => void
  remove: () => void
  getPropertyDialogTitle: () => string
  getNumberDialogTitle: () => string
  searchCustomer: (item: Customer, queryText: string, itemText: string) => boolean
  showCustomerItemText: (item: Customer) => string
  totalAmount: ComputedRef<string>
  getNewData: (mode: string) => void
}

const DeliveryContainer = defineComponent({
  components: {
    ChooseNumber,
    ChooseProperty,
    CreateCommonForm,
    SelectInputRow,
    SingleDetailItem
  },
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    delivery: {
      type: Object,
      required: true
    },
    packingResult: {
      type: Object,
      required: true
    },
    customers: {
      type: Array,
      required: true
    },
    customerGroups: {
      type: Array,
      required: true
    },
    orderTypes: {
      type: Array,
      required: true
    }
  },
  setup(props, { root, emit }): SetUp {
    const { $store } = root
    const numberDialog = ref(false)
    const dialog = ref(false)
    const modal = ref(false)
    const setPropertyName = ref('')
    const choosePropertyItems = ref<Array<CommonProperty>>([])

    const { delivery, index, customers, orderTypes, packingResult } = props
    const nextStates: DeliveryState = defaultObject.deliveryDetailNextState
    const prevStates: DeliveryState = defaultObject.deliveryDetailPrevState
    const nextProperty = computed(() => {
      const key = setPropertyName.value
      const next = nextStates[key as keyof DeliveryState]
      if (
        delivery.orderType &&
        delivery.orderType.id === 1 &&
        (next === 'buyerInfo' || next === 'price')
      ) {
        return nextStates[next]
      }
      return next
    })
    const prevProperty = computed(() => {
      const key = setPropertyName.value
      const prev = prevStates[key as keyof DeliveryState]
      if (
        delivery.orderType &&
        delivery.orderType.id === 1 &&
        (prev === 'buyerInfo' || prev === 'price')
      ) {
        return prevStates[prev]
      }
      return prev
    })

    const chooseProperty = (property: string): void => {
      setPropertyName.value = property
      dialog.value = false
      modal.value = false
      numberDialog.value = false

      switch (property) {
        case 'auctionDate':
          modal.value = true
          break
        case 'customer':
          choosePropertyItems.value = customers as Array<Customer>
          dialog.value = true
          break
        case 'buyerInfo':
          numberDialog.value = true
          break
        case 'bundleNo':
          numberDialog.value = true
          break
        case 'orderType':
          choosePropertyItems.value = orderTypes as Array<OrderType>
          dialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        case 'stems':
          numberDialog.value = true
          break
        case 'price':
          numberDialog.value = true
          break
        default:
          break
      }
    }
    const selectProperty = (action: string): void => {
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      } else if (action === 'clickOk') {
        dialog.value = false
        modal.value = false
        numberDialog.value = false
      }
    }
    const getPropertyDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}.title`) as string
    }
    const getNumberDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}`) as string
    }

    const update = (action: string, value: CommonProperty | number) => {
      const key = setPropertyName.value
      delivery[key] = value

      // update stems if user change boxes number
      if (key === 'boxes') {
        delivery.stems = (Number(packingResult.quantity) || 0) * (Number(delivery.boxes) || 0)
      }

      emit('on-updated', delivery, index)
      selectProperty(action)
    }
    const remove = () => {
      emit('on-removed', index)
    }
    const searchCustomer = (item: Customer, queryText: string, itemText: string): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return itemText.trim().toLowerCase().indexOf(searchText) > -1
    }
    const showCustomerItemText = (item: Customer): string => {
      const { code, name } = item
      return code ? `[${code}] ${name}` : name
    }
    const totalAmount = computed(() => {
      return formatNumber.formatCurrency(
        (parseInt(delivery.stems, 10) || 0) * (parseInt(delivery.price, 10) || 0)
      )
    })

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      const newData = $store.state.common.newDataMaster
      emit('update-item-select-variety', 1)
      if (mode === 'customer') {
        listID = customers.map((item: any) => Number(item.id))
        newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
        if (newId.length === 0) {
          customers.push({ ...newData })
        }
      }
      chooseProperty(setPropertyName.value)
      update('next', newData)
    }

    return {
      numberDialog,
      dialog,
      modal,
      setPropertyName,
      chooseProperty,
      choosePropertyItems,
      selectProperty,
      getPropertyDialogTitle,
      getNumberDialogTitle,
      update,
      remove,
      searchCustomer,
      showCustomerItemText,
      totalAmount,
      getNewData
    }
  }
})

export default DeliveryContainer
