




































































import { defineComponent } from '@vue/composition-api'

const MultiLevelList = defineComponent({
  props: {
    data: {
      type: Array,
      required: true
    },
    levels: {
      type: Array,
      required: false,
      default: () => ['itemName', 'varietyName', 'qualitySizeName']
    },
    multiDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    treeviewLevel: {
      type: Number,
      required: false,
      default: 4
    }
  },
  setup(props, { emit }) {
    const handleChangeRootPanel = (rootPanel: Array<number>): void => {
      const data = { ...props.value, panels: rootPanel }
      emit('input', data)
    }

    const handleChangePanelLevelPanel = (key: string, panel: Array<number>): void => {
      const data = { ...props.value, [key]: panel }
      emit('input', data)
    }

    return { handleChangeRootPanel, handleChangePanelLevelPanel }
  }
})

export default MultiLevelList
