





























import { defineComponent, ref, Ref, watch, getCurrentInstance } from '@vue/composition-api'

import { api } from 'plugins'
import { endpoints } from 'utils'
import _ from 'lodash'

import ListMember from '../ListMember/index.vue'
import MemberEditDialog from '../MemberEditDialog/index.vue'

const MasterCustomerMember = defineComponent({
  components: {
    ListMember,
    MemberEditDialog
  },
  props: {
    customerID: {
      types: Number || undefined,
      required: true
    }
  },
  setup(props, { emit }) {
    const listMember = ref([] as any) // list to display all member of each customer
    const show = ref(false) // check to show dialog
    const isAdd = ref(true) // check create/edit case
    const dialogValue = ref({}) // 1 item obj has been choose

    const setNewMember = (members: Ref<Array<string>>) => {
      const instance = getCurrentInstance()
      listMember.value.push(members)
      instance?.proxy?.$forceUpdate()
    }

    const setUpdateMember = (member: any) => {
      const index = _.findIndex(listMember.value, (item: any) => {
        return item.id === member.id
      })
      listMember.value[index] = member
    }

    const setDeleteMember = (member: any) => {
      const index = _.findIndex(listMember.value, (item: any) => {
        return item.id === member.id
      })
      listMember.value[index] = member
    }

    const getListMember = async () => {
      if (props.customerID !== undefined) {
        try {
          const id = props.customerID
          const data = await api.get(`${endpoints.CUSTOMER_MEMBER}?customer_id=${id}`)
          listMember.value = data.data.map((i: any) => {
            return { id: i.id, name: i.name, email: i.email, customer_id: i.customer_id }
          })
          emit('list-member', listMember.value)
        } catch (e) {
          console.log(e)
        }
      }
    }
    getListMember()

    // click each item: open 1 item has been choose dialog + get this item info
    const openItemDialog = (item: any | null) => {
      if (item) {
        dialogValue.value = item
        isAdd.value = false
      } else {
        dialogValue.value = {}
        isAdd.value = true
      }
      show.value = true
    }

    // click Create button to create new item
    const createDialog = (): void => {
      show.value = true
      isAdd.value = true
      dialogValue.value = {}
    }

    const closeDialog = () => {
      show.value = false
      getListMember()
    }

    watch(
      () => props.customerID,
      () => {
        getListMember()
      }
    )

    return {
      listMember,
      getListMember,
      openItemDialog,
      closeDialog,
      show,
      dialogValue,
      isAdd,
      createDialog,
      setNewMember,
      setUpdateMember,
      setDeleteMember
    }
  }
})
export default MasterCustomerMember
