













































import { defineComponent, toRefs, Ref, ref, watch } from '@vue/composition-api'
import { ObjectWrapper } from 'typings'
import MasterChangeOrderLists from '@/components/MasterChangeOrderLists/index.vue'

interface SetUp {
  items: Ref<ObjectWrapper[]>
  clickItem: (item: ObjectWrapper) => void
  updateData: (event: ObjectWrapper[]) => void
  calculateHeight: (itemLength: number) => number
  checkCustomer: (item: ObjectWrapper) => void
}

const MasterItemList = defineComponent({
  components: {
    MasterChangeOrderLists
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    },
    isSorting: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    listEmptyText: {
      type: String,
      default: '',
      required: true
    },
    checkBoxLeft: {
      type: Boolean,
      required: false,
      default: false
    },
    itemsCheck: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props, { emit }): SetUp {
    const { value } = toRefs(props)
    const items: Ref<ObjectWrapper[]> = ref([])

    const clickItem = (item: ObjectWrapper): void => {
      emit('clickItem', item)
    }

    const updateData = (events: ObjectWrapper[]): void => {
      emit('input', events)
    }

    const calculateHeight = (itemLength: number): number => {
      let height: number
      if (itemLength * 50 < window.innerHeight) {
        height = itemLength * 50
      } else {
        height = window.innerHeight
      }
      return height
    }

    const checkCustomer = (item: ObjectWrapper) => {
      // eslint-disable-next-line no-param-reassign
      item.seriPriority = !item.seriPriority
      emit('on-check-item', item)
      items.value = items.value
        .filter((e: ObjectWrapper) => e.seriPriority)
        .concat(items.value.filter((e: ObjectWrapper) => !e.seriPriority))
    }

    watch(
      value,
      (newval, oldVal) => {
        if (newval !== oldVal) {
          items.value = newval as ObjectWrapper[]
        }
      },
      { immediate: true }
    )

    watch(
      () => props.checkBoxLeft,
      () => {
        if (props.checkBoxLeft) {
          items.value = items.value
            .filter((item: ObjectWrapper) => item.seriPriority)
            .concat(items.value.filter((item: ObjectWrapper) => !item.seriPriority))
        }
      }
    )

    return {
      items,
      clickItem,
      updateData,
      calculateHeight,
      checkCustomer
    }
  }
})

export default MasterItemList
