import { Error } from 'typings'
import { i18n } from 'plugins'
import { TranslateResult } from 'vue-i18n'

export interface Toast {
  error: (mess: string) => void
}

export const showError = (
  e: Error,
  toast: Toast,
  defaultMessage: string | TranslateResult = i18n.t('master.msg.get_data_failed')
): void => {
  if (e.response?.data?.messages) {
    const { messages } = e.response.data
    if (messages?.msg) toast.error(messages?.msg)
    else messages?.map((mess: string) => toast.error(mess))
  } else {
    toast.error(defaultMessage as string)
  }
}

export const scrollTop = (): void => {
  window.scrollTo(0, 0)
}

export const scrollBottom = (): void => {
  window.setTimeout(() => {
    window.scrollTo(0, document.body.scrollHeight)
  }, 300)
}
