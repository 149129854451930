



















import { defineComponent, Ref, ref } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, showError } from 'utils'

interface DayType {
  id: string
  name: string
  isSelected: boolean
}

interface SetUp {
  dayOfWeek: Ref<Array<DayType>>
  auctionDateID: Ref<number>
  changeSelectedDay(dayID: string): void
  isSaveAucDateSuccessful: Ref<boolean>
  getAuctionDate: () => void
  updateAuctionDate: (id: number, data: Record<string, any>) => void
  saving: Ref<boolean>
}

const MasterAuctionDate = defineComponent({
  setup(props, { root }): SetUp {
    const isSaveAucDateSuccessful = ref(false)
    const { $toast } = root
    const auctionDateID = ref()
    const saving = ref(false)
    const dayOfWeek: Ref<Array<DayType>> = ref([
      { id: '6', name: 'sunday', isSelected: false },
      { id: '0', name: 'monday', isSelected: false },
      { id: '1', name: 'tuesday', isSelected: false },
      { id: '2', name: 'wednesday', isSelected: false },
      { id: '3', name: 'thursday', isSelected: false },
      { id: '4', name: 'friday', isSelected: false },
      { id: '5', name: 'saturday', isSelected: false }
    ])

    const changeSelectedDay = (dayID: string) => {
      const index = dayOfWeek.value.findIndex((day: DayType) => day.id === dayID)
      dayOfWeek.value[index].isSelected = !dayOfWeek.value[index].isSelected
    }

    const getAuctionDate = async () => {
      try {
        const data = await api.get(endpoints.AUCTION_DATE)
        //  ["2", "4", "6"]
        dayOfWeek.value.forEach((item: DayType) => {
          if (data.data.value.includes(item.id)) {
            const index = dayOfWeek.value.findIndex((day: DayType) => day.id === item.id)
            dayOfWeek.value[index].isSelected = true
          }
        })
        auctionDateID.value = data.data.id
        // 1
      } catch (e) {
        console.log(e)
      }
    }
    getAuctionDate()

    const updateAuctionDate = async (id: number) => {
      saving.value = true
      try {
        const auctionDates: Array<string> = []
        dayOfWeek.value.forEach((day: DayType) => {
          if (day.isSelected) {
            auctionDates.push(day.id)
          }
        })
        // data for update to database (type: obj/dict)
        const updateData = { value: auctionDates }
        const result = await api.put(`${endpoints.AUCTION_DATE}${id}`, updateData)
        saving.value = false
        if (result === undefined) {
          $toast.error(root.$t('master.msg.update_failed'))
          isSaveAucDateSuccessful.value = false
        } else {
          $toast.success(root.$t('master.msg.update_successful'))
          isSaveAucDateSuccessful.value = true
        }
      } catch (e) {
        isSaveAucDateSuccessful.value = false
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
    }

    return {
      dayOfWeek,
      changeSelectedDay,
      isSaveAucDateSuccessful,
      getAuctionDate,
      updateAuctionDate,
      auctionDateID,
      saving
    }
  }
})
export default MasterAuctionDate
