import { Route, NavigationGuardNext } from 'vue-router'
import Store from '../../store'

/* eslint-disable consistent-return */
export const navGuard = (to: Route, from: Route, next: NavigationGuardNext): void => {
  // only add to store if change screen
  if (from.name !== to.name) {
    Store.commit('pushHistory', to)
  } else if (from.fullPath !== to.fullPath) {
    Store.commit('updateHistory', to)
  }
  next()
}

/* eslint-enable consistent-return */
