





























































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { checkIsOnPC } from 'utils'
import { Calculator } from 'components'
import Vue from 'vue'

interface SetUp {
  v: Ref<string>
  /* eslint-disable @typescript-eslint/no-explicit-any */
  textField: any
  isOnPC: boolean
  onInput: (input: string) => void
  onTyping: (input: any) => void
  next: () => void
  prev: () => void
  ok: () => void
  cancel: () => void
  getLabel: () => string
  onFocus: () => void
}

const ChooseNumber = defineComponent({
  components: {
    Calculator
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    title2: {
      type: String,
      required: false,
      default: null
    },
    inputValue: {
      type: [Number, String],
      required: false,
      default: ''
    },
    stringMode: {
      type: Boolean,
      required: false,
      default: false
    },
    isInProcess: {
      type: Boolean,
      required: false,
      default: true
    },
    separateCharacter: {
      type: String,
      required: false,
      default: '-'
    },
    titleBottom: {
      type: String,
      required: false,
      default: null
    },
    titleBottom2: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    isFloat: {
      type: Boolean,
      required: false,
      default: false
    },
    isNegative: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit, refs }): SetUp {
    const v = ref(props.inputValue.toString())
    const isFirstInputtedKey = ref(true)
    const textField: any = ref(null)
    const isOnPC = checkIsOnPC()

    const onInput = (input: string) => {
      if (isFirstInputtedKey.value) {
        v.value = ''
      }
      if (input === 'clear') {
        v.value = ''
      } else if (input === 'edit') {
        v.value = v.value.slice(0, -1)
      } else {
        v.value = `${v.value}${input}`
        v.value = props.stringMode ? v.value : Number(v.value).toString()
        if (props.isNegative && v.value.length > 0) {
          if (v.value[0] !== '-') v.value = `-${v.value}`
        } else {
          v.value = v.value.replace(/-/g, '')
        }
      }
      isFirstInputtedKey.value = false
      // if (isOnPC) {
      //   textField.value.focus()
      // }
    }

    watch(props, () => {
      v.value = props.inputValue.toString()
      if (!props.value) {
        isFirstInputtedKey.value = true
      }
    })

    const next = () => {
      isFirstInputtedKey.value = true
      emit('on-change', 'next', props.stringMode ? v.value : Number(v.value))
    }

    const prev = () => {
      isFirstInputtedKey.value = true
      emit('on-change', 'prev', props.stringMode ? v.value : Number(v.value))
    }

    const ok = () => {
      emit('on-change', 'ok', props.stringMode ? v.value : Number(v.value))
    }

    const cancel = () => {
      emit('on-change', 'cancel', props.stringMode ? v.value : Number(v.value))
    }

    const getLabel = (): string => `home.step.${props.label}`

    const onTyping = (event: any) => {
      if (event.keyCode === 27) {
        // Press ESC
        ok()
        emit('input', false)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        next()
      } else if (event.keyCode === 13 && event.shiftKey) {
        // Press shift + Enter
        prev()
      } else if (isFirstInputtedKey.value) {
        v.value = ''
        isFirstInputtedKey.value = false
      }
      if (props.isNegative) {
        if (v.value[0] !== '-') v.value = `-${v.value}`
      } else {
        v.value = v.value.replace(/-/g, '')
      }
    }

    const onFocus = () => {
      //  as InstanceType<typeof ValidationProvider>
      if (v.value === '0') v.value = ''
      const bodyText = (refs.textField as Vue).$el.querySelector('input')
      bodyText?.setSelectionRange(v.value.length, v.value.length)
    }

    return {
      v,
      isOnPC,
      onInput,
      onTyping,
      next,
      prev,
      ok,
      cancel,
      getLabel,
      textField,
      onFocus
    }
  }
})

export default ChooseNumber
