import { i18n } from 'plugins'
import { urlPath } from 'utils'
import moment from 'moment'

const date = moment(new Date()).format('YYYY-MM-DD')

export const MENU = [
  {
    title: i18n.t('home.title') as string,
    icon: 'mdi-home',
    path: urlPath.HOME.path
  },
  {
    title: i18n.t('order.name') as string,
    icon: 'mdi-clipboard-outline',
    children: [
      {
        title: i18n.t('order.menu_title') as string,
        // path: urlPath.ORDER_BY_AUCTION.path
        path: `/orders/auction_date/?date=${date.toString()}`
      }
    ]
  },
  {
    title: i18n.t('harvest.name') as string,
    icon: 'mdi-flower-outline',
    children: [
      {
        title: i18n.t('harvest.result.title') as string,
        path: urlPath.HARVEST_RESULT.path
      }
    ]
  },
  {
    title: i18n.t('packing_result.name') as string,
    icon: 'mdi-cube-outline',
    children: [
      {
        title: i18n.t('packing_import.title') as string,
        path: urlPath.PACKING_IMPORT.path
      },
      {
        title: i18n.t('packing_result.title') as string,
        path: urlPath.PACKING_RESULT_DATE.path
      }
    ]
  },
  {
    title: i18n.t('assign.name') as string,
    icon: 'mdi-clipboard-check-outline',
    children: [
      {
        title: i18n.t('assign.title') as string,
        path: urlPath.ASSIGN.path
      },
      {
        title: i18n.t('assign_seri.title') as string,
        path: urlPath.ASSIGN_SERI.path
      },
      {
        title: i18n.t('assign_order.title') as string,
        path: urlPath.ASSIGN_ORDER.path
      }
    ]
  },
  {
    title: i18n.t('invoice.name') as string,
    icon: 'mdi-file-document',
    children: [
      {
        title: i18n.t('invoice.title') as string,
        path: urlPath.INVOICE.path
      }
    ]
  },
  {
    title: i18n.t('sales_result.name') as string,
    icon: 'mdi-book-open',
    children: [
      {
        title: i18n.t('sales_result.title') as string,
        path: urlPath.SALE.path
      },
      {
        title: i18n.t('claim_register.title') as string,
        path: urlPath.CLAIM_REGISTER.path
      }
    ]
  },
  {
    title: i18n.t('accounts_receivable.title') as string,
    icon: 'mdi-credit-card-outline',
    children: [
      {
        title: i18n.t('accounts_receivable.title') as string,
        path: urlPath.AR.path
      }
    ]
  },
  {
    title: i18n.t('master.title') as string,
    icon: 'mdi-cog-outline',
    children: [
      {
        title: i18n.t('product') as string,
        path: urlPath.MASTER.path
      },
      {
        title: i18n.t('customer') as string,
        path: urlPath.MASTER_CUSTOMERS.path
      },
      {
        title: i18n.t('master.grower.title') as string,
        path: urlPath.MASTER_GROWER.path
      },
      {
        title: i18n.t('auction_date') as string,
        path: urlPath.AUCTION_DATE_SETTING.path
      },
      {
        title: i18n.t('material_master.title') as string,
        path: urlPath.MASTER_MATERIAL.path
      },
      {
        title: i18n.t('master.ar_item.title') as string,
        path: urlPath.MASTER_AR_ITEM.path
      },
      {
        title: i18n.t('master.setting.title') as string,
        path: urlPath.MASTER_SETTINGS.path
      }
    ]
  }
]

export const CHILD_GROWER_MENU = [
  {
    title: i18n.t('home.title') as string,
    icon: 'mdi-home',
    path: urlPath.CHILD_GROWER_HOME.path
  },
  {
    title: i18n.t('assign.title') as string,
    icon: 'mdi-clipboard-check-outline',
    children: [
      {
        title: i18n.t('assign_grower.title') as string,
        path: urlPath.ASSIGN_GROWER.path
      },
      {
        title: i18n.t('invoice_grower.title') as string,
        path: urlPath.INVOICE_GROWER.path
      }
    ]
  },
  {
    title: i18n.t('master.title') as string,
    icon: 'mdi-cog-outline',
    children: [
      {
        title: i18n.t('product') as string,
        path: urlPath.MASTER.path
      }
    ]
  }
]
