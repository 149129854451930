import { ref, watchEffect, Ref } from '@vue/composition-api'
import useSWRV from 'swrv'
import { IKey, IConfig, IResponse } from 'swrv/dist/types'

import { api } from 'plugins'
import { toCamelCase } from 'utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetcher = (url: string): any => {
  return api.get(url).then((res) => toCamelCase(res.data))
}

interface IQuery extends IResponse {
  loading: Ref<boolean>
}

export const useQuery = (key: IKey, option: IConfig = {}): IQuery => {
  const loading = ref(false)

  const { data, error, isValidating, mutate } = useSWRV(key, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    ...option
  })

  watchEffect(() => {
    if (data.value === undefined && !error.value) {
      loading.value = true
    } else {
      loading.value = false
    }
  })

  return { data, loading, error, isValidating, mutate }
}
