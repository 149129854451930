



















































import { defineComponent, toRefs, ref, getCurrentInstance } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { debounce } from 'lodash'
import { api } from 'plugins'
import { endpoints, showError, toCamelCase, toSnakeCase } from 'utils'
import DialogContainer from '../DialogContainer/index.vue'

const GrowerDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    grower: {
      type: Object,
      required: true,
      default: null
    },
    isAddGrower: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }) {
    const { $toast, $store } = root
    const instance = getCurrentInstance()
    const { grower } = toRefs(props)
    const loading = ref(false)
    const deleteLoading = ref(false)
    const groID = ref(0)
    const toYomi = debounce(async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${grower.value.name}`)
        grower.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    const closeDialog = (): void => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    // generate body to create
    const generateBody = () => {
      const {
        name,
        code,
        yomi,
        shortName,
        tel,
        fax,
        mobilePhone,
        email,
        address1,
        address2,
        postalCode,
        closingDateGroup,
        bankCode,
        accountNo,
        accountName1,
        accountName2
      } = grower.value

      return {
        name: name.trim(),
        yomi: yomi ? yomi.trim() : null,
        shortName: shortName ? shortName.trim() : null,
        code: code || null,
        tel: tel ? tel.trim() : null,
        mobilePhone: mobilePhone ? mobilePhone.trim() : null,
        fax: fax ? fax.trim() : null,
        email: email ? email.trim() : null,
        address1: address1 ? address1.trim() : null,
        address2: address2 ? address2.trim() : null,
        postalCode: postalCode ? postalCode.trim() : null,
        closingDateGroup,
        bankCode: bankCode ? bankCode.trim() : null,
        accountNo: accountNo ? accountNo.trim() : null,
        accountName1: accountName1 ? accountName1.trim() : null,
        accountName2: accountName2 ? accountName2.trim() : null
      }
    }

    // validate input
    const validateInput = async (): Promise<boolean> => {
      const validate = await (refs.observer as InstanceType<typeof ValidationObserver>).validate()
      return validate
    }

    // UPDATE grower
    const updateGrower = async (gId: number): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.GROWER}${grower.value.id ? grower.value.id : gId}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    // CREATE grower
    const createGrower = async (): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        if (props.isAddGrower) {
          const { data } = await api.post(endpoints.GROWER, body)
          groID.value = data.id
          $toast.success(root.$t('master.msg.create_successful'))
          $store.commit('setNewDataMaster', toCamelCase(data))
          emit('on-reload')
          closeDialog()
        } else {
          await updateGrower(groID.value)
        }
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    // DELETE grower
    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.GROWER}${props.grower.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    return {
      toYomi,
      closeDialog,
      createGrower,
      updateGrower,
      confirmDelete,
      loading,
      deleteLoading
    }
  }
})

export default GrowerDialog
