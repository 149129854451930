import { MultiLevelProduct, MultiLevelVariety, MultiLevelQualitySize } from 'typings'

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
}
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  @typescript-eslint/no-explicit-any */

/* 
  Hook function get panel state when want expand or collapse
  UI too complex so this function complex too
  @data: data after group multiple level
  @state: current panel states
  @selected: object {rootIndex, firstIndex} index of root level and sub level when focus
  @keys keys of first 2 levels
*/

export const useExpansion = () => {
  const getExpandPanelState = ({
    data,
    state,
    selected,
    keys = ['itemName', 'varietyName']
  }: {
    data: Array<MultiLevelProduct>
    state: Record<string, any>
    selected: SelectedLevel
    keys?: Array<string>
  }): Record<string, any> => {
    const panel = { ...state }

    if (selected.firstIndex === null) {
      // Expand item level
      panel.panels = Array.from(new Set([...(state.panels || []), selected.rootIndex]))

      const detail = data[selected.rootIndex as number]
      const rootLevel = detail.children?.map((child: MultiLevelVariety, index: number) => index)
      const rootLevelName = detail[keys[0] as keyof MultiLevelProduct] as string

      Object.assign(panel, { [rootLevelName]: rootLevel })

      detail.children?.forEach((child: MultiLevelVariety) => {
        const firstLevel = child.children?.map(
          (qualitySize: MultiLevelQualitySize, subIndex: number) => subIndex
        )
        const firstLevelName = child[keys[1] as keyof MultiLevelVariety]
        Object.assign(panel, { [`${rootLevelName}-${firstLevelName}`]: firstLevel })
      })
    } else {
      // Expand variety level
      const detail = data[selected.rootIndex as number]
      const firstLevelData = detail.children[selected.firstIndex]
      const rootLevelName = detail[keys[0] as keyof MultiLevelProduct] as string

      const subLevelState = [...(state[rootLevelName] || []), selected.firstIndex]

      Object.assign(panel, { [rootLevelName]: subLevelState })

      const secondLevel = firstLevelData.children?.map(
        (qualitySize: MultiLevelQualitySize, subIndex: number) => subIndex
      )
      const firstLevelName = firstLevelData[keys[1] as keyof MultiLevelVariety]

      Object.assign(panel, { [`${rootLevelName}-${firstLevelName}`]: secondLevel })
    }

    return panel
  }

  const getCollapsePanelState = ({
    data,
    state,
    selected,
    keys = ['itemName', 'varietyName']
  }: {
    data: Array<MultiLevelProduct>
    state: Record<string, any>
    selected: SelectedLevel
    keys?: Array<string>
  }): Record<string, any> => {
    const panel = { ...state }

    if (selected.firstIndex === null) {
      // Collapse item level
      panel.panels = [
        ...(state.panels || []).filter((index: number) => index !== selected.rootIndex)
      ]

      const detail = data[selected.rootIndex as number]
      const rootLevelName = detail[keys[0] as keyof MultiLevelProduct]
      delete panel[rootLevelName as string]

      detail.children?.forEach((child: MultiLevelVariety) => {
        const firstLevelName = child[keys[1] as keyof MultiLevelVariety]

        delete panel[`${rootLevelName}-${firstLevelName}`]
      })
    } else {
      // Collapse variety level
      const detail = data[selected.rootIndex as number]
      const rootLevelName = detail[keys[0] as keyof MultiLevelProduct]

      panel[rootLevelName as string] = panel[rootLevelName as string].filter(
        (index: number) => index !== selected.firstIndex
      )

      const firstLevelData = detail.children[selected.firstIndex]
      const firstLevelName = firstLevelData[keys[1] as keyof MultiLevelVariety]

      delete panel[`${rootLevelName}-${firstLevelName}`]
    }

    return panel
  }

  return { getExpandPanelState, getCollapsePanelState }
}
