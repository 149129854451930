import { Route, NavigationGuardNext } from 'vue-router'

import { CHILD_GROWER_HOME, CHILD_GROWER_PERMISSION, START } from '../urlPath'
import Store from '../../store'
import { memberRole } from '../constants'

/* eslint-disable consistent-return */
export const roleGuard = (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  const fn = async () => {
    const currentUser = await Store.getters.getUserInfo

    if (currentUser.memberRole === memberRole.ROLE_ADMINISTRATOR) {
      next()
    } else if (currentUser.memberRole === memberRole.ROLE_CHILD_GROWER) {
      let isIncludePermission = false
      CHILD_GROWER_PERMISSION.forEach((url: string) => {
        if (to.path.startsWith(url) && url !== START.path) isIncludePermission = true
        if (to.path === START.path) isIncludePermission = true
      })
      if (isIncludePermission) {
        next()
      } else {
        console.log('vao dYay')
        next({ path: CHILD_GROWER_HOME.path })
      }
    }
  }

  return fn()
}

/* eslint-enable consistent-return */
