// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const expandAll = (
  data: any = {},
  panelsData: any = {},
  levelValue: Array<string> = [],
  levelName: Array<string> = []
) => {
  const panels: any = { ...panelsData }
  data.map((lv0: any, index1: number) => {
    if (lv0[levelName[0]] === levelValue[0]) {
      if (levelValue[1] === null) {
        panels[lv0[levelName[0]]] = Array.from(Array(lv0.children.length).keys())
        if (panels.panels.indexOf(index1) === -1) {
          panels.panels.push(index1)
        }

        lv0.children.map((lv1: any) => {
          panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] = Array.from(
            Array(lv1.children.length).keys()
          )
          return []
        })
      } else {
        lv0.children.map((lv1: any, index2: number) => {
          if (levelValue[1] === lv1[levelName[1]]) {
            if (levelValue[2] === null) {
              if (panels[lv0[levelName[0]]] === undefined) {
                panels[lv0[levelName[0]]] = [index2]
              }
              if (panels[lv0[levelName[0]]].indexOf(index2) === -1) {
                panels[lv0[levelName[0]]].push(index2)
              }
              panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] = Array.from(
                Array(lv1.children.length).keys()
              )
            } else {
              lv1.children.map((lv2: any, index3: number) => {
                if (levelValue[2] === lv2[levelName[2]]) {
                  if (panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] === undefined) {
                    panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] = [index3]
                  }
                  if (panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`].indexOf(index3) === -1) {
                    panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`].push(index3)
                  }
                }
                return []
              })
            }
          }
          return []
        })
      }
    }
    return []
  })
  return panels
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const collapseAll = (
  data: any = {},
  panelsData: any = {},
  levelValue: Array<string> = [],
  levelName: Array<string> = []
) => {
  const panels: any = { ...panelsData }
  data.map((lv0: any, index1: number) => {
    if (lv0[levelName[0]] === levelValue[0]) {
      if (levelValue[1] === null) {
        panels[lv0[levelName[0]]] = []
        panels.panels = panels.panels.filter((e: any) => e !== index1)

        lv0.children.map((lv1: any) => {
          panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] = []
          return []
        })
      } else {
        lv0.children.map((lv1: any, index2: number) => {
          if (levelValue[1] === lv1[levelName[1]]) {
            if (levelValue[2] === null) {
              panels[lv0[levelName[0]]] = panels[lv0[levelName[0]]].filter((e: any) => e !== index2)
            } else {
              lv1.children.map((lv2: any, index3: number) => {
                if (levelValue[2] === lv2[levelName[2]]) {
                  panels[`${lv0[levelName[0]]}-${lv1[levelName[1]]}`] = panels[
                    `${lv0[levelName[0]]}-${lv1[levelName[1]]}`
                  ].filter((e: any) => e !== index3)
                }
                return []
              })
            }
          }
          return []
        })
      }
    }
    return []
  })
  return panels
}
