export const listPackingStates = [
  'packedDate',
  'item',
  'variety',
  'color',
  'quality',
  'size',
  'quantity',
  'unit',
  'boxes',
  'boxType',
  'totalStems',
  'desiredPrice'
]

export const breakdownDetailNextState = {
  item: 'variety',
  variety: 'color',
  color: 'quality',
  quality: 'size',
  size: 'quantity',
  quantity: ''
}

export const breakdownDetailPrevState = {
  item: '',
  variety: 'item',
  color: 'variety',
  quality: 'color',
  size: 'quality',
  quantity: 'size'
}

export const deliveryDetailNextState = {
  auctionDate: 'orderType',
  orderType: 'customer',
  customer: 'buyerInfo',
  buyerInfo: 'bundleNo',
  bundleNo: 'boxes',
  boxes: 'price',
  stems: 'price',
  price: ''
}

export const deliveryDetailPrevState = {
  auctionDate: '',
  orderType: 'auctionDate',
  customer: 'orderType',
  buyerInfo: 'customer',
  bundleNo: 'buyerInfo',
  boxes: 'bundleNo',
  stems: 'boxes',
  price: 'boxes'
}

export const initPackingResultDetail = {
  packedDate: '',
  item: null,
  variety: null,
  size: null,
  color: null,
  boxType: null,
  unit: null,
  quality: null,
  quantity: null,
  boxes: null,
  totalStems: null,
  desiredPrice: null,
  breakdowns: [],
  deliveries: [],
  remark: null
}

export const initBreakdown = {
  item: null,
  variety: null,
  size: null,
  color: null,
  quality: null,
  quantity: null
}

export const initDelivery = {
  id: null,
  orderDetailId: null,
  customer: { id: null, name: null },
  auctionDate: null,
  boxes: null,
  stems: null,
  orderType: null,
  buyerInfo: null,
  bundleNo: null,
  price: null
}
