
































import { defineComponent } from '@vue/composition-api'

const confirmAssign = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    return {}
  }
})

export default confirmAssign
