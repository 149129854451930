var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white-bg"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_vm._t("header"),(_vm.rows.includes('item'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('item') ? 'required' : '',"name":_vm.$t('master.item.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('item'),"label":_vm.enableShortcut ? _vm.$t('master.item.name') + ' (' + _vm.$t('common.item_shortcut') + ')' : _vm.$t('master.item.name'),"placeholder":_vm.$t('master.placeholder.item'),"disabled":_vm.disabledRows.includes('item'),"errors":errors,"check-key":'item',"this-page":_vm.thisPage,"append-icon":false,"is-show-check":_vm.isShowCheck,"value":_vm.detail.item ? _vm.detail.item.name : ''},on:{"on-click":function($event){return _vm.clickRow('item')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('variety'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('variety') ? 'required' : '',"name":_vm.$t('master.variety.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('variety'),"label":_vm.enableShortcut ? _vm.$t('master.variety.name') + ' (' + _vm.$t('common.variety_shortcut') + ')' : _vm.$t('master.variety.name'),"placeholder":_vm.$t('master.placeholder.variety'),"errors":errors,"check-key":'variety',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.variety ? _vm.detail.variety.name : '',"disabled":_vm.disabledRows.includes('variety')},on:{"on-click":function($event){return _vm.clickRow('variety')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('color') || _vm.rows.includes('colors'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('color') || _vm.requiredRows.includes('colors') ? 'required' : '',"name":_vm.$t('master.color.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('color') || _vm.requiredRows.includes('colors'),"label":_vm.$t('master.color.name'),"check-key":'colors',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck},on:{"on-click":function($event){_vm.clickRow(_vm.rows.includes('color') ? 'color' : 'colors')}}},[_c('v-combobox',{attrs:{"flat":"","solo":"","reverse":"","hide-details":"","readonly":"","append-icon":"","error-messages":errors,"multiple":_vm.rows.includes('colors'),"placeholder":_vm.$t('master.placeholder.color')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('color-circle',{key:data.item.code,attrs:{"color":data.item,"size":"small"}})]}}],null,true),model:{value:(_vm.rows.includes('color') ? _vm.detail.color : _vm.detail.colors),callback:function ($$v) {_vm.$set(_vm.rows.includes('color') ? _vm.detail.color : _vm.detail, "colors", $$v)},expression:"rows.includes('color') ? detail.color : detail.colors"}})],1)]}}],null,true)}):_vm._e(),(_vm.rows.includes('quality'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('quality') ? 'required' : '',"name":_vm.$t('master.quality.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('quality'),"label":_vm.enableShortcut ? _vm.$t('master.quality.name') + ' (' + _vm.$t('common.quality_shortcut') + ')' : _vm.$t('master.quality.name'),"placeholder":_vm.$t('master.placeholder.quality'),"errors":errors,"check-key":'quality',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.quality ? _vm.detail.quality.name : '',"disabled":_vm.disabledRows.includes('quality')},on:{"on-click":function($event){return _vm.clickRow('quality')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('size'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('size') ? 'required' : '',"name":_vm.$t('master.size.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('size'),"label":_vm.enableShortcut ? _vm.$t('master.size.name') + ' (' + _vm.$t('common.size_shortcut') + ')' : _vm.$t('master.size.name'),"placeholder":_vm.$t('master.placeholder.size'),"errors":errors,"check-key":'size',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.size ? _vm.detail.size.name : '',"disabled":_vm.disabledRows.includes('size')},on:{"on-click":function($event){return _vm.clickRow('size')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('arItem'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('arItem') ? 'required' : '',"name":_vm.$t('master.ar_item.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('arItem'),"label":_vm.$t('master.ar_item.name'),"placeholder":_vm.$t('master.ar_item.name'),"errors":errors,"check-key":'arItem',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.arItem ? _vm.detail.arItem.name : '',"disabled":_vm.disabledRows.includes('arItem')},on:{"on-click":function($event){return _vm.clickRow('arItem')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('quantity'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('quantity') ? 'required|min:1' : '',"name":_vm.$t('master.quantity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('quantity'),"label":_vm.enableShortcut ? _vm.$t('master.quantity') + ' (' + _vm.$t('common.quantity_shortcut') + ')' : _vm.$t('master.quantity'),"placeholder":_vm.$t('master.placeholder.quantity'),"errors":errors,"check-key":'quantity',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.quantity,"disabled":_vm.disabledRows.includes('quantity')},on:{"on-click":function($event){return _vm.clickRow('quantity')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('box'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('box') ? 'required' : '',"name":_vm.$t('master.box')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('box'),"label":_vm.$t('master.box'),"placeholder":_vm.disabledRows.includes('box') ? _vm.$t('assign.placeholder') : _vm.$t('master.box'),"errors":errors,"check-key":'box',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.box,"disabled":_vm.disabledRows.includes('box')},on:{"on-click":function($event){return _vm.clickRow('box')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('arUnit'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('arUnit') ? 'required' : '',"name":_vm.$t('master.ar_unit.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('arUnit'),"label":_vm.$t('master.placeholder.ar_unit'),"placeholder":_vm.$t('master.placeholder.ar_unit'),"errors":errors,"check-key":'arUnit',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.arUnit ? _vm.detail.arUnit.name : '',"disabled":_vm.disabledRows.includes('arUnit')},on:{"on-click":function($event){return _vm.clickRow('arUnit')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('unit'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('unit') ? 'required' : '',"name":_vm.$t('master.unit.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('unit'),"label":_vm.$t('master.unit.name'),"placeholder":_vm.$t('master.placeholder.unit'),"errors":errors,"check-key":'unit',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.unit ? _vm.detail.unit.name : '',"disabled":_vm.disabledRows.includes('unit')},on:{"on-click":function($event){return _vm.clickRow('unit')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('boxes'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('boxes') ? 'required|min:1' : '',"name":_vm.$t('master.boxes')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('boxes'),"label":_vm.enableShortcut ? _vm.$t('master.boxes') + ' (' + _vm.$t('common.boxes_shortcut') + ')' : _vm.$t('master.boxes'),"placeholder":_vm.disabledRows.includes('boxes') ? _vm.$t('assign.placeholder') : _vm.$t('master.placeholder.boxes'),"errors":errors,"check-key":'boxes',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.boxes,"disabled":_vm.disabledRows.includes('boxes')},on:{"on-click":function($event){return _vm.clickRow('boxes')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('boxType'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('boxType') ? 'required' : '',"name":_vm.$t('master.box_type.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('boxType'),"label":_vm.enableShortcut ? _vm.$t('master.box_type.name') + ' (' + _vm.$t('common.box_type_shortcut') + ')' : _vm.$t('master.box_type.name'),"placeholder":_vm.$t('master.placeholder.box_type'),"errors":errors,"check-key":'boxType',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.boxType ? (_vm.detail.boxType.shortName ? _vm.detail.boxType.shortName: _vm.detail.boxType.name) : '',"disabled":_vm.disabledRows.includes('boxType')},on:{"on-click":function($event){return _vm.clickRow('boxType')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('totalStems') || _vm.rows.includes('stems'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('totalStems') || _vm.requiredRows.includes('stems') ? 'required|min:1' : '',"name":_vm.requiredRows.includes('totalStems') ? _vm.$t('master.total_stems') : _vm.$t('master.stems')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('totalStems') || _vm.requiredRows.includes('stems'),"label":_vm.$t('master.total_stems'),"placeholder":_vm.disabledRows.includes('stems') ? _vm.$t('assign.placeholder') : _vm.$t('master.placeholder.total_stems'),"errors":errors,"check-key":_vm.rows.includes('stems') ? 'stems' : 'totalStems',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.rows.includes('stems') ? _vm.detail.stems : _vm.detail.totalStems,"disabled":_vm.disabledRows.includes('stems') || _vm.disabledRows.includes('totalStems')},on:{"on-click":function($event){_vm.clickRow(_vm.rows.includes('stems') ? 'stems' : 'totalStems')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('price'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('price') ? 'required' : '',"name":_vm.$t('master.price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('price'),"label":_vm.enableShortcut ? _vm.$t('master.price') + ' (' + _vm.$t('common.price_shortcut') + ')' : _vm.$t('master.price'),"placeholder":_vm.disabledRows.includes('price') ? _vm.$t('assign.placeholder') : _vm.$t('master.placeholder.price'),"errors":errors,"check-key":'price',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.price,"disabled":_vm.disabledRows.includes('price')},on:{"on-click":function($event){return _vm.clickRow('price')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('desiredPrice'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('desiredPrice') ? 'required' : '',"name":_vm.$t('master.desired_price')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('desiredPrice'),"label":_vm.$t('master.desired_price'),"placeholder":_vm.$t('master.placeholder.desired_price'),"errors":errors,"check-key":'desiredPrice',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.desiredPrice,"disabled":_vm.disabledRows.includes('desiredPrice')},on:{"on-click":function($event){return _vm.clickRow('desiredPrice')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('total_amount')  || _vm.rows.includes('amount'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('total_amount') ? 'required' : '',"name":_vm.$t('master.total_amount')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('total_amount'),"label":_vm.$t('master.amount'),"placeholder":_vm.disabledRows.includes('amount') ? _vm.$t('assign.placeholder') : _vm.$t('master.amount'),"errors":errors,"check-key":'amount',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.totalAmount || _vm.detail.amount,"disabled":_vm.disabledRows.includes('total_amount')},on:{"on-click":function($event){return _vm.clickRow('amount')}}})]}}],null,true)}):_vm._e(),(_vm.rows.includes('taxRate'))?_c('validation-provider',{attrs:{"rules":_vm.requiredRows.includes('tax_rate') ? 'required' : '',"name":_vm.$t('sale.tax_rate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select-input-row',{attrs:{"required":_vm.requiredRows.includes('taxRate'),"label":_vm.$t('sale.tax_rate'),"placeholder":_vm.$t('sale.tax_rate'),"errors":errors,"check-key":'taxRate',"this-page":_vm.thisPage,"is-show-check":_vm.isShowCheck,"value":_vm.detail.taxRate,"disabled":_vm.disabledRows.includes('taxRate')},on:{"on-click":function($event){return _vm.clickRow('taxRate')}}})]}}],null,true)}):_vm._e(),_vm._t("footer")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }