







































import { defineComponent, toRefs, Ref, ref, watch, getCurrentInstance } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { debounce } from 'lodash'

import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, createOrDeleteImage, toCamelCase } from 'utils'
import { IItem, Unit, Image, DefaultUnit, SizeGroup, QualityGroup } from 'typings'

import DialogContainer from '../DialogContainer/index.vue'
import ImageList from '../ImageList/index.vue'
import MaterialSelectUnitAndDefaultUnit from '../MaterialSelectUnitAndDefaultUnit/index.vue'

interface onSaveUnitInterface {
  default: DefaultUnit
  units: Array<Unit>
}

interface SetUp {
  unitNameShow: Ref<string>
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  toYomi: () => void
  closeDialog: () => void
  createItem: () => void
  updateItem: () => void
  confirmDelete: (param: string) => void
  removeImage: (id: number) => void
  isShowDialogUnit: Ref<boolean>
  openSelectUnit: () => void
  closeSelectUnit: () => void
  onSave: (data: onSaveUnitInterface) => void
  defaultUnit: Ref<DefaultUnit>
  searchSizeGroup: (group: SizeGroup, queryText: string, groupText: string) => boolean
  searchQualityGroup: (group: QualityGroup, queryText: string, groupText: string) => boolean
}

const MaterialItemDialog = defineComponent({
  components: {
    DialogContainer,
    ImageList,
    MaterialSelectUnitAndDefaultUnit
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: true,
      default: null
    },
    isAddItem: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { item } = toRefs(props)
    const instance = getCurrentInstance()
    const { $toast, $store } = root
    const listImageRemove = ref<Array<number>>([])
    const loading = ref<boolean>(false)
    const deleteLoading = ref<boolean>(false)
    const isShowDialogUnit = ref(false)
    const unitNameShow = ref('')
    const defaultUnit = ref<DefaultUnit>({
      id: 0,
      name: '',
      isCheck: false
    })

    const searchSizeGroup = (group: SizeGroup, queryText: string, groupText: string): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return groupText.trim().toLowerCase().indexOf(searchText) > -1
    }

    const searchQualityGroup = (
      group: QualityGroup,
      queryText: string,
      groupText: string
    ): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return groupText.trim().toLowerCase().indexOf(searchText) > -1
    }

    const getUnitNameShow = (): string => {
      if (item.value.units) {
        return item.value.units.map((unit: Unit) => unit.name).join(', ')
      }
      return ''
    }

    watch(
      () => props.show,
      () => {
        if (props.show) {
          unitNameShow.value = getUnitNameShow()
          defaultUnit.value = item.value.defaultUnit
            ? item.value.defaultUnit
            : { id: 0, name: '', isCheck: false }
        }
      }
    )

    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${item.value.name}`)
        item.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    const closeDialog = (): void => {
      listImageRemove.value = []
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): IItem => {
      const body: IItem = {
        name: item.value.name.trim(),
        defaultUnit: item.value.defaultUnit ? item.value.defaultUnit.id : null
      }
      if (item.value.yomi) {
        body.yomi = item.value.yomi.trim()
      }
      if (item.value.shortName) {
        body.shortName = item.value.shortName.trim()
      }
      if (item.value.nameEng) {
        body.nameEng = item.value.nameEng.trim()
      }
      if (item.value.units) {
        body.units = item.value.units.map((unit: Unit) => unit.id)
      }
      if (item.value.sizeGroup) {
        body.sizeGroupId = item.value.sizeGroup.id ? item.value.sizeGroup.id : item.value.sizeGroup
      }
      if (item.value.qualityGroup) {
        body.qualityGroupId = item.value.qualityGroup.id
          ? item.value.qualityGroup.id
          : item.value.qualityGroup
      }
      return body
    }

    const uploadImage = async (uuidKey: string): Promise<void> => {
      if (item.value?.images) {
        const newImages = item.value.images.filter((image: Image) => !image.id)
        await createOrDeleteImage(uuidKey, newImages, listImageRemove.value)
        listImageRemove.value = []
      }
    }

    const removeImage = (id: number) => {
      listImageRemove.value = [...listImageRemove.value, id]
    }

    const validateItem = async (): Promise<boolean> => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      const valid = await observer.validate()
      if (!valid) {
        let errorMess: Array<string> = []
        Object.keys(observer.errors).forEach((key) => {
          errorMess = [...errorMess, ...observer.errors[key]]
        })
        $toast.error(errorMess.join(',\n'))
        return false
      }
      return true
    }

    const createItem = async (): Promise<void> => {
      const validateResult = await validateItem()
      if (!validateResult) {
        return
      }

      const body = toSnakeCase(generateBody())
      loading.value = true
      try {
        const { data } = await api.post(endpoints.MATERIAL_ITEMS, body)
        await uploadImage(data.uuid)
        $store.commit('setNewDataMaster', toCamelCase(data))
        $toast.success(root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        console.log('e', e)
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      }
      loading.value = false
    }

    const updateItem = async (): Promise<void> => {
      const validateResult = await validateItem()
      if (!validateResult) {
        return
      }

      const body = toSnakeCase(generateBody())
      loading.value = true
      try {
        await api.put(`${endpoints.MATERIAL_ITEMS}${item.value.id}`, body)
        await uploadImage(item.value.uuid)

        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
      loading.value = false
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.MATERIAL_ITEMS}${props.item.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const openSelectUnit = () => {
      isShowDialogUnit.value = true
    }

    const closeSelectUnit = () => {
      isShowDialogUnit.value = false
    }

    const onSave = (data: onSaveUnitInterface) => {
      item.value.units = data.units
      item.value.defaultUnit = data.default
      defaultUnit.value = item.value.defaultUnit ? item.value.defaultUnit : { id: 0, name: '' }
      unitNameShow.value = getUnitNameShow()
      closeSelectUnit()
    }

    return {
      loading,
      deleteLoading,
      toYomi,
      closeDialog,
      createItem,
      updateItem,
      confirmDelete,
      removeImage,
      isShowDialogUnit,
      openSelectUnit,
      closeSelectUnit,
      unitNameShow,
      onSave,
      defaultUnit,
      searchSizeGroup,
      searchQualityGroup
    }
  }
})

export default MaterialItemDialog
