var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"pa-0 ma-0 mt-0"},[_c('v-col',{staticClass:"pa-0 ma-0"},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":true}}):_vm._e(),_c('v-data-table',{ref:"expandableTable",staticClass:"mb-1 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"expanded":_vm.expanded,"item-key":_vm.itemKey,"show-expand":_vm.showExpand,"items-per-page":-1,"no-data-text":_vm.$t('common.no_data'),"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":"0"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',{staticClass:"border-checkbox"},_vm._l((headers),function(header,index){return _c('th',{key:index,class:_vm.getClassHeader(header.align, header.width, header.value),attrs:{"colspan":header.colspan}},[_vm._t(("header_" + (header.value)),function(){return [_c('span',{staticClass:"font-size-large",class:header.value !== 'start-table' && header.value !== 'end-table'},[_vm._v(_vm._s(header.text))])]},{"headers":headers})],2)}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var index = ref.index;
var headers = ref.headers;
return [_c('tr',{class:item.active === false ? 'item--disable' : '',on:{"click":function($event){_vm.showExpand ? expand(!isExpanded) : ''}}},_vm._l((headers),function(header,idx){return _c('td',{key:idx,class:_vm.getClassHeader(header.align, header.width, header.value),attrs:{"colspan":header.colspan}},[(header.value === 'data-table-expand')?_vm._t(("item_" + (header.value)),function(){return [_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)]},{"index":index}):_vm._t(("item_" + (header.value)),function(){return [_c('span',[_vm._v(_vm._s(item[header.value]))])]},{"item":item,"headers":headers,"isExpanded":isExpanded,"expand":expand,"index":index})],2)}),0)]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [(_vm.isHeaderExpand !== false)?_vm._t("expand-header",function(){return _vm._l((_vm.headersExpand),function(header,index){return _c('th',{key:("expandedHeader_" + (item.customerName) + "_" + index),staticClass:"color-expand-header",class:_vm.getClassHeader(header.align),attrs:{"colspan":header.colspan}},[_vm._t(("expand_header_" + (header.value)),function(){return [_c('span',{staticClass:"font-size-large"},[_vm._v(_vm._s(header.text))])]})],2)})},{"item":item}):_vm._e(),_vm._t('expanded-item-body',function(){return _vm._l((item.expandItems),function(expandItem){return _c('tr',{key:("expanded_" + (JSON.stringify(expandItem)))},_vm._l((_vm.headers),function(header,index){return _c('td',{key:index,class:header.value==='checkbox' ? 'small-width' : 'color-expand-body'},[(header.value === 'data-table-expand')?_vm._t(("expand_" + (header.value)),function(){return [_c('v-menu',{attrs:{"bottom":"","left":"","min-width":"9vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-auto",attrs:{"color":"primary","dark":"","icon":"","max-height":"20px","max-width":"20px"}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)})]},{"item":expandItem,"header":_vm.headers}):_vm._t(("expand_" + (header.value)),function(){return [_c('span',[_vm._v(_vm._s(expandItem[header.value]))])]},{"item":expandItem,"header":header})],2)}),0)})},{"item":item,"header":_vm.headersExpand})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }