





























import { defineComponent, computed, Ref, ref, onMounted, watch } from '@vue/composition-api'
import { formatNumber, fontResizeRatio } from 'utils'

interface SetUp {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  stepBtn: any
  formattedValue: Ref<string>
  formattedRemaining: Ref<string>
  formattedSpecialValue: Ref<string>
  formattedHistory: Ref<string>
  getName(): string
  getClass(): string
  innerWidth: Ref<number>
  fontRatioValue: any
  fontRatioRemaining: any
  fontRatioSpecial: any
  fontRatioName: any
  fontRatioHistory: Ref<string>
}

const StepButton = defineComponent({
  props: {
    stepName: {
      type: String,
      required: true
    },
    value: {
      type: Number || null,
      required: false,
      default: null
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false
    },
    remaining: {
      type: Number,
      required: false,
      default: 0
    },
    isShowRemaining: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowValue: {
      type: Boolean,
      required: false,
      default: true
    },
    isShowByBox: {
      type: Boolean,
      default: false,
      required: false
    },
    isShowSpecialValue: {
      type: Boolean,
      default: false,
      required: false
    },
    specialValue: {
      type: Number,
      default: 0,
      required: false
    },
    text: {
      type: String,
      required: false,
      default: null
    },
    isFormatThousand: {
      type: Boolean,
      required: false,
      default: false
    },
    xSmall: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    medium: {
      type: Boolean,
      required: false,
      default: false
    },
    large: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: Number,
      required: false,
      default: null
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#FFFFFF'
    },
    iconTopRight: {
      type: String,
      required: false,
      default: null
    },
    showValueZero: {
      type: Boolean,
      required: false,
      default: false
    },
    isShowHistory: {
      type: Boolean,
      required: false,
      default: false
    },
    historyValue: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup(props, { emit, root }): SetUp {
    const innerWidth = ref(50)
    if (props.xSmall) innerWidth.value = 45
    else if (props.small) innerWidth.value = 50
    else if (props.medium) innerWidth.value = 55
    else if (props.large) innerWidth.value = 90

    innerWidth.value = props.width ? props.width : innerWidth.value

    const stepBtn: any = ref(null)
    const getClass = (): string => {
      let className = ''
      className += props.value ? `step-btn--${props.stepName}` : 'step-btn--empty'
      className += props.clickable ? ' clickable' : ''
      return className
    }
    const getName = (): string => props.text ?? `home.step.${props.stepName}`
    const formattedValue = computed(() =>
      formatNumber.formatThousand(props.value, props.isFormatThousand)
    )
    const formattedRemaining = computed(() =>
      props.remaining ? formatNumber.formatThousand(props.remaining, props.isFormatThousand) : '0'
    )
    const formattedSpecialValue = computed(() =>
      props.specialValue
        ? formatNumber.formatThousand(props.specialValue, props.isFormatThousand)
        : '0'
    )
    const formattedHistory = computed(() =>
      props.historyValue
        ? formatNumber.formatThousand(props.historyValue, props.isFormatThousand)
        : '0'
    )
    // Resize ratio
    const fontRatioName = ref<any>()
    const fontRatioValue = ref<any>()
    const fontRatioRemaining = ref<any>()
    const fontRatioSpecial = ref<any>()
    const fontRatioHistory = ref<any>()
    const resizeText = () => {
      fontRatioValue.value = fontResizeRatio(
        formattedValue.value?.toString(),
        innerWidth.value - 6,
        16
      )
      fontRatioSpecial.value = fontResizeRatio(
        formattedSpecialValue.value,
        innerWidth.value - 6,
        16
      )
      fontRatioRemaining.value = fontResizeRatio(
        formattedRemaining.value?.toString(),
        innerWidth.value - 6,
        16
      )
      fontRatioHistory.value = fontResizeRatio(
        formattedHistory.value?.toString(),
        innerWidth.value - 20,
        16
      )
      let compensateWidth = 0
      if (props.iconTopRight !== null) {
        compensateWidth += 9
      }
      fontRatioName.value = fontResizeRatio(
        root.$t(getName()).toString(),
        innerWidth.value - 6 - compensateWidth,
        15
      )
    }
    resizeText()
    const setHeight = (): void => {
      if (
        (props.isShowRemaining && props.value && props.isShowValue) ||
        (props.isShowSpecialValue && props.specialValue) ||
        props.isShowHistory
      ) {
        stepBtn.value.style.height = '65px'
      } else {
        stepBtn.value.style.height = '45px'
      }
      stepBtn.value.style.backgroundColor = props.backgroundColor
    }
    onMounted(() => {
      setHeight()
      stepBtn.value.addEventListener('click', (e: any) => {
        if (props.clickable) {
          emit('on-click')
          e.stopPropagation()
        }
      })
    })
    watch(props, () => {
      setHeight()
      resizeText()
    })
    return {
      stepBtn,
      innerWidth,
      formattedValue,
      formattedRemaining,
      formattedSpecialValue,
      getName,
      getClass,
      fontRatioRemaining,
      fontRatioSpecial,
      fontRatioValue,
      fontRatioName,
      formattedHistory,
      fontRatioHistory
    }
  }
})
export default StepButton
