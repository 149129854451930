

























import { defineComponent, ref, watch } from '@vue/composition-api'
import moment from 'moment'

const AuctionDateDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    dateProps: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const dateData = ref('')

    watch(
      () => props.value,
      () => {
        dateData.value =
          props.dateProps !== '' ? props.dateProps : moment(new Date()).format('YYYY-MM-DD')
      }
    )

    const closeDialog = () => {
      emit('close-dialog')
    }
    const handleClickOk = () => {
      emit('on-click-ok', dateData.value)
      closeDialog()
    }
    return {
      dateData,
      handleClickOk,
      closeDialog
    }
  }
})
export default AuctionDateDialog
