
































import { defineComponent, Ref, ref } from '@vue/composition-api'
import AddButton from '@/components/AddButton/index.vue'

interface Button {
  name: string
  icon: string
  action: string
}

interface SetUp {
  clickAddBtn: () => void
  actionByIndex: (button: Button) => void
  showAddBtn: Ref<boolean>
  showSortNewBtns: Ref<boolean>
  buttons: Ref<Button[]>
}

const MasterSpeedGroupDial = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AddButton
  },
  setup(props, { emit }): SetUp {
    const showAddBtn: Ref<boolean> = ref(true)
    const showSortNewBtns: Ref<boolean> = ref(false)

    const buttons: Ref<Button[]> = ref([
      {
        id: 1,
        name: 'Sort',
        icon: 'mdi-sort-ascending',
        action: 'on-sort'
      },
      {
        id: 2,
        name: 'Sort Group',
        icon: 'mdi-sort-ascending',
        action: 'on-sort-group'
      },
      {
        id: 3,
        name: 'New',
        icon: 'mdi-plus',
        action: 'on-add'
      },
      {
        id: 4,
        name: 'New Group',
        icon: 'mdi-plus',
        action: 'on-add-group'
      }
    ])

    const clickAddBtn = (): void => {
      showAddBtn.value = !showAddBtn.value
      setTimeout(() => {
        showSortNewBtns.value = !showSortNewBtns.value
      }, 400)
    }

    const actionByIndex = (button: Button): void => {
      emit(button.action)
    }

    return { actionByIndex, clickAddBtn, showAddBtn, buttons, showSortNewBtns }
  }
})

export default MasterSpeedGroupDial
