











import { defineComponent } from '@vue/composition-api'
import ConfirmDialog from '../ConfirmDialog/index.vue'

interface SetUp {
  closeDialog: (param: string) => void
}

const ConfirmDelete = defineComponent({
  components: {
    ConfirmDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const closeDialog = (param: string) => {
      emit('close-dialog', param)
    }

    return {
      closeDialog
    }
  }
})
export default ConfirmDelete
