















































































import { defineComponent, ref, watch } from '@vue/composition-api'

import { api } from 'plugins'
import { endpoints, toCamelCase } from 'utils'

import DialogContainer from '../DialogContainer/index.vue'

const CustomerDetailInvoiceDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    customerProp: {
      type: Object,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const { $toast } = root
    const loading = ref(false)
    const invoice = ref([] as any)
    const invoiceDefault = ref([] as any)
    const customerId = ref(props.customerId)

    const formTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const countryTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' }
    ]
    const packingStyleTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' }
    ]
    const offerTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const orderNoteTypes: Array<{ value: number; name: string }> = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' }
    ]
    const catalogTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' }
    ]
    const desirablePriceTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const markTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const addSendNumTypes: Array<{ value: number; name: string }> = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]

    const getListInvoice = async () => {
      if (props.customerId) {
        try {
          const { data } = await api.get(
            `${endpoints.CUSTOMER_INVOICE}?customer_id=${
              props.customerId ? props.customerId : customerId.value
            }`
          )
          if (data.length !== 0) {
            const tempData = JSON.parse(JSON.stringify(data[0]))
            invoice.value = toCamelCase(tempData)
            invoiceDefault.value = toCamelCase(tempData)
          } else {
            invoice.value = {
              code: '',
              name: '',
              customerId: null,
              wholesalerCode: '',
              ediVersion: null,
              isMode: false,
              isChart: false,
              isDiffList: false,
              formType: 0,
              countryType: 0,
              packingStyleType: 0,
              offerType: 0,
              orderNoteType: 0,
              catalogType: 0,
              desirablePriceType: 0,
              markType: 0,
              addSendNumType: 0
            }
            invoiceDefault.value = invoice.value
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
    getListInvoice()

    const closeDialog = () => {
      emit('close-dialog')
      getListInvoice()
    }

    // CREATE
    const createInvoice = async () => {
      const data = {
        customer_id: props.customerId ? props.customerId : customerId.value,
        code: invoice.value.code ? invoice.value.code : '',
        name: invoice.value.name ? invoice.value.name : '',
        wholesaler_code: invoice.value.wholesalerCode ? invoice.value.wholesalerCode : '',
        edi_version: invoice.value.ediVersion ? invoice.value.ediVersion : 0,
        is_mode: invoice.value.isMode ? invoice.value.isMode : false,
        is_chart: invoice.value.isChart ? invoice.value.isChart : false,
        is_diff_list: invoice.value.isDiffList ? invoice.value.isDiffList : false,
        form_type: invoice.value.formType ? invoice.value.formType : 0,
        country_type: invoice.value.countryType ? invoice.value.countryType : 0,
        packing_style_type: invoice.value.packingStyleType ? invoice.value.packingStyleType : 0,
        offer_type: invoice.value.offerType ? invoice.value.offerType : 0,
        order_note_type: invoice.value.orderNoteType ? invoice.value.orderNoteType : 0,
        catalog_type: invoice.value.catalogType ? invoice.value.catalogType : 0,
        desirable_price_type: invoice.value.desirablePriceType
          ? invoice.value.desirablePriceType
          : 0,
        mark_type: invoice.value.markType ? invoice.value.markType : 0,
        add_send_num_type: invoice.value.addSendNumType ? invoice.value.addSendNumType : 0
      }
      try {
        loading.value = true
        await api.post(endpoints.CUSTOMER_INVOICE, data)
        emit('on-reload')
        $toast.success(root.$t('master.msg.create_successful'))
      } catch (e) {
        $toast.error(root.$t('master.msg.update_failed'))
        console.log(e)
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    // UPDATE
    const updateInvoice = async () => {
      const data = {
        id: invoice.value.id,
        code: invoice.value.code,
        name: invoice.value.name,
        wholesaler_code: invoice.value.wholesalerCode,
        edi_version: invoice.value.ediVersion,
        is_mode: invoice.value.isMode,
        is_chart: invoice.value.isChart,
        is_diff_list: invoice.value.isDiffList,
        form_type: invoice.value.formType,
        country_type: invoice.value.countryType,
        packing_style_type: invoice.value.packingStyleType,
        offer_type: invoice.value.offerType,
        order_note_type: invoice.value.orderNoteType,
        catalog_type: invoice.value.catalogType,
        desirable_price_type: invoice.value.desirablePriceType,
        mark_type: invoice.value.markType,
        add_send_num_type: invoice.value.addSendNumType
      }
      try {
        loading.value = true
        const result = await api.put(`${endpoints.CUSTOMER_INVOICE}/${invoice.value.id}`, data)
        emit('on-reload')
        if (result === undefined) {
          $toast.error(root.$t('master.msg.update_failed'))
        } else {
          $toast.success(root.$t('master.msg.update_successful'))
        }
      } catch (e) {
        $toast.error(root.$t('master.msg.update_failed'))
        console.log(e)
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    const resetData = () => {
      invoice.value = {
        code: '',
        name: '',
        customerId: null,
        wholesalerCode: '',
        ediVersion: 0,
        isMode: false,
        isChart: false,
        isDiffList: false,
        formType: 0,
        countryType: 0,
        packingStyleType: 0,
        offerType: 0,
        orderNoteType: 0,
        catalogType: 0,
        desirablePriceType: 0,
        markType: 0,
        addSendNumType: 0
      }
    }

    const handleSave = async () => {
      if (invoice.value.customerId !== null || undefined) {
        await updateInvoice()
      } else {
        await createInvoice()
      }
      getListInvoice()
    }

    watch(
      () => props.customerId || props.customerProp,
      () => {
        customerId.value = props.customerId
        resetData()
        getListInvoice()
      }
    )

    return {
      closeDialog,
      handleSave,
      invoice,
      formTypes,
      countryTypes,
      packingStyleTypes,
      offerTypes,
      orderNoteTypes,
      catalogTypes,
      desirablePriceTypes,
      markTypes,
      addSendNumTypes,
      loading
    }
  }
})

export default CustomerDetailInvoiceDialog
