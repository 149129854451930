








import { defineComponent } from '@vue/composition-api'

const LoaderComponent = defineComponent({
  props: {
    loaderType: {
      type: String,
      required: true,
      // type: on vuetify doc
      default: 'list-item@8'
    }
  }
})
export default LoaderComponent
