import Vue from 'vue'
import { sum } from 'lodash'
import { moment, api, i18n } from 'plugins'
import {
  QualitiesByQualityGroup,
  SizesBySizeGroup,
  VarietiesByItem,
  Variety,
  Size,
  Quality,
  MappingKeyAndField
} from 'typings'
import Store from 'store'
import router from 'router'
import { showError } from '../errors'
import { buildQuery } from '../query'
import {
  BOX_TYPE,
  COLORS,
  CUSTOMERS,
  ITEMS,
  ORDER_TYPES,
  QUALITIES,
  QUALITY_GROUPS,
  SIZE_GROUPS,
  SIZES,
  UNITS,
  VARIETIES,
  NOSALE_DATE,
  AR_ITEM,
  AR_UNIT,
  VAT,
  AR,
  CLOSING_GROUP
} from '../apiEndpoints'

// get sum stems packing or harvest
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSumDataStems = (
  mode: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  item?: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  variety?: any,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  qualitySize?: any
) => {
  let dataResult: Array<any> = []
  // get stems packing or harvest item level
  if (item) {
    dataResult = data.filter((e: any) => {
      return e.item.name === item
    })
  }
  // get stems packing or harvest variety level
  if (variety) {
    dataResult = data.filter((e: any) => {
      return e.variety.name === variety
    })
  }
  // get stems packing or harvest qualitySize level
  if (qualitySize) {
    dataResult = data.filter((e: any) => {
      let qualitySizePack = ''
      if (e.quality && e.size) {
        qualitySizePack = e.quality.name + e.size.name
      }
      return qualitySizePack === qualitySize
    })
  }
  if (mode === 'harvest') {
    return sum(dataResult.map((e: any) => e.stems))
  }
  return sum(dataResult.map((e: any) => e.totalStems))
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const matchData = (
  varietiesByItem: Array<VarietiesByItem>,
  sizesBySizeGroup: Array<SizesBySizeGroup>,
  qualitiesByQualityGroup: Array<QualitiesByQualityGroup>
) => {
  let items = []
  const varieties: Array<Variety> = []
  let sizeGroups = []
  const sizes: Array<any> = []
  let qualityGroups = []
  const qualities: Array<any> = []
  // get data items form varietiesByItem
  items = varietiesByItem.map((item: VarietiesByItem) => {
    if (item.varieties.length > 0) {
      // get data varieties form varietiesByItem
      item.varieties.map((variety: Variety) => {
        varieties.push({ ...variety })
        return null
      })
    }
    return { ...item }
  })
  // get data sizeGroups form sizesBySizeGroup
  sizeGroups = sizesBySizeGroup.map((sizeGroup: SizesBySizeGroup) => {
    if (sizeGroup.sizes.length > 0) {
      // get data sizes form sizesBySizeGroup
      sizeGroup.sizes.map((size: Size) => {
        sizes.push(size)
        return null
      })
    }
    return { ...sizeGroup }
  })
  // get data qualityGroups form qualityByQuality
  qualityGroups = qualitiesByQualityGroup.map((qualityByQuality: QualitiesByQualityGroup) => {
    if (qualityByQuality.qualities.length > 0) {
      // get data qualities form qualityByQuality
      qualityByQuality.qualities.map((quality: Quality) => {
        qualities.push(quality)
        return null
      })
    }
    return { ...qualityByQuality }
  })

  return { items, varieties, sizes, sizeGroups, qualities, qualityGroups }
}

// set date range in calendar -> (date start, date end)
// return:
//    packedDateStart
//    packedDateEnd
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setDateRange = (dateRange: Array<string | any>) => {
  const dates = dateRange
  let dateStart = ''
  let dateEnd = ''
  if (dates.length === 1 || dates[0] === dates[1]) {
    // eslint-disable-next-line prefer-destructuring
    dateStart = dates[0]
    dateEnd = dateStart
  } else {
    // eslint-disable-next-line no-lonely-if
    if (dates[0] >= dates[1]) {
      // eslint-disable-next-line prefer-destructuring
      dateStart = dates[1]
      // eslint-disable-next-line prefer-destructuring
      dateEnd = dates[0]
    } else {
      // eslint-disable-next-line prefer-destructuring
      dateStart = dates[0]
      // eslint-disable-next-line prefer-destructuring
      dateEnd = dates[1]
    }
  }
  return { dateStart, dateEnd }
}

// get, display text of date range on input field
// return:
//    text of date
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getDatesText = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  root: any,
  dateRange: Array<string | any>,
  dateStart: string | any,
  dateEnd: string | any
) => {
  if (dateRange.length === 1 || dateStart === dateEnd || dateRange[0] === dateRange[1]) {
    return moment(dateStart).format(root.$t('common.time_month_date').toString())
  }
  const dates = [
    moment(dateStart).format(root.$t('common.time_month_date').toString()),
    moment(dateEnd).format(root.$t('common.time_month_date').toString())
  ]
  return dates.join(' ~ ')
}
export const getClassOrderType = (orderTypeId: number | null): string => {
  const AUCTION = 1
  const REQUEST = 2
  const ORDER = 3
  const FM = 4
  const SCHEDULE = 5
  const BUY = 6
  let className = ''
  switch (orderTypeId) {
    case ORDER:
      className = 'teal--text'
      break
    case AUCTION:
    case FM:
    case REQUEST:
    case SCHEDULE:
    case BUY:
    default:
      className = ''
  }
  return className
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const numberToString = (number: number, isRounding = false, maximumFractionDigits = 2) => {
  // eslint-disable-next-line no-param-reassign
  if (number && isRounding) number = Math.round(number)
  return number ? number.toLocaleString('en-US', { maximumFractionDigits }) : '0'
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const compactText = (text: any, maxLength: any) => {
  if (!text) return
  if (text.length < maxLength) {
    // eslint-disable-next-line consistent-return
    return text
  }
  // eslint-disable-next-line consistent-return
  return `${text.slice(0, maxLength - 1)}...`
}
export const backPage = (): void => {
  const historiesStore = Store.getters.showHistories
  Store.commit('popHistory')
  const lastRoute = historiesStore.histories[historiesStore.histories.length - 1]
  Store.commit('popHistory')
  router.push(lastRoute)
}

const getWidth = (text: string, startFontSize: number) => {
  const arrayText = text.split('')
  const charsString =
    '`~!@#$%^&*()_+-={}[]/|:;<>,.? 01234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM' +
    ' ｡｢｣､･ｦｧｨｩｪｫｬｭｮｯｰｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜﾝﾞﾟ株'
  const charsArray = charsString.split('')
  const arrayWidth13px = [
    7.890625, 7.21875, 4.203125, 12.3125, 7.21875, 7.21875, 11.984375, 7.21875, 8.84375, 6.078125,
    4.40625, 4.40625, 7.28125, 7.21875, 4.515625, 7.21875, 4.40625, 4.40625, 4.40625, 4.40625,
    5.109375, 3.5, 3.625, 3.625, 7.21875, 7.21875, 3.625, 3.625, 6.171875, 2.921875, 7.21875,
    7.21875, 7.21875, 7.21875, 7.21875, 7.21875, 7.21875, 7.21875, 7.21875, 7.21875, 7.21875,
    8.0625, 10.4375, 7.203125, 5.046875, 4.90625, 6.78125, 7.90625, 3.578125, 7.890625, 8.0625,
    7.34375, 6.09375, 8.0625, 4.234375, 7.328125, 7.90625, 3.59375, 7.1875, 3.71875, 6.171875,
    6.484375, 6.625, 6.78125, 8.046875, 7.953125, 12.046875, 9.65625, 11.421875, 7.65625, 8.28125,
    7.796875, 6.90625, 9.375, 3.8125, 9.65625, 8.21875, 7.90625, 7.765625, 8.9375, 7.1875, 8.96875,
    9.46875, 6.96875, 8.40625, 7.0625, 7.84375, 7.453125, 8.296875, 7.484375, 8.546875, 9.390625,
    10.5625, 2.921875, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5,
    6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5,
    6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5,
    6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 13.015625
  ]
  const fontSizeRatio = startFontSize / 13
  const arrayWidth = arrayWidth13px.map((x) => {
    return x * fontSizeRatio
  })
  let totalWidth = 0
  arrayText.forEach((char) => {
    if (charsArray.indexOf(char) > -1) {
      const index = charsArray.indexOf(char)
      const charWidth = arrayWidth[index]
      totalWidth += charWidth
    } else {
      // a width of japanese characters
      totalWidth += arrayWidth[arrayWidth.length - 1]
    }
  })
  return totalWidth
}

export const fontResizeRatio = (
  text: string,
  maximumWidthAllow: number,
  startFontSize: number
): any => {
  const textWidth = getWidth(text, startFontSize)
  let fontSizeRatio = 1
  const propertiesObj = Object()
  if (textWidth > maximumWidthAllow) {
    fontSizeRatio = textWidth / maximumWidthAllow
    propertiesObj.textAlign = 'justify'
  } else {
    propertiesObj.textAlign = ''
  }
  // props css after resize
  propertiesObj.fontSize = startFontSize / fontSizeRatio
  propertiesObj.transformYRatio = fontSizeRatio
  return propertiesObj
}

export const checkIsOnPC = (): boolean => {
  // BREAK USER AGENT DOWN
  const isMobile = navigator.userAgent.toLowerCase().match(/mobile/i)
  const isTablet = navigator.userAgent.toLowerCase().match(/tablet/i)
  const isAndroid = navigator.userAgent.toLowerCase().match(/android/i)
  const isiPhone = navigator.userAgent.toLowerCase().match(/iphone/i)
  const isiPad = navigator.userAgent.toLowerCase().match(/ipad/i)
  return !(isMobile || isTablet || isAndroid || isiPhone || isiPad)
}

export const mappingKeyAndField: Array<MappingKeyAndField> = [
  { keyCode: 49, field: 'customer' }, // 1
  { keyCode: 50, field: 'buyerInfo' }, // 2
  { keyCode: 51, field: 'item' }, // 3
  { keyCode: 52, field: 'variety' }, // 4
  { keyCode: 53, field: 'quality' }, // 5
  { keyCode: 54, field: 'size' }, // 6
  { keyCode: 55, field: 'quantity' }, // 7
  { keyCode: 56, field: 'boxes' }, // 8
  { keyCode: 57, field: 'boxType' }, // 9
  { keyCode: 48, field: 'price' } // 0
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const replaceUrl = (query: any = {}, params: any = {}) => {
  // type query and params is object
  // exp: {id: 1}
  router
    .replace({
      query,
      params
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .catch((e: any) => e)
}

export const getOneApi = async (url: string): Promise<any> => {
  try {
    const { data } = await api.get(url)
    return data
  } catch (e: any) {
    showError(e, Vue.$toast, i18n.t('common.get_data_failed'))
  }
  return null
}

export const getNextAuctionDate = async (): Promise<any> => {
  return (
    (await getOneApi(`${NOSALE_DATE}/next_auction_date`))?.date ||
    moment(new Date()).format('YYYY-MM-DD')
  )
}

export const getData = async (
  listData = ['color', 'quality', 'size', 'boxType', 'unit', 'arItem', 'arUnit', 'vat', 'ar'],
  queryData: any = {},
  getOne: any = {}
): Promise<any> => {
  const result = {
    items: null,
    varieties: null,
    colors: null,
    qualities: null,
    sizes: null,
    boxTypes: null,
    units: null,
    orderTypes: null,
    qualityGroups: null,
    sizeGroups: null,
    customers: null,
    closingGroups: null,
    arItems: null,
    arUnits: null,
    vat: null,
    ars: null
  }

  const queryItem = queryData.item ? buildQuery(queryData.item) : ''
  const queryVariety = queryData.variety ? buildQuery(queryData.variety) : ''
  // const querySaleBreakDown = queryData.saleBreakDown ? buildQuery(queryData.saleBreakDown) : ''
  // const queryBill = queryData.bill ? buildQuery(queryData.bill) : ''
  const queryAr = queryData.ar ? buildQuery(queryData.ar) : ''
  // const queryPayment = queryData.payment ? buildQuery(queryData.payment) : ''

  const customerId = getOne?.customer || ''
  const varietyId = getOne?.variety || ''
  const vatDate = getOne?.date || ''
  try {
    await Promise.all([
      listData.includes('item')
        ? api
            .get(`${ITEMS}?${queryItem}`)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.items = value.data))
        : null,
      listData.includes('variety')
        ? api
            .get(`${VARIETIES}${varietyId}?${queryVariety}`)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.varieties = value.data))
        : null,
      listData.includes('color')
        ? api
            .get(COLORS)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.colors = value.data))
        : null,
      listData.includes('quality')
        ? api
            .get(QUALITIES)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.qualities = value.data))
        : null,
      listData.includes('size')
        ? api
            .get(SIZES)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.sizes = value.data))
        : null,
      listData.includes('boxType')
        ? api
            .get(BOX_TYPE)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.boxTypes = value.data))
        : null,
      listData.includes('unit')
        ? api
            .get(UNITS)
            // eslint-disable-next-line no-return-assign
            .then((value: any) => (result.units = value.data))
        : null,
      listData.includes('assignType')
        ? api
            .get(ORDER_TYPES)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.orderTypes = value.data))
        : null,
      listData.includes('qualityGroup')
        ? api
            .get(QUALITY_GROUPS)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.qualityGroups = value.data))
        : null,
      listData.includes('sizeGroup')
        ? api
            .get(SIZE_GROUPS)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.sizeGroups = value.data))
        : null,
      listData.includes('customer')
        ? api
            .get(`${CUSTOMERS}${customerId}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.customers = value.data))
        : null,
      listData.includes('arItem')
        ? api
            .get(`${AR_ITEM}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.arItems = value.data))
        : null,
      listData.includes('arUnit')
        ? api
            .get(`${AR_UNIT}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.arUnits = value.data))
        : null,
      listData.includes('vat')
        ? api
            .get(`${VAT}${vatDate}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.vat = value.data))
        : null,
      listData.includes('closingGroup')
        ? api
            .get(`${CLOSING_GROUP}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.closingGroups = value.data))
        : null,
      listData.includes('ar')
        ? api
            .get(`${AR}?${queryAr}`)
            // eslint-disable-next-line no-return-assign
            .then((value) => (result.ars = value.data))
        : null
    ])
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.get_data_failed'))
  }
  return result
}

export const postData = async (endpoint: string, payload: any, showMsg = true): Promise<any> => {
  try {
    const { data } = await api.post(endpoint, payload)
    if (showMsg) Vue.$toast.success(i18n.t('common.msg.create_success'))
    return [data, true]
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.get_data_failed'))
  }
  return [null, false]
}

export const putData = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await api.put(`${endpoint}`, data)
    Vue.$toast.success(i18n.t('common.msg.update_success'))
    return [response.data, true]
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.get_data_failed'))
  }
  return [null, false]
}

export const patchData = async (endpoint: string, data: any): Promise<any> => {
  try {
    const response = await api.patch(`${endpoint}`, data)
    Vue.$toast.success(i18n.t('common.msg.update_success'))
    return [response.data, true]
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.get_data_failed'))
  }
  return [null, false]
}

export const deleteData = async (endpoint: string): Promise<any> => {
  try {
    const response = await api.delete(`${endpoint}`)
    Vue.$toast.success(i18n.t('common.msg.delete_success'))
    return response.data
  } catch (e) {
    showError(e, Vue.$toast, i18n.t('common.msg.delete_failed'))
  }
  return null
}
