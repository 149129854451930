






























import { defineComponent, Ref, ref } from '@vue/composition-api'
import AddButton from '@/components/AddButton/index.vue'

interface Button {
  name: string
  icon: string
}

interface SetUp {
  add: () => void
  sort: () => void
  clickAddBtn: () => void
  actionByIndex: (index: number) => void
  showAddBtn: Ref<boolean>
  showSortNewBtns: Ref<boolean>
  buttons: Ref<Button[]>
}

const MasterSpeedDial = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  components: {
    AddButton
  },
  setup(props, { emit }): SetUp {
    const showAddBtn: Ref<boolean> = ref(true)
    const showSortNewBtns: Ref<boolean> = ref(false)

    const buttons: Ref<Button[]> = ref([
      {
        name: 'Sort',
        icon: 'mdi-sort-ascending'
      },
      {
        name: 'New',
        icon: 'mdi-plus'
      }
    ])

    const clickAddBtn = (): void => {
      showAddBtn.value = !showAddBtn.value
      setTimeout(() => {
        showSortNewBtns.value = !showSortNewBtns.value
      }, 400)
    }

    const add = (): void => {
      emit('on-add')
    }
    const sort = (): void => {
      emit('on-sort')
    }

    const actionByIndex = (index: number): void => {
      switch (index) {
        case 0:
          sort()
          break
        default:
          add()
          break
      }
    }

    return { add, sort, actionByIndex, clickAddBtn, showAddBtn, buttons, showSortNewBtns }
  }
})

export default MasterSpeedDial
