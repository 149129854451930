


























import { ref, Ref, defineComponent, watch } from '@vue/composition-api'
import { urlPath } from 'utils'

interface SetUp {
  route: Ref<number>
}

const Navigation = defineComponent({
  props: {},
  setup(props, { root }): SetUp {
    const route: Ref<number> = ref(0)

    const navigateToPage = (page: string): void => {
      if (page !== root.$route.path) {
        root.$router.push({
          path: page,
          query: { called: 'true' }
        })
      }
    }

    watch(
      () => root.$route,
      () => {
        const { path } = root.$route
        const index = urlPath.DISABLE_NAVIGATION.indexOf(path)
        if (index !== -1) {
          route.value = index
        }
      }
    )

    watch(route, () => {
      let url = urlPath.MENU.path
      switch (route.value) {
        case 1:
          url = urlPath.ORDER_SUMMARY.path
          break
        case 2:
          url = urlPath.ORDER_FORM.path
          break
        case 3:
          url = urlPath.SALES.path
          break
        case 4:
          url = urlPath.SETTING.path
          break
        default:
      }
      navigateToPage(url)
    })

    return {
      route
    }
  }
})
export default Navigation
