import Vue from 'vue'
import router from 'router'
import store from 'store'
import VueCompositionAPI from '@vue/composition-api'
import PortalVue from 'portal-vue'
import Toast from 'vue-toastification'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-toastification/dist/index.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { vuetify, i18n, api, moment, numbro } from 'plugins'
import { Amplify } from 'aws-amplify'
import App from './App.vue'
import './registerServiceWorker'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USERPOOL_APP_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN_NAME,
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_SIGN_OUT,
      responseType: 'code'
    }
  }
})

Vue.config.productionTip = false

Vue.prototype.$api = api
Vue.prototype.moment = moment
Vue.prototype.numbro = numbro

Vue.use(VueCompositionAPI)
Vue.use(PortalVue)
Vue.use(Toast, { hideProgressBar: true })
Vue.use(VueVirtualScroller)

declare global {
  interface Window {
    logout?: any
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
