import { chain, sumBy } from 'lodash'

/* eslint-disable @typescript-eslint/no-explicit-any */
const getTotalProperties = (values: any, properties: Array<string>): any => {
  const obj = {}
  properties.forEach((property) => {
    Object.assign(obj, { [property]: sumBy(values, property) })
  })

  return obj
}
/* eslint-disable @typescript-eslint/no-explicit-any */
const getIdLevel = (values: any, groupProperty: string): any => {
  // idLevel is id which is used to group
  // Ex: group by customer is idLevel = customerId
  let idLevel = null
  if (values.length !== 0) {
    if (groupProperty === 'customerName') {
      idLevel = values[0].customer?.id
    } else if (groupProperty === 'itemName') {
      idLevel = values[0].item?.id
    } else if (groupProperty === 'varietyName') {
      idLevel = values[0].variety?.id
    }
  }
  return idLevel
}

export const groupTwoLevel = <T>(
  data: Array<T>,
  totalProperties: Array<string> = [],
  groupProperties: Array<string> = ['itemName']
): any => {
  const formatData = data.map((item: any) => {
    const qualitySizeName = `${item.quality?.name || ''}${item.size?.name || ''}` || ('' as string)
    // console.log(item)
    return {
      qualitySizeName,
      itemName: item?.itemName || item.item?.name || ('' as string),
      varietyName: item?.varietyName || item.variety?.name || '',
      ...item
    }
  })

  return chain(formatData)
    .groupBy(groupProperties[0])
    .map((value, key) => ({
      [groupProperties[0]]: key,
      children: value,
      ...getTotalProperties(value, totalProperties),
      idLevel: getIdLevel(value, groupProperties[0]),
      isAssigned: !value.find((chi: any) => chi.isAssigned === false),
      isConfirmed: !value.find((chi: any) => chi.isConfirmed === false)
    }))
    .value()
}
