













































import { defineComponent, toRefs, Ref, ref, getCurrentInstance, watch } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'

import { api } from 'plugins'
import { endpoints, showError, toCamelCase, toSnakeCase } from 'utils'
import { ArItem } from 'typings'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  createArItem: () => void
  updateArItem: () => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  rateOfTax: Ref<Array<{ key: string; name: string }>>
  isShowGenCode: Ref<boolean>
  arItemsList: Ref<Array<ArItem>>
  toggleGenCode: () => void
}

const ArItemDialog = defineComponent({
  components: {
    DialogContainer,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    arItem: {
      type: Object,
      required: true,
      default: null
    },
    arUnits: {
      type: Array,
      required: false,
      default: null
    },
    vat: {
      type: Object,
      required: false,
      default: null
    },
    isAddArItem: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { $toast, $store } = root
    const instance = getCurrentInstance()
    const { arItem } = toRefs(props)
    const loading = ref(false)
    const deleteLoading = ref(false)
    const rateOfTax = ref<Array<{ key: string; name: string }>>([])
    const isShowGenCode = ref(false)
    const arItemsList = ref([])
    const toYomi = debounce(async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${arItem.value.name}`)
        arItem.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    const closeDialog = (): void => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    // generate body to create
    const generateBody = () => {
      const { id, name, yomi, code, defaultUnitId, taxRate, isNegative, unitPrice, taxType } =
        arItem.value
      return {
        id,
        name: name.trim(),
        yomi: yomi ? yomi.trim() : null,
        code: code || null,
        defaultUnitId,
        taxRate,
        isNegative,
        unitPrice: unitPrice || null,
        taxType
      }
    }

    // validate input
    const validateInput = async (): Promise<boolean> => {
      const validate = await (refs.observer as InstanceType<typeof ValidationObserver>).validate()
      return validate
    }

    // UPDATE ar item
    const updateArItem = async (isDuplicate = true): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      const body = toSnakeCase(generateBody())
      if (!body) {
        return
      }
      loading.value = true
      try {
        await api.put(`${endpoints.AR_ITEM}?is_duplicate=${isDuplicate}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        // if api return duplicate
        if (e.response?.data?.messages) {
          const msg = e.response?.data?.messages.error_code
          $toast.error(root.$t(`error_code.${msg}`))
        } else {
          showError(e, $toast, root.$t('master.msg.updated_failed') as string)
        }
      } finally {
        loading.value = false
      }
    }

    // CREATE ar item
    const createArItem = async (isDuplicate = true): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      const body = toSnakeCase(generateBody())
      if (!body) {
        return
      }
      loading.value = true
      try {
        if (props.isAddArItem) {
          const { data } = await api.post(`${endpoints.AR_ITEM}?is_duplicate=${isDuplicate}`, body)
          $toast.success(root.$t('master.msg.create_successful'))
          $store.commit('setNewDataMaster', toCamelCase(data))
          emit('on-reload')
          closeDialog()
        }
      } catch (e) {
        // if api return duplicate
        if (e.response?.data?.messages) {
          const msg = e.response.data.messages.error_code
          $toast.error(root.$t(`error_code.${msg}`))
        } else {
          showError(e, $toast, root.$t('master.msg.created_failed') as string)
        }
      } finally {
        loading.value = false
      }
    }

    // DELETE ar item
    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.AR_ITEM}${props.arItem.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const toggleGenCode = async () => {
      isShowGenCode.value = !isShowGenCode.value
      const { data } = await api.get(endpoints.AR_ITEM)
      /* eslint-disable */
      arItemsList.value = data.filter((ar: any) => ar.active === true)
      if (arItemsList.value.length) {
        arItemsList.value.sort((a: any, b: any) => a.code === null ? 1 : (b.code === null ? -1 : a.code - b.code))
      }
    }

    watch(
      () => props.vat,
      () => {
        rateOfTax.value = [
          {
            key: 'rate',
            name: `${props.vat.rate}%`
          },
          {
            key: 'rate2',
            name: `${props.vat.rate2}%`
          }
        ]
      }
    )

    return {
      toYomi,
      closeDialog,
      createArItem,
      updateArItem,
      confirmDelete,
      loading,
      deleteLoading,
      rateOfTax,
      isShowGenCode,
      arItemsList,
      toggleGenCode
    }
  }
})

export default ArItemDialog
