import { api } from 'plugins'
import { Image } from 'typings'

import { IMAGES } from '../apiEndpoints'

export const createOrDeleteImage = async (
  uuidKey: string,
  newImages: Array<Image>,
  deleteImageIds: Array<number> = []
): Promise<void> => {
  if (deleteImageIds.length > 0) {
    await Promise.all(
      deleteImageIds.map(async (id) => {
        api.delete(`${IMAGES}${id}`)
      })
    )
  }

  await Promise.all(
    newImages.map(async (image: Image) => {
      const { data } = await api.post(IMAGES, {
        ...image,
        uuid: uuidKey
      })
      return data
    })
  )
}
