import { sumBy } from 'lodash'
import { PackingResult, HarvestResult, Assign, OrderDetail } from 'typings'

export const PACKING_RESULT_DES = 'packingResult'
export const ORDER_DES = 'order'
export const ASSIGN_DES = 'assign'
export const HARVEST_RESULT_DES = 'harvest'

interface TPackingResult extends PackingResult {
  itemName?: string
  varietyName: string
  qualitySizeName: string
  customerName?: string
}

interface THarvestResult extends HarvestResult {
  itemName?: string
  varietyName: string
  qualitySizeName: string
  customerName?: string
}

interface TAssign extends Assign {
  itemName?: string
  varietyName: string
  qualitySizeName: string
  customerName?: string
}

interface TOrderDetail extends OrderDetail {
  itemName?: string
  varietyName: string
  qualitySizeName: string
  customerName?: string
}

const unique = (value: any, index: any, self: Array<any>) => {
  return self.indexOf(value) === index
}

const iSSameAllPropertiesFourLevel = (
  objectData: any,
  firstLevel: any,
  secondLevel: any,
  thirdLevel: any,
  detail: any
) => {
  return (
    objectData.itemName === firstLevel.itemName &&
    objectData.varietyName === secondLevel.varietyName &&
    objectData.qualitySizeName === thirdLevel.qualitySizeName &&
    objectData.quantity === detail.quantity &&
    objectData.boxType?.id === detail.boxType?.id &&
    objectData.unit?.id === detail.unit?.id
  )
}

const iSSameAllPropertiesTwoLevel = (objectData: any, firstLevel: any, secondLevel: any) => {
  return (
    objectData.itemName === firstLevel.itemName &&
    objectData.varietyName === secondLevel.varietyName &&
    objectData.qualitySizeName === secondLevel.qualitySizeName &&
    objectData.quantity === secondLevel.quantity &&
    objectData.boxType?.id === secondLevel.boxType?.id &&
    objectData.unit?.id === secondLevel.unit?.id
  )
}

/* eslint-disable no-param-reassign */
const getIds = (name: string, level: any) => {
  level[name] = []
  level?.children.forEach((children: any) => {
    level[name] = level[name].concat(children[name])
  })
  level[name] = level[name].filter(unique)
  return level[name]
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const formatData = (stockData: any) => {
  return stockData.map((stock: any) => {
    const qualitySizeName = `${stock.quality?.name || ''}${stock.size?.name || ''}` || 'サイズなし'
    return {
      ...stock,
      isSpecial: stock.isSpecial || false,
      itemName: stock.item.name,
      varietyName: stock.variety.name,
      customerName: stock.customer ? `[${stock.customer.code}] ${stock.customer.name}` : null,
      qualitySizeName
    }
  })
}

/* eslint-disable no-param-reassign */
// Get total stems of paking, harvest, order, expected.... mapping by item, variety, quality, size
export const mappingStock = <T>(
  destinationData: Array<T>,
  {
    packingResults,
    harvestResults,
    assignments,
    orderDetails,
    rawDestination
  }: {
    packingResults?: Array<PackingResult>
    harvestResults?: Array<HarvestResult>
    assignments?: Array<Assign>
    orderDetails?: Array<OrderDetail>
    rawDestination?: Array<any>
  },
  isTwoLevel?: boolean,
  destinationName?: string
): Array<T> => {
  const packingData = packingResults || []
  const harvestData = harvestResults || []
  const assignData = assignments || []
  const orderDetailData = orderDetails || []
  const rawDestinationData = rawDestination || []

  const returnData = [...destinationData]

  const formatPacking = formatData(packingData) as TPackingResult[]
  const formatHarvest = formatData(harvestData) as THarvestResult[]
  const formatAssign = formatData(assignData) as TAssign[]
  const formatOrderDetail = formatData(orderDetailData) as TOrderDetail[]
  const formatRawDestination = formatData(rawDestinationData) as any[]

  const isRawDestinationExistProp = (property: string) => {
    let isExist = false
    if (formatRawDestination.length > 0) {
      isExist = property in formatRawDestination[0]
    }
    return isExist
  }
  const calculateTotal = (level: any, property: string, sumByProperty: string) => {
    let total = 0
    if (
      property === 'packingBoxes' ||
      property === 'packingStems' ||
      property === 'assignedStems' ||
      property === 'assignedBoxes'
    ) {
      if (destinationName === PACKING_RESULT_DES) {
        total = sumBy(level?.children, property)
      } else if (isRawDestinationExistProp('packingId')) {
        total = sumBy(
          formatRawDestination.filter((destination: any) =>
            level.packingIds?.includes(destination.packingId)
          ),
          property
        )
      } else {
        total = sumBy(
          formatPacking.filter((packing: any) => level.packingIds?.includes(packing.id)),
          sumByProperty
        )
      }
    } else if (
      property === 'harvestBoxes' ||
      property === 'harvestStems' ||
      property === 'orderedStems' ||
      property === 'orderedBoxes'
    ) {
      if (destinationName === HARVEST_RESULT_DES) {
        total = sumBy(level?.children, property)
      } else if (isRawDestinationExistProp('harvestId')) {
        total = sumBy(
          formatRawDestination.filter((destination: any) =>
            level.harvestIds?.includes(destination.harvestId)
          ),
          property
        )
      } else {
        total = sumBy(
          formatHarvest.filter((harvest: any) => level.harvestIds?.includes(harvest.id)),
          sumByProperty
        )
      }
    } else if (property === 'orderBoxes' || property === 'orderStems') {
      if (destinationName === ORDER_DES) {
        total = sumBy(level?.children, property)
      } else if (isRawDestinationExistProp('orderId')) {
        total = sumBy(
          formatRawDestination.filter((destination: any) =>
            level.orderIds?.includes(destination.orderId)
          ),
          property
        )
      } else {
        total = sumBy(
          formatOrderDetail.filter((order: any) => level.orderIds?.includes(order.id)),
          sumByProperty
        )
      }
    } else if (property === 'orderedSpecialStems') {
      if (destinationName === ORDER_DES) {
        total = sumBy(level?.children, property)
      } else if (isRawDestinationExistProp('orderId')) {
        total = sumBy(
          formatRawDestination.filter(
            (destination: any) =>
              !destination.boxes && level.orderIds?.includes(destination.orderId)
          ),
          property
        )
      } else {
        total = sumBy(
          formatOrderDetail.filter(
            (order: any) => !order.boxes && level.orderIds?.includes(order.id)
          ),
          sumByProperty
        )
      }
    }
    return total
  }

  // Is 2 level ? For level 2 : For level 4
  const calculateAllPropertyDetail = (
    level: any,
    filteredPackingResults: any[],
    filteredHarvestResults: any[],
    filteredAssigns: any[],
    filteredOrders: any[]
  ) => {
    level.packingStems = level.packingStems || sumBy(filteredPackingResults, 'totalStems')
    level.packingBoxes = level.packingBoxes || sumBy(filteredPackingResults, 'boxes')
    level.packingIds = level.packingId
      ? [level.packingId]
      : filteredPackingResults.map((packing: TPackingResult) => packing.id)

    level.harvestStems = level.harvestStems || sumBy(filteredHarvestResults, 'stems')
    level.harvestBoxes = level.harvestBoxes || sumBy(filteredHarvestResults, 'boxes')
    level.harvestIds = level.harvestId
      ? [level.harvestId]
      : filteredHarvestResults.map((harvest: THarvestResult) => harvest.id)

    level.assignStems = level.assignStems || sumBy(filteredAssigns, 'stems')
    level.assignBoxes = level.assignBoxes || sumBy(filteredAssigns, 'boxes')
    level.assignIds = level.harvestId
      ? [level.harvestId]
      : filteredAssigns.map((assign: TAssign) => assign.id)

    level.orderStems = level.orderStems || sumBy(filteredOrders, 'stems')
    level.orderBoxes = level.orderBoxes || sumBy(filteredOrders, 'boxes')
    level.orderIds = level.orderId
      ? [level.orderId]
      : filteredOrders.map((order: TOrderDetail) => order.id)

    level.orderedStems = level.orderedStems || sumBy(filteredHarvestResults, 'orderedStems')
    level.orderedBoxes = level.orderedBoxes || sumBy(filteredHarvestResults, 'orderedBoxes')

    level.assignedStems = level.assignedStems || sumBy(filteredPackingResults, 'assignedStems')
    level.assignedBoxes = level.assignedBoxes || sumBy(filteredPackingResults, 'assignedBoxes')
    return level
  }

  // Is 2 level ? for level 1 : for level 1, 2, 3
  const calculateAllTotal = (level: any) => {
    // Packing result
    level.packingIds = getIds('packingIds', level)
    level.packingStems = calculateTotal(level, 'packingStems', 'totalStems')
    level.packingBoxes = calculateTotal(level, 'packingBoxes', 'boxes')
    // Harvest result
    level.harvestIds = getIds('harvestIds', level)
    level.harvestStems = calculateTotal(level, 'harvestStems', 'stems')
    level.harvestBoxes = calculateTotal(level, 'harvestBoxes', 'boxes')
    // Assignment
    // Assignment has relation n - 1 to packingResult, orderDetail
    // so don't need unique assignIds
    level.assignIds = getIds('assignIds', level)
    level.assignStems = sumBy(level?.children, 'assignStems')
    level.assignBoxes = sumBy(level?.children, 'assignBoxes')
    // Order
    level.orderIds = getIds('orderIds', level)
    level.orderStems = calculateTotal(level, 'orderStems', 'stems')
    level.orderBoxes = calculateTotal(level, 'orderBoxes', 'boxes')
    level.orderedSpecialStems = calculateTotal(level, 'orderedSpecialStems', 'stems')

    level.orderedStems = calculateTotal(level, 'orderedStems', 'orderedStems')
    level.orderedBoxes = calculateTotal(level, 'orderedBoxes', 'orderedBoxes')
    level.assignedStems = calculateTotal(level, 'assignedStems', 'assignedStems')
    level.assignedBoxes = calculateTotal(level, 'assignedBoxes', 'assignedBoxes')
    level.isSpecial = level.isSpecial || level.children?.some((e: any) => e.isSpecial) || false
    return level
  }

  returnData?.forEach((stock: any) => {
    stock.children?.forEach((variety: any) => {
      variety.children?.forEach((size: any) => {
        size.children?.forEach((detail: any) => {
          const filteredPackingResults = formatPacking.filter((packing: TPackingResult) =>
            iSSameAllPropertiesFourLevel(packing, stock, variety, size, detail)
          )
          const filteredHarvestResults = formatHarvest.filter((harvest: THarvestResult) =>
            iSSameAllPropertiesFourLevel(harvest, stock, variety, size, detail)
          )
          const filteredAssigns = formatAssign.filter((assign: TAssign) =>
            iSSameAllPropertiesFourLevel(assign, stock, variety, size, detail)
          )
          const filteredOrders = formatOrderDetail.filter((order: TOrderDetail) =>
            iSSameAllPropertiesFourLevel(order, stock, variety, size, detail)
          )
          detail = calculateAllPropertyDetail(
            detail,
            filteredPackingResults,
            filteredHarvestResults,
            filteredAssigns,
            filteredOrders
          )

          detail.orderedSpecialStems =
            detail.orderedSpecialStems ||
            sumBy(
              formatOrderDetail.filter(
                (order: TOrderDetail) =>
                  !order.boxes && iSSameAllPropertiesFourLevel(order, stock, variety, size, detail)
              ),
              'stems'
            )
        })
        size = calculateAllTotal(size)
      })
      if (isTwoLevel) {
        const filteredPackingResults = formatPacking.filter((packing: TPackingResult) =>
          iSSameAllPropertiesTwoLevel(packing, stock, variety)
        )
        const filteredHarvestResults = formatHarvest.filter((harvest: THarvestResult) =>
          iSSameAllPropertiesTwoLevel(harvest, stock, variety)
        )
        const filteredAssigns = formatAssign.filter((assign: TAssign) =>
          iSSameAllPropertiesTwoLevel(assign, stock, variety)
        )
        const filteredOrders = formatOrderDetail.filter((order: TOrderDetail) =>
          iSSameAllPropertiesTwoLevel(order, stock, variety)
        )
        variety = calculateAllPropertyDetail(
          variety,
          filteredPackingResults,
          filteredHarvestResults,
          filteredAssigns,
          filteredOrders
        )

        variety.orderedSpecialStems =
          variety.orderedSpecialStems ||
          sumBy(
            formatOrderDetail.filter(
              (order: TOrderDetail) =>
                !order.boxes && iSSameAllPropertiesTwoLevel(order, stock, variety)
            ),
            'stems'
          )
      } else {
        variety = calculateAllTotal(variety)
      }
    })
    stock = calculateAllTotal(stock)
  })
  return returnData
}
