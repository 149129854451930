



























import { defineComponent, ref, toRefs, watch } from '@vue/composition-api'
import { DialogContainer, ConfirmDelete } from 'components'
import { api } from 'plugins'
import { endpoints, showError } from 'utils'

const MemberEditDialog = defineComponent({
  components: {
    DialogContainer,
    ConfirmDelete
  },
  props: {
    isShow: {
      type: Boolean,
      required: false
    },
    itemProps: {
      type: Object,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: false
    },
    cusID: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit, root }) {
    const { itemProps } = toRefs(props)
    const { $toast } = root
    const isAddNewSuccessful = ref(false)
    const MemberModal = ref(false)
    const deleteLoading = ref(false)
    const loading = ref(false)
    const customerID = ref(props.cusID)

    const closeDialog = (): void => {
      emit('close-dialog')
    }

    const resetData = () => {
      itemProps.value.name = ''
      itemProps.value.email = ''
    }

    // CREATE
    const createMember = async () => {
      const data = {
        name: itemProps.value.name ? itemProps.value.name : '',
        email: itemProps.value.email,
        customer_id: customerID.value ? customerID.value : props.cusID,
        mode: 'create'
      }
      if (customerID.value && data.email !== undefined) {
        try {
          loading.value = true
          await api.post(endpoints.CUSTOMER_MEMBER, data)
          emit('on-reload')
          emit('add-member', data)
          $toast.success(root.$t('master.msg.create_successful'))
          closeDialog()
        } catch (e) {
          $toast.error(root.$t('master.msg.update_failed'))
          console.log(e)
        } finally {
          loading.value = false
        }
      } else {
        $toast.error(root.$t('master.msg.please_enter_email'))
      }
    }

    // UPDATE
    const updateMember = async () => {
      // const id = itemProps.value.id
      const data = {
        id: itemProps.value.id,
        name: itemProps.value.name,
        email: itemProps.value.email,
        mode: 'update'
      }
      try {
        loading.value = true
        const result = await api.put(`${endpoints.CUSTOMER_MEMBER}/${props.itemProps.id}`, data)
        emit('on-reload')
        emit('update-member', data)
        if (result === undefined) {
          $toast.error(root.$t('master.msg.update_failed'))
        } else {
          $toast.success(root.$t('master.msg.update_successful'))
        }
        resetData()
      } catch (e) {
        $toast.error(root.$t('master.msg.update_failed'))
        console.log(e)
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    // DELETE
    const confirmDelete = async () => {
      deleteLoading.value = true
      const data = {
        id: itemProps.value.id,
        name: itemProps.value.name,
        email: itemProps.value.email,
        mode: 'delete'
      }
      try {
        await api.delete(
          `${endpoints.CUSTOMER_MEMBER}/${props.itemProps.id}?customer_id=${customerID.value}`
        )
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        emit('delete-data', data)
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        resetData()
        deleteLoading.value = false
      }
    }

    watch(
      () => props.itemProps,
      () => {
        customerID.value = props.cusID
      }
    )

    return {
      closeDialog,
      isAddNewSuccessful,
      MemberModal,
      confirmDelete,
      deleteLoading,
      loading,
      updateMember,
      createMember,
      customerID
    }
  }
})

export default MemberEditDialog
