



















import { defineComponent } from '@vue/composition-api'

const BottomSheetContainer = defineComponent({
  props: {
    show: {
      default: false
    },
    buttons: {
      default: () => []
    }
  },
  setup() {
    return {}
  }
})

export default BottomSheetContainer
