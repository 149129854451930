







































import { defineComponent, Ref, ref } from '@vue/composition-api'
import { checkIsOnPC } from 'utils'
import { Color } from 'typings'

import ColorCircle from '../ColorCircle/index.vue'

interface SetUp {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  textField: any
  showDialog: Ref<boolean>
  isOnPC: boolean
  next: () => void
  prev: () => void
  colors: Array<Color>
  isSelectedColor: (item: Color) => number
  updateSelectedItem: (color: Color) => void
  onTyping: (input: any) => void
}

const ChooseColor = defineComponent({
  components: {
    ColorCircle
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const showDialog = ref(false)
    const colors = props.items as Array<Color>
    const selectedColors = ref(props.selectedItems as Array<Color>)
    const textField: any = ref(null)
    const isOnPC = checkIsOnPC()

    const isSelectedColor = (item: Color): number => {
      let position = -1
      selectedColors.value.forEach((selectedColor: Color, index: number) => {
        if (selectedColor.code === item.code) {
          position = index
        }
      })
      return position
    }

    const updateSelectedItem = (color: Color) => {
      const index = selectedColors.value.findIndex((item) => item.code === color.code)
      if (index !== -1) {
        selectedColors.value = selectedColors.value.filter((item) => item.code !== color.code)
      } else {
        selectedColors.value = [...selectedColors.value, color]
      }
      if (isOnPC) {
        textField.value.focus()
      }
    }

    const next = () => {
      emit('on-change', 'next', selectedColors)
    }

    const prev = () => {
      emit('on-change', 'prev', selectedColors)
    }

    const onTyping = (event: any) => {
      console.log('onTyping')
      if (event.keyCode === 27) {
        // Press ESC
        emit('input', false)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        next()
      } else if (event.keyCode === 13 && event.shiftKey) {
        // Press shift + Enter
        prev()
      }
    }

    return {
      showDialog,
      next,
      prev,
      colors,
      isSelectedColor,
      updateSelectedItem,
      onTyping,
      textField,
      isOnPC
    }
  }
})

export default ChooseColor
