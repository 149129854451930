






















































import { defineComponent } from '@vue/composition-api'
import { LoaderComponent } from 'components'

interface SetUp {
  showOrderDetail: (id: number) => void
  choseOrderEvent: (id: number) => void
}

const OrderItemComponent = defineComponent({
  components: {
    LoaderComponent
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    chosenOrder: {
      type: Number,
      default: 0
    },
    showDetail: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }): SetUp {
    const choseOrderEvent = (id: number) => {
      if (props.showDetail) {
        const chosen = props.chosenOrder === id ? 0 : id
        emit('choseOrderEvent', chosen)
      }
    }

    const showOrderDetail = (index: number) => {
      emit('showOrderDetail', index)
    }

    return {
      showOrderDetail,
      choseOrderEvent
    }
  }
})
export default OrderItemComponent
