


















































































import { defineComponent, Ref, ref, toRefs, reactive, watch, computed } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { snakeCase } from 'lodash'

import { useQuery, useMutation } from 'hooks'
import { endpoints, showError } from 'utils'
import { IQuickInput, Variety, QuickInputState } from 'typings'
import { DialogContainer, ChooseNumber, ChooseProperty, SelectInputRow } from 'components'

interface Property {
  id: number
  name: string
}

const nextStates: QuickInputState = {
  code: 'item',
  item: 'variety',
  variety: 'size',
  size: 'quality',
  quality: 'unit',
  unit: 'quantity',
  quantity: ''
}

const prevStates: QuickInputState = {
  code: '',
  item: 'code',
  variety: 'item',
  size: 'variety',
  quality: 'size',
  unit: 'quality',
  quantity: 'unit'
}

const QuickInputDialog = defineComponent({
  components: {
    DialogContainer,
    ChooseNumber,
    ChooseProperty,
    SelectInputRow
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup(props, { emit, root, refs }) {
    const { $toast } = root

    const { isShow } = toRefs(props)
    const choosePropertyItems: Ref<Array<Property>> = ref([])
    const dialog = ref(false)
    const numberDialog = ref(false)
    const setPropertyName = ref('')

    const initQuickInput: IQuickInput = {
      id: null,
      code: null,
      item: null,
      variety: null,
      size: null,
      unit: null,
      quality: null,
      quantity: null
    }

    const quickInput: IQuickInput = reactive({ ...initQuickInput })

    const url = computed(() => `${endpoints.QUICK_INPUT}${quickInput.id}`)

    const { data: items } = useQuery(`${endpoints.ITEMS}?with_image=false`)
    const { data: varieties } = useQuery(`${endpoints.VARIETIES}?with_image=false`)
    const { data: sizes } = useQuery(endpoints.SIZES)
    const { data: units } = useQuery(endpoints.UNITS)
    const { data: qualities } = useQuery(endpoints.QUALITIES)

    const [createQuickInput, { loading: createLoading }] = useMutation(
      'post',
      endpoints.QUICK_INPUT
    )
    const [updateInput, { loading: updateLoading }] = useMutation('put', url)
    const [deleteInput, { loading: deleteLoading }] = useMutation('delete', url)

    const resetValidate = async () => {
      const validateCode = await (refs.code as InstanceType<typeof ValidationProvider>)
      const validateItem = await (refs.item as InstanceType<typeof ValidationProvider>)
      validateCode.reset()
      validateItem.reset()
    }

    const valiadate = async (): Promise<boolean> => {
      const validateCode = await (refs.code as InstanceType<typeof ValidationProvider>).validate()
      const validateItem = await (refs.item as InstanceType<typeof ValidationProvider>).validate()
      return validateCode.valid && validateItem.valid
    }

    const closeDialog = (): void => {
      resetValidate()
      emit('close-dialog')
    }

    const resetQuickInput = (): void => {
      Object.keys(initQuickInput).forEach((key) => {
        quickInput[key] = initQuickInput[key]
      })
    }

    watch(
      () => isShow.value,
      () => {
        if (isShow.value && !props.isAdd) {
          Object.keys(quickInput).forEach((key) => {
            quickInput[key] = (props.item as IQuickInput)[key]
          })
        }
        if (isShow.value && props.isAdd) {
          resetQuickInput()
        }
      }
    )

    const nextProperty = computed(() => {
      const key = setPropertyName.value
      return nextStates[key as keyof QuickInputState]
    })
    const prevProperty = computed(() => {
      const key = setPropertyName.value
      return prevStates[key as keyof QuickInputState]
    })

    const varietyList = computed(() => {
      const itemId = quickInput.item?.id
      if (!itemId) return []
      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(setPropertyName.value)}.title`) as string
    }

    const chooseProperty = (property: string): void => {
      setPropertyName.value = property
      dialog.value = false
      numberDialog.value = false
      switch (property) {
        case 'code':
          numberDialog.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizes.value
          dialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = units.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualities.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        default:
      }
    }

    const selectProperty = (action: string): void => {
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      } else if (action === 'clickOk') {
        dialog.value = false
        numberDialog.value = false
      }
    }

    const editQuickInput = (action: string, data: Property | number): void => {
      const key = setPropertyName.value
      if (key === 'item') {
        quickInput.variety = null
      }
      quickInput[key] = data || null
      selectProperty(action)
    }

    const generateBody = (): IQuickInput => {
      return {
        code: quickInput.code,
        item: quickInput.item?.id,
        variety: quickInput.variety ? quickInput.variety.id : null,
        size: quickInput.size ? quickInput.size.id : null,
        quality: quickInput.quality ? quickInput.quality.id : null,
        unit: quickInput.unit ? quickInput.unit.id : null,
        quantity: quickInput.quantity || null
      }
    }

    const addQuickInput = async (): Promise<void> => {
      try {
        if (await valiadate()) {
          await createQuickInput(generateBody())
          $toast.success(root.$t('master.msg.create_successful'))
          emit('re-load')
          resetQuickInput()
          await resetValidate()
        }
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed'))
      }
    }

    const updateQuickInput = async (): Promise<void> => {
      try {
        if (await valiadate()) {
          await updateInput(generateBody())
          $toast.success(root.$t('master.msg.update_successful'))
          emit('re-load')
          closeDialog()
        }
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed'))
      }
    }

    const deleteQuickInput = async (): Promise<void> => {
      try {
        await deleteInput()
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('re-load')
        emit('close-dialog')
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed'))
      }
    }

    return {
      createLoading,
      updateLoading,
      deleteLoading,
      closeDialog,
      quickInput,
      dialog,
      choosePropertyItems,
      chooseProperty,
      selectProperty,
      getDialogTitle,
      editQuickInput,
      setPropertyName,
      numberDialog,
      addQuickInput,
      updateQuickInput,
      deleteQuickInput
    }
  }
})

export default QuickInputDialog
