export const listOrderStates = [
  'auctionDate',
  'orderType',
  'customer',
  'buyerInfo',
  'item',
  'variety',
  'colors',
  'quality',
  'size',
  'quantity',
  'unit',
  'boxes',
  'boxType',
  'stems',
  'price',
  'amount'
]

export const initOrderDetail = {
  item: null,
  variety: null,
  orderId: null,
  remark: null,
  size: null,
  boxType: null,
  quality: null,
  unit: null,
  orderType: null,
  colors: [],
  quantity: null,
  boxes: null,
  price: null,
  amount: null,
  buyerInfo: null,
  stems: null,
  harvestResultIds: [],
  isSpecial: false,
  isAssigned: false
}
