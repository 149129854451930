import numbro from 'numbro'

export const formatCurrency = (number: number): string => {
  const formatter = new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY'
  })
  return formatter.format(number)
}

export const formatThousand = (num: number, isFormatThousand: boolean): string => {
  let res = ''
  if (!isFormatThousand) {
    res = num ? num.toLocaleString() : ''
  } else {
    if (num === 0) {
      res = ''
    } else if (num < 1000) {
      res = num.toString()
    } else if (num > 100000) {
      res = numbro(num).format({ thousandSeparated: true, mantissa: 0, average: true })
    } else {
      res = numbro(num).format({ thousandSeparated: true, mantissa: 1, average: true })
      res = res.replace('.0', '')
    }
    res = res.toUpperCase()
  }
  return res
}
