




















































































































































import { defineComponent, ref, toRefs } from '@vue/composition-api'
import { ObjectWrapper, VarietiesByItem, SizesBySizeGroup } from 'typings'

// type of group data
type GroupItem = VarietiesByItem | SizesBySizeGroup

const MasterItemGroupList = defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true
    },
    groupHasNoItemText: {
      type: String,
      default: ''
    },
    groupItemName: {
      type: String,
      default: ''
    },
    editGroupItemText: {
      type: String,
      default: ''
    },
    addChildItemText: {
      type: String,
      default: ''
    },
    // if data is heavy, should use this to avoid impact to performance
    useVueVirtualScroller: {
      type: Boolean,
      required: true,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const showGroupBottomSheet = ref(false)
    const { items } = toRefs(props)
    const openingBottomSheetGroupId = ref(0)

    const toggleGroup = (groupIndex: number): void => {
      emit('toggle-group', groupIndex)
    }

    const calculateHeight = (itemLength: number): number => {
      let height: number
      if (itemLength * 48 < window.innerHeight) {
        height = itemLength * 48
      } else {
        height = window.innerHeight
      }
      return height
    }

    const openVarietyDialog = () => {
      emit('add-variety', openingBottomSheetGroupId.value)
    }

    const clickItem = (item: ObjectWrapper): void => {
      emit('click-item', item)
    }

    const openBottomSheet = (groupId: number, index?: number): void => {
      emit('open-group-by-index', index)
      emit('toggle-group', index, false)
      openingBottomSheetGroupId.value = groupId
      showGroupBottomSheet.value = true
    }

    const openGroupItemDialog = (): void => {
      const item = items.value.find(
        (element) => (element as GroupItem).id === openingBottomSheetGroupId.value
      )
      showGroupBottomSheet.value = false
      emit('open-group-dialog', item)
    }

    const openReadonlyGroupItemDialog = (item: GroupItem): void => {
      emit('open-group-dialog', item)
    }

    return {
      showGroupBottomSheet,
      openingBottomSheetGroupId,
      openBottomSheet,
      toggleGroup,
      calculateHeight,
      clickItem,
      openGroupItemDialog,
      openReadonlyGroupItemDialog,
      openVarietyDialog
    }
  }
})
export default MasterItemGroupList
