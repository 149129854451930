


















import { defineComponent, ref, Ref } from '@vue/composition-api'

interface SetUp {
  choose: Ref<boolean>
  selectColor(): void
}

const ColorCircle = defineComponent({
  props: {
    color: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'large'
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }): SetUp {
    const choose = ref(props.selected)

    const selectColor = (): void => {
      choose.value = !choose.value
      emit('on-selected', props.color)
    }
    return {
      choose,
      selectColor
    }
  }
})
export default ColorCircle
