




























































import { defineComponent, ref, Ref, computed, ComputedRef } from '@vue/composition-api'
import { checkIsOnPC } from 'utils'

import ConfirmDelete from '../ConfirmDelete/index.vue'

interface SetUp {
  showConfirmDelete: Ref<boolean>
  isOnPC: boolean
  close: () => void
  create: () => void
  deleteItem: () => void
  update: () => void
  confirmDelete: (param: string) => void
  isAssignOrCalendar: ComputedRef<boolean>
}

const DialogContainer = defineComponent({
  components: {
    ConfirmDelete
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    mode: {
      type: String,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false
    },
    deleteLoading: {
      type: Boolean,
      required: false
    },
    isBack: {
      type: Boolean,
      required: false,
      default: false
    },
    isFull: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }): SetUp {
    const showConfirmDelete = ref(false)
    const isOnPC = checkIsOnPC()

    const close = (): void => {
      emit('on-close')
    }

    const create = (): void => {
      emit('on-create')
    }

    const deleteItem = (): void => {
      showConfirmDelete.value = true
    }

    const confirmDelete = (param: string): void => {
      if (param === 'delete') {
        emit('on-delete')
      }
      showConfirmDelete.value = false
    }

    const update = (): void => {
      emit('on-update')
    }

    const isAssignOrCalendar = computed(() => {
      return props.mode === 'assign' || props.mode === 'calendar'
    })

    return {
      showConfirmDelete,
      close,
      create,
      deleteItem,
      update,
      confirmDelete,
      isAssignOrCalendar,
      isOnPC
    }
  }
})

export default DialogContainer
