




















import { defineComponent, toRefs, Ref, ref } from '@vue/composition-api'

import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, constants } from 'utils'
import { ISetting } from 'typings'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  closeDialog: () => void
  updateSetting: () => Promise<void>
  loading: Ref<boolean>
  HIDE_TEXT_FIELD: Array<string>
  ORDER_BY_PRODUCT_NAME: string
  ORDER_BY_BUNDLE_NO: string
  ORDER_BY_ORDER_TYPE: string
}

const SettingDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    setting: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup(props, { root, emit }): SetUp {
    const { $toast } = root
    const { setting } = toRefs(props)
    const HIDE_TEXT_FIELD = ['treeview_level', 'invoice_order_by']
    const ORDER_BY_BUNDLE_NO = constants.orderByKey.BUNDLE_NO
    const ORDER_BY_PRODUCT_NAME = constants.orderByKey.PRODUCT_NAME
    const ORDER_BY_ORDER_TYPE = constants.orderByKey.ORDER_TYPE

    const loading = ref(false)

    const closeDialog = (): void => {
      loading.value = false
      emit('on-close')
    }

    const generateBody = (): ISetting => {
      return {
        key: setting.value.key,
        value: setting.value.value.trim()
      }
    }

    const updateSetting = async (): Promise<void> => {
      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.SETTINGS}${setting.value.id}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    return {
      closeDialog,
      updateSetting,
      loading,
      HIDE_TEXT_FIELD,
      ORDER_BY_PRODUCT_NAME,
      ORDER_BY_ORDER_TYPE,
      ORDER_BY_BUNDLE_NO
    }
  }
})

export default SettingDialog
