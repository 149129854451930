





















import { defineComponent } from '@vue/composition-api'

interface SetUp {
  clickBtn: () => void
}

const AddButton = defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-plus'
    },
    tooltipLabel: {
      type: String,
      default: 'Add'
    }
  },
  setup(props, { emit }): SetUp {
    const clickBtn = (): void => {
      emit('clickBtn')
    }

    return { clickBtn }
  }
})

export default AddButton
