

























































































































import { defineComponent, ref, watch, toRefs } from '@vue/composition-api'
import { StepButton } from 'components'
import { api } from 'plugins'
import { convertToWidthCharacter, endpoints, showError, toSnakeCase } from 'utils'
import { debounce, sumBy, sum } from 'lodash'
import { Color } from 'typings'
import InputNumberIncDec from '../InputNumberIncDec/index.vue'
import ConfirmAssign from './confirmAssign.vue'

const AssignToMultipleCustomersDialog = defineComponent({
  components: {
    StepButton,
    InputNumberIncDec,
    ConfirmAssign
  },
  props: {
    customersProps: {
      type: Array,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    },
    assigns: {
      type: Array,
      required: true
    },
    customerCheckAndBoxes: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const ORDER_TYPE_ID = 1 // Type is seri
    const { $toast } = root
    const loading = ref(false)
    const benched = ref(0)
    const { customersProps, assigns } = toRefs(props)
    const customers = ref<any[]>([])
    const customersCheck = ref<any[]>([])
    const customerCheckIds = ref<number[]>([])
    const searchedCustomers = ref<any[]>([])
    const searchInfo = ref('')
    const isSelectAll = ref(false)
    const boxes = ref(0)
    const showNumBerDialog = ref(false)
    const totalBoxCurrent = ref(0)
    const assignedCustomers = ref<number[]>([])
    const isShowProposalText = ref(false)
    const isConfirmShow = ref(false)
    const boxesAssignThisAuctionDate = ref(0)

    const generateBody = (customersInfo: any[]) => {
      return {
        auctionDate: props.auctionDate,
        buyerInfo: props.data.buyerInfo,
        customersInfo: [...customersInfo],
        itemId: props.data.item.id,
        varietyId: props.data.variety.id,
        sizeId: props.data.size.id,
        qualityId: props.data.quality.id,
        colorIds: props.data.colors ? props.data.colors.map((color: Color) => color.id) : [],
        unitId: props.data.unit.id,
        orderTypeId: ORDER_TYPE_ID,
        boxTypeId: props.data.boxType.id,
        quantity: props.data.quantity,
        boxes: boxes.value,
        stems: props.data.quantity * boxes.value,
        price: props.data.desiredPrice,
        amount: props.data.desiredPrice * props.data.quantity * boxes.value,
        remark: props.data.remark,
        orderDetailId: props.data.orderDetailId,
        packingResultId: props.data.id,
        breakdowns: []
      }
    }

    const getTotalBoxesCurrent = () => {
      totalBoxCurrent.value =
        sum(
          searchedCustomers.value.map((customer: any) => {
            return customerCheckIds.value.indexOf(customer.id) > -1
              ? 0
              : customer.assigned.seri.boxes
          })
        ) +
        sum(
          customersCheck.value.map((customer: any) => {
            return customer.boxes
          })
        ) -
        boxesAssignThisAuctionDate.value
    }

    const createAssignAPI = async () => {
      let customersInfo = customersCheck.value.map((customer: any) => ({
        assignId: customer.assigned,
        customerId: customer.id,
        boxes: customer.boxes
      }))
      // remove element with boxes = 0 if create
      customersInfo = customersInfo.filter(
        (customer: any) =>
          !(customer.boxes === 0 && assignedCustomers.value.indexOf(customer.customerId) === -1)
      )
      const body = generateBody(customersInfo)
      try {
        await api.post(
          `${endpoints.ASSIGNMENTS}create_from_packing_multiple_customer`,
          toSnakeCase(body)
        )
        customersCheck.value = []
        boxes.value = 0
        emit('on-check-customer', customersCheck.value)
        emit('on-close')
        emit('re-load')
        isConfirmShow.value = false
        $toast.success(root.$t('assign_seri.assign_successful'))
      } catch (e) {
        showError(e, $toast, root.$t('assign_seri.create_failed') as string)
      }
    }

    const createAssign = async () => {
      if (props.data.packingBoxes - props.data.assignBoxes - totalBoxCurrent.value < 0) {
        // if (totalInputBoxes.value > props.data.packingBoxes) {
        // $toast.error(root.$t('assign_seri.must_be_less_than_total_packing'))
        isConfirmShow.value = true
        return
      }
      await createAssignAPI()
    }

    const handleClickOk = () => {
      createAssignAPI()
    }

    const save = async () => {
      if (customersCheck.value.length > 0) {
        await createAssign()
      } else {
        $toast.error(root.$t('assign_seri.no_customer'))
      }
    }

    const calculator = (mode: string) => {
      if (mode === 'subtraction') {
        boxes.value += 1
      } else {
        boxes.value -= 1
      }
    }

    const search = debounce(async () => {
      // include search by halfwidth character (convert to fullwidth)
      const searchText = convertToWidthCharacter(searchInfo.value.trim().toLowerCase(), 'full')
      const searchCustomerStartsWith = customers.value
        .filter((item: any): boolean => {
          const searchStr = item.searchStr.toLowerCase()
          const regex = new RegExp(`^${searchText}|\\|${searchText}`)
          return regex.test(searchStr) && !item.isCheck
        })
        .map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.boxes = item.boxes || 0
          return item
        })
      const searchCustomerIncludes = customers.value
        .filter((item: any): boolean => {
          const searchStr = item.searchStr.toLowerCase()
          const regex = new RegExp(`^${searchText}|\\|${searchText}`)
          return searchStr.includes(searchText) && !regex.test(searchStr) && !item.isCheck
        })
        .map((item: any) => {
          // eslint-disable-next-line no-param-reassign
          item.boxes = item.boxes || 0
          return item
        })
      searchedCustomers.value = [...searchCustomerStartsWith, ...searchCustomerIncludes]
    }, 300)

    const onCheckCustomer = (customer: any) => {
      // check this customer checked ?
      const indexExists = customersCheck.value.findIndex((e: any) => e.id === customer.id)
      if (indexExists !== -1) {
        customersCheck.value.splice(indexExists, 1)
      } else {
        // eslint-disable-next-line no-param-reassign
        customer.boxes =
          searchedCustomers.value.find((e: any) => e.id === customer.id)?.assigned.seri.boxes ||
          boxes.value
        customersCheck.value.push(customer)
      }
      if (customersCheck.value.length < customers.value.length) {
        isSelectAll.value = false
      }
      customerCheckIds.value = customersCheck.value.map((customerCheck: any) => customerCheck.id)
      emit('on-check-customer', customersCheck.value)
      // get total input box current
      getTotalBoxesCurrent()
    }

    const onSelectAll = () => {
      customersCheck.value = []
      boxes.value = isSelectAll.value ? boxes.value : 0
      customersCheck.value = isSelectAll.value
        ? customers.value.map((customer: any) => {
            // eslint-disable-next-line no-param-reassign
            customer.boxes =
              searchedCustomers.value.find((e: any) => e.id === customer.id)?.assigned.seri.boxes ||
              boxes.value
            return customer
          })
        : []
      customerCheckIds.value = customersCheck.value.map((customerCheck: any) => customerCheck.id)
      emit('on-check-customer', customersCheck.value)
    }

    const processBegin = () => {
      searchedCustomers.value = customers.value.map((customer: any, index: number) => {
        const orderSeri = assigns.value.filter(
          (assign: any) =>
            assign.packingResult === props.data.id && assign.customer.id === customer.id
        )
        const orders = orderSeri.filter((assign: any) => {
          return assign.orderType.id === 3
        })
        const order = {
          stems: sumBy(orders, 'stems'),
          boxes: sumBy(orders, 'boxes')
        }
        const seris = orderSeri.filter((assign: any) => {
          return assign.orderType.id === 1
        })
        const seri = {
          stems: sumBy(seris, 'stems'),
          boxes: sumBy(seris, 'boxes')
        }
        if (seri.boxes > 0 && assignedCustomers.value.indexOf(customer.id) === -1)
          assignedCustomers.value.push(customer.id)
        customers.value[index].assigned = { order, seri }
        // get default master customer checked
        return { ...customer }
      })
      boxesAssignThisAuctionDate.value = sumBy(
        searchedCustomers.value.map((c: any) => c.assigned.seri),
        'boxes'
      )
    }

    const updateBoxes = (data: number, customer: any | null) => {
      if (customer) {
        const customerIndex = customersCheck.value.indexOf(customer)
        // eslint-disable-next-line no-param-reassign
        customer.boxes = data
        customersCheck.value[customerIndex] = customer
      } else {
        boxes.value = data
        customersCheck.value.map((element: any) => {
          // eslint-disable-next-line no-param-reassign
          element.boxes = data
          return customer
        })
      }
      customerCheckIds.value = customersCheck.value.map((customerCheck: any) => customerCheck.id)
      emit('on-check-customer', customersCheck.value)
      // get total input box current
      getTotalBoxesCurrent()
    }

    const enableCheckPriority = () => {
      searchedCustomers.value.map((customer: any) => {
        if (customer.seriPriority && customerCheckIds.value.indexOf(customer.customerId) === -1)
          onCheckCustomer(customer)
        return 0
      })
    }

    watch(
      () => props.value,
      () => {
        if (props.value) {
          customers.value = customersProps.value.filter((customer: any) => customer.id > 0)
          // get customer checked in the last open dialog
          if (props.customerCheckAndBoxes[props.data.id]) {
            customersCheck.value = props.customerCheckAndBoxes[props.data.id].data
              ? props.customerCheckAndBoxes[props.data.id].data
              : []
          } else {
            customersCheck.value = []
            boxes.value = 0
          }
          isSelectAll.value = customersCheck.value.length === customers.value.length
          processBegin()
          customerCheckIds.value = customersCheck.value.map(
            (customerCheck: any) => customerCheck.id
          )
          // auto checked if the first open
          if (!props.customerCheckAndBoxes[props.data.id]) enableCheckPriority()
          else if (props.customerCheckAndBoxes[props.data.id].data.length === 0)
            enableCheckPriority()
          getTotalBoxesCurrent()
        }
      }
    )

    watch(
      () => customerCheckIds.value,
      () => {
        // check show proposal
        isShowProposalText.value = customerCheckIds.value.length === 0
      }
    )

    return {
      loading,
      benched,
      onCheckCustomer,
      customers,
      customersCheck,
      searchedCustomers,
      searchInfo,
      search,
      boxes,
      calculator,
      showNumBerDialog,
      save,
      isSelectAll,
      onSelectAll,
      updateBoxes,
      customerCheckIds,
      totalBoxCurrent,
      isShowProposalText,
      isConfirmShow,
      handleClickOk,
      boxesAssignThisAuctionDate
    }
  }
})

export default AssignToMultipleCustomersDialog
