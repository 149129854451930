<template lang="pug">
  v-dialog(
    ref="dialog"
    persistent
    width="90vw"
    max-width="500px"
    :return-value.sync="dateData"
    v-model="isOpenDatePicker"
  )
    template(v-slot:activator="{ on, attrs }")
      v-text-field(
        :label="label || $t('common.auction_date')"
        readonly
        hide-details
        :outlined="outlined"
        :dense="dense"
        :disabled="disabled"
        :style="maxWidth ? `max-width: ${maxWidth}px` : ''"
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        :value="dateFormat"
        @click:append="isOpenDatePicker = true"
        color="#343f4b"
        :clearable="clearable"
        @click:clear="dateData = null, $refs.dialog.save(dateData), savaDate()"
      )
    v-date-picker(
      full-width
      scrollable
      locale="ja-jp"
      color="#343f4b"
      header-color="#343f4b"
      v-model="dateData"
      :min="minDate"
      :max="maxDate"
      @change="$refs.dialog.save(dateData), savaDate()"
    )
      v-spacer
      v-btn.white--text(dark text color="red" @click="isOpenDatePicker = false")
        span {{$t('common.cancel')}}
</template>

<script>
import moment from 'moment'
import { defineComponent, ref, computed, getCurrentInstance, watch } from '@vue/composition-api'

const DateDialog = defineComponent({
  props: {
    date: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    outlined: { default: false },
    dense: { default: false },
    maxWidth: { default: null },
    formatDate: { default: 'time_month_date' },
    minDate: { default: '' },
    maxDate: { default: '' },
    disabled: { default: false }
  },
  setup(props, { emit }) {
    const { $root } = getCurrentInstance().proxy
    const isOpenDatePicker = ref(false)
    const dateData = ref(props.date)
    const dateFormat = computed(() => {
      return moment(dateData.value).format($root.$t(`common.${props.formatDate}`))
      // return dateMoment._isValid ? dateMoment.format($root.$t(`common.${props.formatDate}`)) : ''
    })

    const savaDate = () => {
      emit('save-date', dateData.value)
    }

    watch(
      () => props.date,
      () => {
        dateData.value = props.date
      }
    )

    return {
      isOpenDatePicker,
      savaDate,
      dateData,
      dateFormat
    }
  }
})

export default DateDialog
</script>
