export const listBatchAssignStates = [
  'auctionDate',
  'orderType',
  'item',
  'variety',
  'colors',
  'quality',
  'size',
  'quantity',
  'unit',
  'boxType'
]

export const listAssignStates = [
  'auctionDate',
  'orderType',
  'customer',
  'buyerInfo',
  'bundleNo',
  'item',
  'variety',
  'colors',
  'quality',
  'size',
  'quantity',
  'unit',
  'boxes',
  'boxType',
  'stems',
  'price',
  'amount'
]

export const initAssign = {
  auctionDate: null,
  buyerInfo: null,
  bundleNo: null,
  customer: null,
  item: null,
  variety: null,
  size: null,
  quality: null,
  unit: null,
  orderType: null,
  boxType: null,
  colors: [],
  quantity: null,
  boxes: null,
  stems: null,
  price: null,
  amount: null,
  remark: null,
  orderDetailId: null,
  packingResultId: null,
  harvestResultId: null,
  breakdowns: []
}
