




import { defineComponent } from '@vue/composition-api'

const SectionHeader = defineComponent({
  props: {
    title: {
      type: String,
      required: true
    }
  }
})
export default SectionHeader
