import { CreateElement, VNode } from 'vue'
import { defineComponent } from '@vue/composition-api'
import {
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VDivider,
  VExpansionPanelContent,
  VListItem,
  VListItemContent
} from 'vuetify/lib/components'

import { MultiLevelProduct, MultiLevelVariety, MultiLevelQualitySize } from 'typings'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const MultiLevelListJsx = defineComponent({
  name: 'MultiLevelListJsx',
  props: {
    data: {
      type: Array,
      required: true
    },
    levels: {
      type: Array,
      required: false,
      default: () => ['itemName', 'varietyName', 'qualitySizeName']
    },
    multiDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    treeviewLevel: {
      type: Number,
      required: false,
      default: 4
    }
  },
  components: {
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VDivider,
    VExpansionPanelContent,
    VListItem,
    VListItemContent
  },
  methods: {
    getStyle(isLast: boolean) {
      return isLast ? { 'padding-left': '24px !important' } : {}
    },
    handleChangeRootPanel(rootPanel: Array<number>) {
      const data = { ...this.value, panels: rootPanel }
      this.$emit('input', data)
    },
    handleChangePanelLevelPanel(key: string, panel: Array<number>) {
      const data = { ...this.value, [key]: panel }
      this.$emit('input', data)
    },
    createMultiLevelList(h: CreateElement): VNode {
      const { levels, multiDetail, value } = this

      return h('div', [
        h(
          'v-expansion-panels',
          {
            props: {
              flat: true,
              accordion: true,
              multiple: true,
              value: value.panels
            },
            on: {
              change: (data: Array<number>) => this.handleChangeRootPanel(data)
            }
          },
          (this.data as Array<MultiLevelProduct>).map((root, rootIndex) => {
            return h(
              'v-expansion-panel',
              {
                props: {
                  multiple: true,
                  key: root[levels[0] as keyof MultiLevelProduct]
                }
              },
              [
                h(
                  'v-expansion-panel-header',
                  {
                    class: {
                      'pl-0': true,
                      'pr-1': true,
                      'py-1': true
                    }
                  },
                  [
                    this.$scopedSlots.rootContent
                      ? this.$scopedSlots.rootContent({ data: root, rootIndex })
                      : h('span', root[levels[0] as keyof MultiLevelProduct] as string)
                  ]
                ),
                h('v-divider'),
                h(
                  'v-expansion-panel-content',
                  {
                    style: {
                      'border-width': this.treeviewLevel === 2 ? '0 0 0 3px' : '0 0 0 10px',
                      'border-style': 'solid',
                      'border-color': this.treeviewLevel === 2 ? '#6ec6ff' : '#ffffff'
                    }
                  },
                  [
                    value.panels && value.panels.includes(rootIndex)
                      ? h(
                          'v-expansion-panels',
                          {
                            props: {
                              flat: true,
                              accordion: true,
                              multiple: true,
                              value: value[root[levels[0] as keyof MultiLevelProduct] as string]
                            },
                            on: {
                              change: (data: Array<number>) =>
                                this.handleChangePanelLevelPanel(
                                  root[levels[0] as keyof MultiLevelProduct] as string,
                                  data
                                )
                            }
                          },
                          root.children.map((firstLevel, firstIndex) => {
                            return h(
                              'v-expansion-panel',
                              {
                                props: {
                                  multiple: true,
                                  key: `${
                                    firstLevel[levels[1] as keyof MultiLevelVariety]
                                  }-${firstIndex}`
                                }
                              },
                              [
                                h(
                                  'v-expansion-panel-header',
                                  {
                                    props: {
                                      hideActions: this.treeviewLevel === 2
                                    },
                                    class: {
                                      'pl-0': true,
                                      'pr-1': true,
                                      'py-1': true
                                    },
                                    style: {
                                      'background-color': 'rgb(102 171 242 / 10%)'
                                    }
                                  },
                                  [
                                    this.$scopedSlots.firstContent
                                      ? this.$scopedSlots.firstContent({
                                          rootIndex,
                                          firstIndex,
                                          data: firstLevel
                                        })
                                      : h(
                                          'span',
                                          firstLevel[levels[1] as keyof MultiLevelVariety] as string
                                        )
                                  ]
                                ),
                                h('v-divider'),
                                h(
                                  'v-expansion-panel-content',
                                  {
                                    style: {
                                      'border-width': '0 0 0 10px',
                                      'border-style': 'solid',
                                      'border-color': 'rgb(102 171 242 / 10%)'
                                    }
                                  },
                                  [
                                    value[root[levels[0] as keyof MultiLevelProduct] as string] &&
                                    value[
                                      root[levels[0] as keyof MultiLevelProduct] as string
                                    ].includes(firstIndex)
                                      ? h(
                                          'v-expansion-panels',
                                          {
                                            props: {
                                              flat: true,
                                              accordion: true,
                                              multiple: true,
                                              value:
                                                value[
                                                  `${root[levels[0] as keyof MultiLevelProduct]}-${
                                                    firstLevel[levels[1] as keyof MultiLevelVariety]
                                                  }`
                                                ]
                                            },
                                            on: {
                                              change: (data: Array<number>) =>
                                                this.handleChangePanelLevelPanel(
                                                  `${root[levels[0] as keyof MultiLevelProduct]}-${
                                                    firstLevel[levels[1] as keyof MultiLevelVariety]
                                                  }`,
                                                  data
                                                )
                                            }
                                          },
                                          firstLevel.children?.map((secondLevel, secondIndex) => {
                                            return h(
                                              'v-expansion-panel',
                                              {
                                                props: {
                                                  multiple: true,
                                                  key: secondLevel[
                                                    levels[2] as keyof MultiLevelQualitySize
                                                  ]
                                                }
                                              },
                                              [
                                                h(
                                                  'v-expansion-panel-header',
                                                  {
                                                    props: {
                                                      hideActions: !multiDetail
                                                    },
                                                    style: {
                                                      ...this.getStyle(!multiDetail),
                                                      'background-color':
                                                        'rgb(102 171 242 / 30%) !important'
                                                    },
                                                    class: {
                                                      'pl-0': true,
                                                      'pr-1': true,
                                                      'py-1': true
                                                    }
                                                  },
                                                  [
                                                    this.$scopedSlots.secondContent
                                                      ? this.$scopedSlots.secondContent({
                                                          rootIndex,
                                                          firstIndex,
                                                          secondIndex,
                                                          data: secondLevel
                                                        })
                                                      : h(
                                                          'span',
                                                          secondLevel[
                                                            levels[2] as keyof MultiLevelQualitySize
                                                          ]
                                                        )
                                                  ]
                                                ),
                                                h('v-divider'),
                                                !multiDetail
                                                  ? undefined
                                                  : h(
                                                      'v-expansion-panel-content',
                                                      secondLevel.children?.map((detail) => {
                                                        return (
                                                          h('div'),
                                                          {
                                                            style: {
                                                              'margin-left': '10px',
                                                              'background-color':
                                                                'rgb(102 171 242 / 30%) !important',
                                                              'border-bottom':
                                                                '1px solid rgba(148, 148, 148, 0.72)'
                                                            }
                                                          },
                                                          [
                                                            this.$scopedSlots.detailContent
                                                              ? this.$scopedSlots.detailContent({
                                                                  data: detail
                                                                })
                                                              : h('v-list-item', [
                                                                  h('v-list-item-content', [
                                                                    h('span', detail.id)
                                                                  ])
                                                                ])
                                                          ]
                                                        )
                                                      })
                                                    )
                                              ]
                                            )
                                          })
                                        )
                                      : undefined
                                  ]
                                )
                              ]
                            )
                          })
                        )
                      : undefined
                  ]
                )
              ]
            )
          })
        )
      ])
    }
  },
  render(h) {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return (this as any).createMultiLevelList(h)
  }
})

export default MultiLevelListJsx
