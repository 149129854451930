export const initPackingImportDetail = {
  auctionDate: '',
  item: null,
  variety: null,
  size: null,
  boxType: null,
  unit: null,
  quality: null,
  quantity: null,
  boxes: null,
  stems: null,
  remark: null
}
