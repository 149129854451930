







import { defineComponent } from '@vue/composition-api'

const BottomContainer = defineComponent({
  props: {
    bottom: {
      type: [String, Number],
      required: false,
      default: 0
    }
  }
})

export default BottomContainer
