



















































































































import { defineComponent, ref, computed, watch } from '@vue/composition-api'
import { endpoints, showError, toCamelCase, toSnakeCase, constants } from 'utils'
import { api } from 'plugins'
import { Assign } from 'typings'
import ChooseNumber from '../ChooseNumber/index.vue'
import ConfirmDialog from '../ConfirmDialog/index.vue'
import LoaderComponent from '../LoaderComponent/index.vue'
import CommentDialog from '../CommentDialog/index.vue'

const PreviewInvoiceDialog = defineComponent({
  components: {
    ChooseNumber,
    ConfirmDialog,
    LoaderComponent,
    CommentDialog
  },
  props: {
    dialogValue: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    },
    showBigComment: {
      type: Boolean,
      required: false,
      default: false
    },
    remark: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { root, emit }) {
    const currentState = ref(1)
    const filterUnConfirmed = ref(false)
    const invoicesData = ref([] as any)
    const isShowChooseNumber = ref(false)
    const selectedAssignment = ref(1)
    const showEditRemark = ref(false)
    const remarkValue = ref('')
    const showEditMemo = ref(false)
    const memoValue = ref('')
    const loading = ref(false)
    const orderByList = [
      {
        name: root.$t('invoice.preview_invoice.order_by_name') as string,
        key: constants.orderByKey.PRODUCT_NAME
      },
      {
        name: root.$t('invoice.preview_invoice.order_by_order_type') as string,
        key: constants.orderByKey.ORDER_TYPE
      }
    ]
    const orderByValue = ref(constants.orderByKey.PRODUCT_NAME)
    const bundleNoDialogValue = computed(
      () =>
        invoicesData.value.assignments.find((e: Assign) => e.id === selectedAssignment.value)
          ?.bundleNo || ''
    )
    const { $toast } = root

    const getData = async () => {
      remarkValue.value = props.remark
      loading.value = true
      try {
        const { data } = await api.get(
          `${endpoints.INVOICES}detail?auction_date=${props.auctionDate}&customer_id=${props.customerId}&order_by=${orderByValue.value}`
        )
        // need to convert result from snake_case to camelCase
        invoicesData.value = toCamelCase(data)
        remarkValue.value = invoicesData.value.remark
      } catch (e: any) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loading.value = false
      }
    }
    const onClose = () => {
      emit('on-close')
      emit('reload')
      currentState.value = 1
    }
    const onClickRightChevron = () => {
      if (currentState.value < 4) currentState.value += 1
    }
    const onClickLeftChevron = () => {
      if (currentState.value > 1) currentState.value -= 1
    }
    const updateBundleNo = (action: string, value: string) => {
      const index = invoicesData.value.assignments.findIndex(
        (e: Assign) => e.id === selectedAssignment.value
      )
      if (index !== -1 && action === 'ok') {
        invoicesData.value.assignments[index].bundleNo = value !== '' ? value : null
      }
      isShowChooseNumber.value = false
    }

    const saveConfirm = async (assign: any) => {
      const body = {
        id: assign.id,
        isConfirmed: !assign.isConfirmed
      }
      try {
        const { data } = await api.put(`${endpoints.ASSIGNMENTS}confirm`, toSnakeCase(body))
        const index = invoicesData.value.assignments.findIndex((asm: any) => asm.id === assign.id)
        invoicesData.value.assignments[index].isConfirmed = toCamelCase(data).isConfirmed
        // emit('update-is-confirmed', toCamelCase(data))
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
    }

    const openEditMemo = (assignment: any) => {
      showEditMemo.value = true
      selectedAssignment.value = assignment.id
      if (assignment.memo) {
        memoValue.value = assignment.memo
      } else {
        memoValue.value = ''
      }
    }

    const updateMemo = async (value: string) => {
      showEditMemo.value = false
      const body = {
        id: selectedAssignment.value,
        memo: value?.trim()
      }
      try {
        const { data } = await api.put(`${endpoints.ASSIGNMENTS}update_memo`, body)
        const index = invoicesData.value.assignments.findIndex(
          (asm: any) => asm.id === selectedAssignment.value
        )
        invoicesData.value.assignments[index].memo = toCamelCase(data).memo
        $toast.success(root.$t('assign.update_memo_success'))
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
    }

    const updateRemark = (value: string) => {
      showEditRemark.value = false
      remarkValue.value = value
      emit('update-remark', remarkValue.value)
    }
    watch(
      () => props.dialogValue,
      () => {
        if (props.dialogValue) {
          getData()
        }
      }
    )
    return {
      onClose,
      onClickLeftChevron,
      onClickRightChevron,
      currentState,
      invoicesData,
      isShowChooseNumber,
      selectedAssignment,
      bundleNoDialogValue,
      updateBundleNo,
      updateRemark,
      showEditRemark,
      remarkValue,
      loading,
      saveConfirm,
      filterUnConfirmed,
      orderByList,
      orderByValue,
      getData,
      updateMemo,
      memoValue,
      showEditMemo,
      openEditMemo
    }
  }
})

export default PreviewInvoiceDialog
