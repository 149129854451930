



























import { defineComponent, ref, toRefs, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'

import { endpoints, showError, toSnakeCase, toCamelCase } from 'utils'
import { api } from 'plugins'
import { DialogContainer } from 'components'

const BoxTypeDialog = defineComponent({
  props: {
    isAddBoxType: Boolean,
    show: {
      type: Boolean,
      required: true
    },
    boxType: {
      type: Object,
      required: true,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    DialogContainer
  },
  setup(props, { emit, root, refs }) {
    const isUpdateSuccessful = ref(false)
    const isAddNewSuccessful = ref(false)
    const { $toast, $store } = root
    const { boxType } = toRefs(props)
    const instance = getCurrentInstance()
    const addBoxTypeAPI = async () => {
      const dataAdd = ref({
        name: '',
        shortName: '',
        code: '',
        yomi: '',
        nameEng: '',
        bundleSize: '',
        boxTypePrice: ''
      })
      dataAdd.value.boxTypePrice = props.boxType.boxTypePrice?.trim()
      dataAdd.value.bundleSize = props.boxType.bundleSize?.trim()
      dataAdd.value.name = props.boxType.name?.trim()
      dataAdd.value.shortName = props.boxType.shortName?.trim()
      dataAdd.value.code = props.boxType.code?.trim()
      dataAdd.value.nameEng = props.boxType.nameEng?.trim()
      dataAdd.value.yomi = props.boxType.yomi?.trim()
      try {
        const { data } = await api.post(endpoints.BOX_TYPE, toSnakeCase(dataAdd.value))
        if (data === undefined) {
          isAddNewSuccessful.value = false
        } else {
          isAddNewSuccessful.value = true
          $store.commit('setNewDataMaster', toCamelCase(data))
        }
      } catch {
        isAddNewSuccessful.value = false
      }
    }
    const updateBoxTypeAPI = async (id: number) => {
      const dataUpdate = ref({
        name: '',
        shortName: '',
        code: '',
        yomi: '',
        nameEng: '',
        bundleSize: '',
        boxTypePrice: ''
      })
      dataUpdate.value.boxTypePrice = props.boxType.boxTypePrice
      dataUpdate.value.bundleSize = props.boxType.bundleSize
      dataUpdate.value.name = props.boxType.name.trim()
      dataUpdate.value.code = props.boxType.code ? props.boxType.code : null
      dataUpdate.value.shortName = props.boxType.shortName ? props.boxType.shortName.trim() : null
      dataUpdate.value.nameEng = props.boxType.nameEng ? props.boxType.nameEng.trim() : null
      dataUpdate.value.yomi = props.boxType.yomi.trim()

      try {
        const { data } = await api.put(`${endpoints.BOX_TYPE}${id}`, toSnakeCase(dataUpdate.value))
        if (data === undefined) {
          isUpdateSuccessful.value = false
        } else {
          $toast.success(root.$t('master.msg.update_successful'))
          isUpdateSuccessful.value = true
        }
      } catch (e) {
        isUpdateSuccessful.value = false
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
    }
    const resetValidate = () => {
      const validateNameReset = refs.name as InstanceType<typeof ValidationProvider>
      const validateBundleSizeReset = refs.bundleSize as InstanceType<typeof ValidationProvider>
      validateNameReset.reset()
      validateBundleSizeReset.reset()
    }
    const closeDialog = () => {
      emit('close-dialog')
      resetValidate()
    }
    const addBoxType = async () => {
      const validateName = await (refs.name as InstanceType<typeof ValidationProvider>).validate()
      if (!validateName.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }
      // const validateBundleSize = await (
      //   refs.bundleSize as InstanceType<typeof ValidationProvider>
      // ).validate()
      // if (!validateBundleSize.valid) {
      //   $toast.error(root.$t('master.msg.please_enter_bundle_size'))
      //   return
      // }
      await addBoxTypeAPI()
      if (isAddNewSuccessful.value === true) {
        emit('re-load')
        emit('clear-props-box-type')
        resetValidate()
        closeDialog()
        $toast.success(root.$t('master.msg.create_successful'))
      } else {
        $toast.error(root.$t('master.msg.name_already_exists'))
      }
    }
    const updateBoxType = async () => {
      const validateName = await (refs.name as InstanceType<typeof ValidationProvider>).validate()
      if (!validateName.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }
      // const validateBundleSize = await (
      //   refs.bundleSize as InstanceType<typeof ValidationProvider>
      // ).validate()
      // if (!validateBundleSize.valid) {
      //   $toast.error(root.$t('master.msg.please_enter_bundle_size'))
      //   return
      // }

      await updateBoxTypeAPI(props.boxType.id)
      if (isUpdateSuccessful.value === true) {
        emit('re-load')
        resetValidate()
        closeDialog()
      }
    }

    const deleteBoxAPI = async (id: number) => {
      try {
        const { data } = await api.delete(`${endpoints.BOX_TYPE}${id}`)
        if (data !== undefined) {
          $toast.success(root.$t('master.msg.delete_successful'))
        }
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      }
    }
    const confirmDelete = async () => {
      await deleteBoxAPI(props.boxType.id)
      emit('re-load')
      closeDialog()
    }

    const getYomiAPI = async () => {
      try {
        const { data } = await api.get(`${endpoints.TO_YOMI}?text=${props.boxType.name}`)
        return data
      } catch (e) {
        return ''
      }
    }
    const inputNameWhenAddNew = debounce(async () => {
      const data = await getYomiAPI()
      boxType.value.yomi = data
      instance?.proxy?.$forceUpdate()
      emit('handle-insert-yomi-into-box-type-props', data)
    }, 300)

    return {
      closeDialog,
      addBoxType,
      updateBoxType,
      confirmDelete,
      inputNameWhenAddNew
    }
  }
})

export default BoxTypeDialog
