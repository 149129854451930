export const common = {
  state: {
    newDataMaster: {},
    allVarietyByItem: [],
    historyPath: [],
    latestSelectedDates: {},
    settingMaster: []
  },
  mutations: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setHistoryPath(state: any, value: Array<string>): void {
      state.historyPath = value
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setNewDataMaster(state: any, value: any): void {
      state.newDataMaster = value
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setAllVarietyByItem(state: any, value: any): void {
      state.allVarietyByItem = value
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setLatestSelectedDates(state: any, value: any): void {
      state.latestSelectedDates = value
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setSettingMaster(state: any, value: any): void {
      state.settingMaster = value
    }
  },
  actions: {}
}
