























import { defineComponent } from '@vue/composition-api'
import { ObjectWrapper } from '../../typings'

const ListMember = defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const clickItem = (item: ObjectWrapper): void => {
      emit('click-item', item)
    }
    return {
      clickItem
    }
  }
})

export default ListMember
