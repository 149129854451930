import { ref } from '@vue/composition-api'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getSortStates = (
  listStateDefault: Array<string>,
  listStateStore: Array<string>,
  listStateSetting: Array<string>
) => {
  const result = ref<Array<string>>([])
  if (listStateStore.length !== 0) {
    // eslint-disable-next-line array-callback-return
    listStateDefault.map((state: string) => {
      listStateStore.forEach((stateStore: string) => {
        if (stateStore.indexOf(state) !== -1) {
          result.value.push(state)
        }
      })
    })
  } else {
    return listStateSetting.length === 0 ? listStateDefault : listStateSetting
  }
  return result.value
}
