




































































import { defineComponent, toRefs, Ref, ref, watch, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'

import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, createOrDeleteImage, toCamelCase } from 'utils'
import {
  IVariety,
  Item,
  Image,
  SizeGroup,
  QualityGroup,
  VarietiesByItem,
  NurseryCompany
} from 'typings'

import DialogContainer from '../DialogContainer/index.vue'
import ImageList from '../ImageList/index.vue'

interface SetUp {
  toYomi: () => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  closeDialog: () => void
  createVariety: () => void
  updateVariety: () => void
  confirmDelete: (param: string) => void
  removeImage: (id: number) => void
  refreshVariety: () => void
  searchItem: (item: Item, queryText: string, itemText: string) => boolean
  searchSizeGroup: (group: SizeGroup, queryText: string, groupText: string) => boolean
  searchQualityGroup: (group: QualityGroup, queryText: string, groupText: string) => boolean
  searchNurseryCompany: (comapny: NurseryCompany, queryText: string, groupText: string) => boolean
  updateVarietyByItemChoice: () => void
}

const VarietyDialog = defineComponent({
  components: {
    DialogContainer,
    ImageList
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    variety: {
      type: Object,
      required: true,
      default: null
    },
    itemList: {
      type: Array,
      required: true,
      default: () => []
    },
    isAddVariety: {
      type: Boolean,
      required: true,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    sizeGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    qualityGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    nurseryCompanyItems: {
      type: Array,
      required: true,
      default: () => []
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { variety, itemList } = toRefs(props)
    const instance = getCurrentInstance()
    const { $toast, $store } = root

    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${variety.value.name}`)
        variety.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    const listImageRemove = ref<Array<number>>([])
    const loading = ref<boolean>(false)
    const deleteLoading = ref<boolean>(false)

    const searchSizeGroup = (group: SizeGroup, queryText: string, groupText: string): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return groupText.trim().toLowerCase().indexOf(searchText) > -1
    }

    const searchQualityGroup = (
      group: QualityGroup,
      queryText: string,
      groupText: string
    ): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return groupText.trim().toLowerCase().indexOf(searchText) > -1
    }

    const searchNurseryCompany = (
      company: NurseryCompany,
      queryText: string,
      groupText: string
    ): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return groupText.trim().toLowerCase().indexOf(searchText) > -1
    }

    const refreshVariety = (): void => {
      // keep current item (not reset to the first item)
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      variety.value.images = []
      variety.value.name = ''
      variety.value.shortName = ''
      variety.value.yomi = ''
      variety.value.nameEng = ''
      variety.value.code = ''
      validateName.reset()
    }

    const closeDialog = (): void => {
      listImageRemove.value = []
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      validateName.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): IVariety => {
      const body: IVariety = {
        name: variety.value.name.trim(),
        item: variety.value.item.id ? variety.value.item.id : variety.value.item
      }
      if (variety.value.code) {
        body.code = variety.value.code
      }
      if (variety.value.yomi) {
        body.yomi = variety.value.yomi.trim()
      }
      if (variety.value.shortName) {
        body.shortName = variety.value.shortName.trim()
      }
      if (variety.value.nameEng) {
        body.nameEng = variety.value.nameEng.trim()
      }
      if (variety.value.royalty) {
        body.royalty = variety.value.royalty
      }
      if (variety.value.sizeGroup) {
        body.sizeGroupId = variety.value.sizeGroup.id
          ? variety.value.sizeGroup.id
          : variety.value.sizeGroup
      }
      if (variety.value.qualityGroup) {
        body.qualityGroupId = variety.value.qualityGroup.id
          ? variety.value.qualityGroup.id
          : variety.value.qualityGroup
      }
      if (variety.value.nurseryCompany) {
        body.nurseryCompanyId = variety.value.nurseryCompany.id
          ? variety.value.nurseryCompany.id
          : variety.value.nurseryCompany
      }
      return body
    }

    const uploadImage = async (uuidKey: string): Promise<void> => {
      if (variety.value?.images) {
        const newImages = variety.value.images.filter((image: Image) => !image.id)
        await createOrDeleteImage(uuidKey, newImages, listImageRemove.value)
        listImageRemove.value = []
      }
    }

    const removeImage = (id: number) => {
      listImageRemove.value = [...listImageRemove.value, id]
    }

    const createVariety = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }
      const body = toSnakeCase(generateBody())
      loading.value = true
      try {
        const { data } = await api.post(endpoints.VARIETIES, body)
        await uploadImage(data.uuid)
        $store.commit('setNewDataMaster', toCamelCase(data))
        $toast.success(root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      }
      loading.value = false
    }

    const updateVariety = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }
      const body = toSnakeCase(generateBody())
      loading.value = true
      try {
        await api.put(`${endpoints.VARIETIES}${variety.value.id}`, body)
        await uploadImage(variety.value.uuid)

        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      }
      loading.value = false
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.VARIETIES}${props.variety.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const searchItem = (item: Item, queryText: string, itemText: string): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return itemText.trim().toLowerCase().indexOf(searchText) > -1
    }

    // auto update variety's sizeGroup and qualityGroup
    const updateVarietyByItemChoice = (): void => {
      const chosenItem: VarietiesByItem = (itemList.value as Array<VarietiesByItem>).find(
        (item) => item.id === variety.value.item
      ) as VarietiesByItem
      variety.value.sizeGroup = chosenItem?.sizeGroup?.id || 1
      variety.value.qualityGroup = chosenItem?.qualityGroup?.id || 1
    }

    watch(variety, () => {
      // set default item
      if (!variety.value.item) {
        variety.value.item =
          (itemList.value as Array<VarietiesByItem>).length > 0
            ? (itemList.value as Array<VarietiesByItem>)[0].id
            : null
      }
    })

    return {
      toYomi,
      loading,
      deleteLoading,
      closeDialog,
      createVariety,
      updateVariety,
      confirmDelete,
      refreshVariety,
      removeImage,
      searchItem,
      searchSizeGroup,
      searchQualityGroup,
      updateVarietyByItemChoice,
      searchNurseryCompany
    }
  }
})

export default VarietyDialog
