

















import { defineComponent, watch } from '@vue/composition-api'

interface SetUp {
  cancel: () => void
  ok: () => void
}

const ConfirmDialog = defineComponent({
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    message_2: {
      type: String,
      required: false
    },
    okText: {
      type: String,
      required: true
    },
    cancelText: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const cancel = () => {
      emit('on-cancel')
    }

    const ok = () => {
      emit('on-ok')
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 89) {
        // Press Y
        ok()
      } else if (event.keyCode === 78) {
        // Press N
        cancel()
      }
    }

    watch(
      () => props.showDialog,
      () => {
        if (props.showDialog) {
          document.addEventListener('keydown', onTyping)
        } else {
          document.removeEventListener('keydown', onTyping)
        }
      }
    )

    return {
      cancel,
      ok
    }
  }
})
export default ConfirmDialog
