import GraphemeSplitter from 'grapheme-splitter'
import arrayCharacterFullWidth from './fullwidth'
import arrayCharacterHalfWidth from './halfwidth'

const splitter = new GraphemeSplitter()

const getCharacters = (string: string) => {
  return splitter.splitGraphemes(string)
}

const convertCharacter = (character: string, mode: string): string => {
  let arrayCharacter
  if (mode === 'full') {
    arrayCharacter = arrayCharacterFullWidth
    const key = character as keyof typeof arrayCharacterFullWidth
    if (typeof arrayCharacter[key] === 'undefined') {
      return character
    }
    return arrayCharacter[key]
  }

  if (mode === 'half') {
    arrayCharacter = arrayCharacterHalfWidth
    const key = character as keyof typeof arrayCharacterHalfWidth
    if (typeof arrayCharacter[key] === 'undefined') {
      return character
    }
    return arrayCharacter[key]
  }

  return character
}

export const convertToWidthCharacter = (string: string, mode: string): string => {
  const characters = getCharacters(string)
  let convertString = ''
  characters.forEach((character: string) => {
    convertString += convertCharacter(character, mode)
  })

  return convertString.replace('　', ' ')
}
