export const buildQuery = (params: Record<string, unknown>): unknown => {
  // eslint-disable-next-line no-param-reassign
  params = params || {}
  return Object.keys(params)
    .map((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`
    })
    .join('&')
}
