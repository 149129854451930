








































import { defineComponent, ref, watch } from '@vue/composition-api'
import Vue from 'vue'

const CommentDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    remark: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit, refs }) {
    const textField: any = ref(null)
    const changedRemark = ref('')
    const updateRemark = () => {
      emit('on-ok', changedRemark.value)
    }
    const onFocus = () => {
      const bodyText = (refs.textField as Vue).$el.querySelector('input')
      bodyText?.setSelectionRange(changedRemark.value.length, changedRemark.value.length)
    }
    watch(
      () => props.remark,
      () => {
        changedRemark.value = props.remark
      }
    )

    return {
      changedRemark,
      updateRemark,
      onFocus
    }
  }
})

export default CommentDialog
