export const initHarvestResult = {
  harvestDate: null,
  item: null,
  variety: null,
  size: null,
  quality: null,
  unit: null,
  color: null,
  stems: null,
  desiredPrice: null,
  quantity: null,
  boxes: null
}

export const listHarvestStates = [
  'harvestDate',
  'item',
  'variety',
  'color',
  'quality',
  'size',
  'quantity',
  'unit',
  'boxes',
  'stems',
  'desiredPrice'
]
