





































import { defineComponent, ref, Ref, watch } from '@vue/composition-api'
import { Item, VarietiesByItem } from 'typings'

type Combination = VarietiesByItem

interface SetUp {
  isSortAll: Ref<boolean>
  chosenItem: Ref<Combination>
  close: () => void
  chooseItem: () => void
  searchItem: (item: Item, queryText: string, itemText: string) => boolean
}

const SortDialog = defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true
    },
    isSortByName: {
      type: Boolean,
      default: false
    },
    groupItems: {
      type: Array,
      required: true
    },
    itemDefault: {
      type: Object,
      required: false,
      defaults: null
    }
  },
  setup(props, { emit }): SetUp {
    const chosenItem: Ref<Combination> = ref(props.itemDefault as Combination)
    const isSortAll = ref(true)
    const close = (): void => {
      emit('on-close')
    }

    watch(props, () => {
      chosenItem.value = props.itemDefault as Combination
    })

    const chooseItem = (): void => {
      if (props.isSortByName) {
        if (isSortAll.value) {
          emit('sort-item-by-name')
        } else {
          emit('sort-item-by-name', chosenItem?.value?.varieties)
        }
      } else {
        emit('on-drag-drop-items', chosenItem?.value.id)
      }
      close()
    }

    const searchItem = (item: Item, queryText: string, itemText: string): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return itemText.trim().toLowerCase().indexOf(searchText) > -1
    }

    return { isSortAll, chosenItem, close, chooseItem, searchItem }
  }
})

export default SortDialog
