

































































import { defineComponent, Ref, ref, computed, onMounted } from '@vue/composition-api'
import {
  CommonProperty,
  BreakdownState,
  Size,
  Variety,
  Quality,
  Item,
  QualityGroup,
  SizeGroup,
  VarietiesByItem,
  SizesBySizeGroup,
  QualitiesByQualityGroup,
  NurseryCompany
} from 'typings'
import { defaultObject, endpoints, showError, toCamelCase, toSnakeCase } from 'utils'

import { api } from 'plugins'
import CreateCommonForm from '../CreateCommonForm/index.vue'
import ChooseColorSingle from '../ChooseColorSingle/index.vue'
import ChooseNumber from '../ChooseNumber/index.vue'
import ChooseProperty from '../ChooseProperty/index.vue'
import SingleDetailItem from '../SingleDetailItem/index.vue'

interface SetUp {
  numberDialog: Ref<boolean>
  colorDialog: Ref<boolean>
  dialog: Ref<boolean>
  setPropertyName: Ref<string>
  chooseProperty: (property: string) => void
  choosePropertyItems: Ref<Array<CommonProperty>>
  selectProperty: (action: string) => void
  update: (action: string, value: Ref<CommonProperty> | number) => void
  remove: () => void
  getPropertyDialogTitle: () => string
  getNumberDialogTitle: () => string
  groupDataMaster: Ref<Array<any>>
  getNewData: (mode: string) => void
  sizeGroups: Ref<SizeGroup[]>
  qualityGroups: Ref<QualityGroup[]>
  nurseryCompanies: Ref<NurseryCompany[]>
  itemSelectVariety: Ref<number>
}

const BreakdownContainer = defineComponent({
  components: {
    ChooseColorSingle,
    ChooseNumber,
    ChooseProperty,
    CreateCommonForm,
    SingleDetailItem
  },
  props: {
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    index: {
      type: Number,
      required: true
    },
    breakdown: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    varieties: {
      type: Array,
      required: true
    },
    sizes: {
      type: Array,
      required: true
    },
    qualities: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    inputtingData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    page: {
      type: String,
      required: true
    }
  },
  setup(props, { root, emit }): SetUp {
    const { $store, $toast } = root
    const numberDialog = ref(false)
    const colorDialog = ref(false)
    const dialog = ref(false)
    const setPropertyName = ref('')
    const choosePropertyItems = ref<Array<CommonProperty>>([])
    const { breakdown, index, items, varieties, sizes, qualities } = props
    const itemSelectVariety = ref(breakdown.item ? breakdown.item.id : 1)
    const groupDataMaster = ref<any[]>([])
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])

    const getVarietyByItem = async () => {
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const getData = async () => {
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(endpoints.NURSERY_COMPANY)
        ])

        const [
          { data: qualitiesByQualityGroupData },
          { data: sizesBySizeGroupData },
          { data: nurseryCompaniesData }
        ] = data

        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)

        sizeGroups.value = sizesBySizeGroup.value.map((sizeGroup: SizesBySizeGroup) => {
          return { ...sizeGroup }
        })

        qualityGroups.value = qualitiesByQualityGroup.value.map(
          (qualityByQuality: QualitiesByQualityGroup) => {
            return { ...qualityByQuality }
          }
        )
        nurseryCompanies.value = toCamelCase(nurseryCompaniesData)
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const nextStates: BreakdownState = defaultObject.breakdownDetailNextState
    const prevStates: BreakdownState = defaultObject.breakdownDetailPrevState
    const nextProperty = computed(() => {
      const key = setPropertyName.value
      return nextStates[key as keyof BreakdownState]
    })
    const prevProperty = computed(() => {
      const key = setPropertyName.value
      return prevStates[key as keyof BreakdownState]
    })

    const varietyByItem = computed(() => {
      const itemId = breakdown.item?.id
      if (!itemId) return []
      return (varieties as Array<Variety>).filter((variety: Variety) => {
        return variety.item.id === itemId
      })
    })

    const sizeByProduct = computed(() => {
      const itemSizeGroupId = breakdown.item?.sizeGroup.id
      const varietySizeGroupId = breakdown.variety?.sizeGroup.id

      if (varietySizeGroupId) {
        return (sizes as Array<Size>).filter((size: Size) => {
          return size.sizeGroup.id === varietySizeGroupId
        })
      }
      if (itemSizeGroupId) {
        return (sizes as Array<Size>).filter((size: Size) => {
          return size.sizeGroup.id === itemSizeGroupId
        })
      }
      return []
    })

    const chooseProperty = (property: string): void => {
      setPropertyName.value = property
      dialog.value = false
      colorDialog.value = false
      numberDialog.value = false

      switch (property) {
        case 'item':
          choosePropertyItems.value = items as Array<Item>
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyByItem.value as Array<Variety>
          groupDataMaster.value = varietiesByItem.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizeByProduct.value as Array<Size>
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'color':
          colorDialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualities as Array<Quality>
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        default:
          break
      }
    }
    const selectProperty = (action: string): void => {
      if (action === 'next') {
        chooseProperty(nextProperty.value)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value)
      } else if (action === 'clickOk') {
        dialog.value = false
        colorDialog.value = false
        numberDialog.value = false
      }
    }

    const getPropertyDialogTitle = (): string => {
      return root.$t(`master.${toSnakeCase(setPropertyName.value)}.title`) as string
    }
    const getNumberDialogTitle = (): string => {
      return root.$t(`master.${toSnakeCase(setPropertyName.value)}`) as string
    }

    const update = (action: string, value: Ref<CommonProperty> | number | Item) => {
      const key = setPropertyName.value
      if (key === 'item') {
        itemSelectVariety.value = (value as Item).id
      }
      if (key === 'color') {
        breakdown[key] = (value as Ref<CommonProperty>).value
      } else {
        breakdown[key] = value
      }

      emit('on-updated', breakdown, index)
      selectProperty(action)
    }
    const remove = () => {
      emit('on-removed', index)
    }

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData = $store.state.common.newDataMaster
      // emit('update-item-select-variety', 1)
      // itemSelectVariety.value = 1
      switch (mode) {
        case 'item':
          items.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          groupDataMaster.value = varietiesByItem.value
          itemSelectVariety.value = Number($store.state.common.newDataMaster.id)
          break
        case 'variety':
          listID = varieties.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.push({ ...newData })
          }
          break
        case 'size':
          listID = sizes.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'quality':
          listID = qualities.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, idx: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[idx].qualities.push({ ...newData })
              }
              return null
            })
            qualities.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        default:
      }
      chooseProperty(setPropertyName.value)
      update('next', newData)
    }

    onMounted(async () => {
      await getData()
    })

    return {
      numberDialog,
      colorDialog,
      dialog,
      setPropertyName,
      chooseProperty,
      choosePropertyItems,
      selectProperty,
      getPropertyDialogTitle,
      getNumberDialogTitle,
      update,
      remove,
      groupDataMaster,
      getNewData,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      itemSelectVariety
    }
  }
})

export default BreakdownContainer
