import { chain, sumBy } from 'lodash'
import { i18n } from 'plugins'

/* eslint-disable @typescript-eslint/no-explicit-any */
const getTotalProperties = (values: any, properties: Array<string>): any => {
  const obj = {}
  properties.forEach((property) => {
    Object.assign(obj, { [property]: sumBy(values, property) })
  })

  return obj
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const getIdLevel = (values: any, groupProperty: string, level: number): any => {
  // idLevel is id which is used to group
  // Ex: group by customer is idLevel = customerId
  let idLevel = null
  let data = values
  for (let i = level; i < 3; i += 1) {
    if (data.length !== 0) {
      if (i !== 2) {
        data = data[0].children
      }
      if (groupProperty === 'customerName') {
        idLevel = data[0].customer?.id
      } else if (groupProperty === 'itemName') {
        idLevel = data[0].item?.id
      } else if (groupProperty === 'varietyName') {
        idLevel = data[0].variety?.id
      }
    }
  }
  return idLevel
}

export const groupMultiLevel = <T>(
  data: Array<T>,
  totalProperties: Array<string> = [],
  groupProperties: Array<string> = ['itemName', 'varietyName', 'qualitySizeName']
): any => {
  const formatData = data.map((item: any) => {
    const qualitySizeName =
      `${item.quality?.name || ''}${item.size?.name || ''}` || (i18n.t('order.no_size') as string)

    return {
      qualitySizeName,
      itemName: item?.itemName || item.item?.name || (i18n.t('order.no_item') as string),
      varietyName:
        item?.varietyName || item.variety?.name || (i18n.t('order.no_variety') as string),
      ...item
    }
  })

  return chain(formatData)
    .groupBy(groupProperties[0])
    .mapValues((values) =>
      chain(values)
        .groupBy(groupProperties[1])
        .mapValues((valuesSize) =>
          chain(valuesSize)
            .groupBy(groupProperties[2])
            .map((value, key) => ({
              [groupProperties[2]]: key,
              children: value,
              ...getTotalProperties(value, totalProperties),
              idLevel: getIdLevel(value, groupProperties[2], 2),
              isAssigned: !value.find((chi: any) => chi.isAssigned === false),
              isConfirmed: !value.find((chi: any) => chi.isConfirmed === false)
            }))
            .value()
        )
        .map((value, key) => ({
          [groupProperties[1]]: key,
          children: value,
          ...getTotalProperties(value, totalProperties),
          idLevel: getIdLevel(value, groupProperties[1], 1),
          isAssigned: !value.find((chi: any) => chi.isAssigned === false),
          isConfirmed: !value.find((chi: any) => chi.isConfirmed === false)
        }))
        .value()
    )
    .map((value, key) => ({
      [groupProperties[0]]: key,
      children: value,
      ...getTotalProperties(value, totalProperties),
      idLevel: getIdLevel(value, groupProperties[0], 0),
      isAssigned: !value.find((chi: any) => chi.isAssigned === false),
      isConfirmed: !value.find((chi: any) => chi.isConfirmed === false)
    }))
    .value()
}
