





















import { defineComponent } from '@vue/composition-api'

interface SetUp {
  sort: () => void
  drag: () => void
  cancel: () => void
}

const SortBottomSheet = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const sort = (): void => {
      emit('on-sort')
    }
    const drag = (): void => {
      emit('on-drag')
    }

    const cancel = (): void => {
      emit('on-cancel')
    }

    return { sort, drag, cancel }
  }
})

export default SortBottomSheet
