


































































































import { defineComponent, Ref, ref, watch } from '@vue/composition-api'
import { api } from 'plugins'
import { endpoints, showError } from 'utils'
import { StepButton } from 'components'

interface Unit {
  value: boolean
  name: string
}

interface Style {
  value: string
  name: string
}

const SettingViewDialog = defineComponent({
  components: {
    StepButton
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    // 本 <-> 箱
    isShowByBox: {
      type: Boolean,
      required: true
    },
    isShowTotal: {
      type: Boolean,
      required: true
    },
    isShowRemaining: {
      type: Boolean,
      required: true
    },
    page: {
      type: String,
      required: true
    },
    isMonthMode: {
      type: Boolean,
      required: false,
      default: false
    },
    daysBefore: {
      type: Number,
      required: false,
      default: 0
    },
    selectedValueType: {
      type: Number,
      required: false,
      default: 1
    },
    valueTypes: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props, { root, emit }) {
    const { $toast } = root
    const units: Array<Unit> = [
      { value: true, name: root.$t('setting.unit_default.box').toString() },
      { value: false, name: root.$t('setting.unit_default.stems').toString() }
    ]
    const styles: Array<Style> = [
      { value: 'total', name: root.$t('setting.value_default.total').toString() },
      { value: 'remaining', name: root.$t('setting.value_default.remaining').toString() },
      { value: 'all', name: root.$t('setting.value_default.total_and_remaining').toString() }
    ]

    const showDialogValue: Ref<boolean> = ref(false)
    const isShowByBoxValue: Ref<boolean> = ref(false)
    const isShowTotalValue: Ref<boolean> = ref(true)
    const isShowRemainingValue: Ref<boolean> = ref(false)
    const style: Ref<string> = ref('total')
    const isSaveAsDefault = ref(false)
    const selectedValueTypeValue: Ref<number> = ref(1)
    const daysBeforeValue: Ref<number> = ref(props.daysBefore)

    watch(props, () => {
      showDialogValue.value = props.showDialog
      isShowByBoxValue.value = props.isShowByBox
      if (props.isShowTotal && props.isShowRemaining) {
        style.value = 'all'
      } else if (props.isShowRemaining) {
        style.value = 'remaining'
      } else {
        style.value = 'total'
      }
      selectedValueTypeValue.value = props.selectedValueType
      daysBeforeValue.value = props.daysBefore
    })

    watch(style, () => {
      if (style.value === 'total') {
        isShowTotalValue.value = true
        isShowRemainingValue.value = false
      } else if (style.value === 'remaining') {
        isShowTotalValue.value = false
        isShowRemainingValue.value = true
      } else if (style.value === 'all') {
        isShowTotalValue.value = true
        isShowRemainingValue.value = true
      }
    })

    const openDialog = () => {
      emit('on-open')
    }
    const closeDialog = () => {
      emit('on-close')
    }
    const updateValue = async () => {
      if (isSaveAsDefault.value && props.page !== 'home') {
        try {
          const param = {
            is_show_by_box: isShowByBoxValue.value,
            is_show_total: isShowTotalValue.value,
            is_show_remaining: isShowRemainingValue.value
          }
          await api.put(`${endpoints.COMMON}update_setting/${props.page}`, param)
        } catch (e) {
          showError(e, $toast, root.$t('setting.error.update_default_setting_fail') as string)
        }
      }
      if (props.page !== 'home') {
        emit(
          'on-update',
          isShowTotalValue.value,
          isShowByBoxValue.value,
          isShowRemainingValue.value
        )
      } else {
        emit(
          'on-update',
          selectedValueTypeValue.value,
          isShowTotalValue.value,
          isShowByBoxValue.value,
          isShowRemainingValue.value,
          isSaveAsDefault.value,
          daysBeforeValue.value
        )
      }
    }

    return {
      showDialogValue,
      units,
      styles,
      isShowByBoxValue,
      isShowTotalValue,
      isShowRemainingValue,
      style,
      isSaveAsDefault,
      selectedValueTypeValue,
      daysBeforeValue,
      openDialog,
      updateValue,
      closeDialog
    }
  }
})
export default SettingViewDialog
