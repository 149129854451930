
























import { defineComponent, toRefs, Ref, ref, watch } from '@vue/composition-api'
import { ObjectWrapper } from 'typings'
import { orderBy } from 'lodash'
import DraggableContainer from '@/components/DraggableContainer/index.vue'

const MasterChangeOrderLists = defineComponent({
  components: {
    DraggableContainer
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props)
    const orderByNum: Ref<ObjectWrapper[]> = ref([])
    const orderByYomi: Ref<ObjectWrapper[]> = ref([])
    const items: Ref<ObjectWrapper[]> = ref([])

    // const recalculateOrderNum = (arr: ObjectWrapper[]): ObjectWrapper[] => {
    //   return arr.map((el, index) => {
    //     // eslint-disable-next-line no-param-reassign
    //     el.orderNum = index + 1
    //     return el
    //   })
    // }

    const separateData = () => {
      const byNum = items.value.filter((item: ObjectWrapper) => item.orderNum !== null)
      const byYomi = items.value.filter((item: ObjectWrapper) => item.orderNum === null)

      orderByNum.value = orderBy([...byNum], 'orderNum')
      orderByYomi.value = orderBy([...byYomi], 'yomi')
    }

    const updateData = (events: ObjectWrapper[]): void => {
      const updatedOrder = events.map((el, index) => {
        // eslint-disable-next-line no-param-reassign
        el.orderNum = index + 1
        return el as ObjectWrapper
      }) as ObjectWrapper[]
      orderByNum.value = JSON.parse(JSON.stringify(updatedOrder)) as ObjectWrapper[]
      const updated = orderByNum.value.concat(orderByYomi.value) as ObjectWrapper[]
      emit('input', updated)
    }

    const changeItemPriority = (itemID: number, priority: string): void => {
      const isGoUp = priority === 'up'
      const index = items.value.findIndex((e) => e.id === itemID)

      if (isGoUp) {
        // will be recalculate later
        items.value[index].orderNum = orderByNum.value.length + 1
      } else {
        items.value[index].orderNum = null
      }

      // orderByNum.value = [
      //   ...recalculateOrderNum(items.value.filter((item: ObjectWrapper) => item.orderNum !== null))
      // ]
      separateData()
    }

    const calculateHeight = (itemLength: number): number => {
      let height: number
      if (itemLength * 50 < window.innerHeight) {
        height = itemLength * 50
      } else {
        height = window.innerHeight
      }
      return height
    }

    watch(
      value,
      (newval, oldVal) => {
        if (newval !== oldVal) {
          items.value = newval as ObjectWrapper[]
          separateData()
        }
      },
      { immediate: true }
    )

    return {
      orderByNum,
      orderByYomi,
      items,
      changeItemPriority,
      updateData,
      calculateHeight
    }
  }
})

export default MasterChangeOrderLists
