



















































import { defineComponent } from '@vue/composition-api'

import StepButton from '../StepButton/index.vue'
import InputNumberIncDec from '../InputNumberIncDec/index.vue'

const ItemAssignFromPacking = defineComponent({
  components: {
    StepButton,
    InputNumberIncDec
  },
  props: {
    subtitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    showSub: {
      type: Boolean,
      required: false,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    mark: {
      type: Boolean,
      required: false,
      default: true
    },
    hideButtons: {
      type: Array,
      required: false,
      default: () => []
    },
    hideIconAction: {
      type: Boolean,
      required: false,
      default: false
    },
    justify: {
      type: String,
      required: false,
      default: 'end'
    },
    isDetail: {
      type: Boolean,
      required: false,
      default: false
    },
    page: {
      type: String,
      required: false,
      default: ''
    }
  }
})

export default ItemAssignFromPacking
