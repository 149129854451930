





















import { defineComponent } from '@vue/composition-api'

interface SetUp {
  closeDialog: (param: string) => void
}

const ConfirmMerge = defineComponent({
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    stems: {
      type: Number,
      required: true
    },
    customer: {
      type: String,
      required: true
    },
    item: {
      type: String,
      required: true
    },
    variety: {
      type: String,
      required: true
    },
    qualitySize: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const closeDialog = (param: string) => {
      emit('close-dialog', param)
    }

    return {
      closeDialog
    }
  }
})
export default ConfirmMerge
