



































































































import { defineComponent, ref, Ref, watch, onUpdated } from '@vue/composition-api'

interface componentsOfMethod {
  isCheck: boolean
  members: Array<number>
  sendToEmail: boolean
}
interface invoiceMethodType {
  email: componentsOfMethod
  data: componentsOfMethod
  fax: boolean
  download: boolean
}
interface SetUp {
  invoiceMethodData: Ref<invoiceMethodType>
}

const SettingsInvoiceMethodPanel = defineComponent({
  props: {
    customer: {
      type: Object,
      required: true
    },
    onClose: {
      type: Boolean,
      required: true
    },
    onLoad: {
      type: Boolean,
      required: false
    },
    listMemberData: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const invoiceMethodData = ref({
      email: {
        isCheck: false,
        sendToEmail: true,
        members: [] as number[]
      },
      data: {
        isCheck: false,
        sendToEmail: true,
        members: [] as number[]
      },
      fax: false,
      download: false
    })
    const emailDestination = ref({
      mail: '',
      data: ''
    })
    const INVOICE_EMAIL_SIGN = '0'
    const getInvoiceMethodData = () => {
      if (props.customer.invoiceMethod !== null) {
        if (props.customer.invoiceEmailDestination !== null) {
          emailDestination.value = props.customer.invoiceEmailDestination
        }
        const methodArray = props.customer.invoiceMethod
        if (methodArray) {
          if (methodArray.indexOf('mail') > -1) {
            invoiceMethodData.value.email.isCheck = true
            const emailArray = String(emailDestination.value.mail).split(',')
            invoiceMethodData.value.email.sendToEmail = emailArray.indexOf(INVOICE_EMAIL_SIGN) > -1
            for (let i = 0; i < emailArray.length; i += 1) {
              if (emailArray[i] !== INVOICE_EMAIL_SIGN)
                invoiceMethodData.value.email.members.push(parseInt(emailArray[i], 10))
            }
          }
          if (methodArray.indexOf('data') > -1) {
            invoiceMethodData.value.data.isCheck = true
            const emailArray = String(emailDestination.value.data).split(',')
            invoiceMethodData.value.data.sendToEmail = emailArray.indexOf(INVOICE_EMAIL_SIGN) > -1
            for (let i = 0; i < emailArray.length; i += 1) {
              if (emailArray[i] !== INVOICE_EMAIL_SIGN)
                invoiceMethodData.value.data.members.push(parseInt(emailArray[i], 10))
            }
          }
          if (methodArray.indexOf('fax') > -1) {
            invoiceMethodData.value.fax = true
          }
          if (methodArray.indexOf('download') > -1) {
            invoiceMethodData.value.download = true
          }
        }
      }
    }
    const clearInvoiceMethodData = () => {
      invoiceMethodData.value = {
        email: {
          isCheck: false,
          sendToEmail: true,
          members: [] as number[]
        },
        data: {
          isCheck: false,
          sendToEmail: true,
          members: [] as number[]
        },
        fax: false,
        download: false
      }
      emailDestination.value = {
        mail: '',
        data: ''
      }
    }
    const updateInvoiceMethodData = () => {
      const invoiceMethodsArray = [] as string[]
      if (
        invoiceMethodData.value.email.isCheck &&
        (invoiceMethodData.value.email.members.length !== 0 ||
          invoiceMethodData.value.email.sendToEmail)
      ) {
        invoiceMethodsArray.push('mail')
        const emailArray = [] as string[]
        if (invoiceMethodData.value.email.sendToEmail) emailArray.push(INVOICE_EMAIL_SIGN)
        for (let i = 0; i < invoiceMethodData.value.email.members.length; i += 1) {
          emailArray.push(invoiceMethodData.value.email.members[i].toString())
        }
        emailDestination.value.mail = emailArray.join()
      } else emailDestination.value.mail = ''
      if (
        invoiceMethodData.value.data.isCheck &&
        (invoiceMethodData.value.data.members.length !== 0 ||
          invoiceMethodData.value.data.sendToEmail)
      ) {
        invoiceMethodsArray.push('data')
        const emailArray = [] as string[]
        if (invoiceMethodData.value.data.sendToEmail) emailArray.push(INVOICE_EMAIL_SIGN)
        for (let i = 0; i < invoiceMethodData.value.data.members.length; i += 1) {
          emailArray.push(invoiceMethodData.value.data.members[i].toString())
        }
        emailDestination.value.data = emailArray.join()
      } else emailDestination.value.data = ''
      if (invoiceMethodData.value.fax) {
        invoiceMethodsArray.push('fax')
      }
      if (invoiceMethodData.value.download) {
        invoiceMethodsArray.push('download')
      }
      if (invoiceMethodsArray.length > 0) emit('update-invoice-method', invoiceMethodsArray)
      else emit('update-invoice-method', null)
      if (emailDestination.value.mail !== '' || emailDestination.value.data !== '')
        emit('update-invoice-email-destination', emailDestination.value)
      else emit('update-invoice-email-destination', null)
    }
    getInvoiceMethodData()
    watch(
      () => props.customer,
      () => {
        getInvoiceMethodData()
      }
    )
    watch(
      () => props.onClose,
      () => {
        if (!props.onClose) clearInvoiceMethodData()
      }
    )
    watch(
      () => props.onLoad,
      () => {
        if (props.onLoad) {
          clearInvoiceMethodData()
          getInvoiceMethodData()
        }
      }
    )
    watch(
      () => props.listMemberData,
      () => {
        if (props.listMemberData.length === 0) {
          invoiceMethodData.value.email.members = [] as number[]
          invoiceMethodData.value.data.members = [] as number[]
        }
      }
    )

    onUpdated(() => {
      updateInvoiceMethodData()
    })
    return {
      invoiceMethodData
    }
  }
})

export default SettingsInvoiceMethodPanel
