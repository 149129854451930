























































































import { defineComponent, toRefs, Ref, ref, getCurrentInstance, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { debounce } from 'lodash'

import { api } from 'plugins'
import { endpoints, showError, toCamelCase, toSnakeCase } from 'utils'
import SettingsInvoiceMethodPanel from '../SettingsInvoiceMethodPanel/index.vue'
import DialogContainer from '../DialogContainer/index.vue'
import MasterCustomerMember from '../MasterCustomerMember/index.vue'
import CustomerDetailInvoiceDialog from '../CustomerDetailInvoiceDialog/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  closeInvoiceDialog: () => void
  createCustomer: () => void
  updateCustomer: (cusID: number) => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  customerID: Ref<number | undefined>
  listMember: Ref<Array<string>>
  setListMember: (members: Array<string>) => void
  cusID: Ref<number>
  isShowInvoice: Ref<boolean>
}

const CustomerDialog = defineComponent({
  components: {
    DialogContainer,
    MasterCustomerMember,
    SettingsInvoiceMethodPanel,
    CustomerDetailInvoiceDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    customer: {
      type: Object,
      required: true,
      default: null
    },
    customerGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    closingGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    isAddCustomer: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { $toast, $store } = root
    const instance = getCurrentInstance()
    const { customer } = toRefs(props)
    const customerID = ref(undefined)
    const loading = ref(false)
    const deleteLoading = ref(false)
    const listMember = ref([] as any)
    const cusID = ref(0)
    const isShowInvoice = ref(false) // to open dialog edit detail invoice
    const toYomi = debounce(async (): Promise<void> => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${customer.value.name}`)
        customer.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    const closeDialog = (): void => {
      const observer = refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    // generate body to create
    const generateBody = () => {
      const {
        name,
        yomi,
        shortName,
        tel,
        fax,
        email,
        invoiceEmail,
        code,
        zipCode,
        address1,
        address2,
        invoiceMethod,
        invoiceEmailDestination,
        allowPreSale,
        customerGroup,
        theirCode,
        feeRate,
        closingGroupId
      } = customer.value
      if (!customerGroup) {
        $toast.error(root.$t('master.customer.select_customer_group'))
        return false
      }
      return {
        name: name.trim(),
        yomi: yomi ? yomi.trim() : null,
        shortName: shortName ? shortName.trim() : null,
        tel: tel ? tel.trim() : null,
        fax: fax ? fax.trim() : null,
        email: email ? email.trim() : null,
        invoiceEmail: invoiceEmail ? invoiceEmail.trim() : null,
        code: code || null,
        zipCode: zipCode ? zipCode.trim() : null,
        address1: address1 ? address1.trim() : null,
        address2: address2 ? address2.trim() : null,
        invoiceMethod,
        invoiceEmailDestination,
        allowPreSale,
        customerGroupId: customerGroup.id ? customerGroup.id : customerGroup,
        closingGroupId: closingGroupId || null,
        theirCode: theirCode ? theirCode.trim() : null,
        feeRate: feeRate || null
      }
    }

    // validate input
    const validateInput = async (): Promise<boolean> => {
      const validate = await (refs.observer as InstanceType<typeof ValidationObserver>).validate()
      return validate
    }

    // set list member from child
    const setListMember = (members: Array<string>) => {
      listMember.value = members
    }

    // UPDATE customer
    const updateCustomer = async (cId: number): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      const body = toSnakeCase(generateBody())
      if (!body) {
        return
      }
      loading.value = true
      try {
        await api.put(`${endpoints.CUSTOMERS}${customer.value.id ? customer.value.id : cId}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    // CREATE customer
    const createCustomer = async (): Promise<void> => {
      const validate = await validateInput()
      if (!validate) {
        return
      }

      const body = toSnakeCase(generateBody())
      if (!body) {
        return
      }
      loading.value = true
      try {
        if (props.isAddCustomer) {
          const { data } = await api.post(endpoints.CUSTOMERS, body)
          cusID.value = data.id
          $toast.success(root.$t('master.msg.create_successful'))
          $store.commit('setNewDataMaster', toCamelCase(data))
          emit('on-reload')
          closeDialog()
          // refreshCustomer()
        } else {
          await updateCustomer(cusID.value)
        }
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    // DELETE customer
    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.CUSTOMERS}${props.customer.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    // close edit detail invoice dialog
    const closeInvoiceDialog = () => {
      isShowInvoice.value = false
    }

    watch(
      () => customer.value,
      () => {
        customerID.value = customer.value.id
      }
    )

    return {
      toYomi,
      closeDialog,
      createCustomer,
      updateCustomer,
      confirmDelete,
      setListMember,
      closeInvoiceDialog,
      loading,
      deleteLoading,
      customerID,
      listMember,
      cusID,
      isShowInvoice
    }
  }
})

export default CustomerDialog
