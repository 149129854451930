






































































































































import { defineComponent, ref, Ref, watch } from '@vue/composition-api'
import { StepButton, MultiLevelList } from 'components'
import { urlPath } from 'utils'

interface SetUp {
  goToOrderDetail: (orderId: number, orderDetailId: number) => void
  panel: Ref<any>
  movePanelOrderListToState: (data: any) => void
}

const ShowGroupedOrder = defineComponent({
  props: {
    listData: {
      type: Array,
      required: true
    },
    listLevel: {
      type: Array,
      required: false
    },
    date: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      required: false
    }
  },
  components: {
    StepButton,
    MultiLevelList
  },
  setup(props, { emit, root }): SetUp {
    const { $router } = root
    const panel = ref({ ...props.value })

    const movePanelOrderListToState = (data: any) => {
      emit('input', data)
      emit('move-panel-order-list-to-state', data)
    }

    const goToOrderDetail = (orderId: number, orderDetailId: number) => {
      $router.push({
        name: urlPath.ORDER_DETAIL_FORM.name,
        params: {
          orderId: orderId.toString(),
          detailId: orderDetailId.toString()
        },
        query: {
          goTo: 'index',
          acdate: props.date.toString(),
          called: 'true'
        }
      })
    }

    watch(
      () => panel.value,
      () => {
        console.log(panel.value)
      }
    )

    return {
      goToOrderDetail,
      panel,
      movePanelOrderListToState
    }
  }
})

export default ShowGroupedOrder
