






























import { defineComponent, toRefs, Ref, ref, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, toCamelCase } from 'utils'
import { IQuality, QualitiesByQualityGroup } from 'typings'
import { debounce } from 'lodash'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  createQuality: () => void
  updateQuality: () => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  searchQualityGroup: (
    item: QualitiesByQualityGroup,
    queryText: string,
    itemText: string
  ) => boolean
}

const AddOrEditQualityDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    quality: {
      type: Object,
      required: true,
      default: null
    },
    qualityGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    isAddQuality: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { quality } = toRefs(props)
    const instance = getCurrentInstance()
    const { $toast, $store } = root

    const loading = ref(false)
    const deleteLoading = ref(false)

    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${quality.value.name}`)
        quality.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    // const refreshQuality = (): void => {
    //   const validateName = refs.name as InstanceType<typeof ValidationProvider>
    //   quality.value.name = ''
    //   quality.value.yomi = ''
    //   quality.value.nameEng = ''
    //   quality.value.shortName = ''
    //   validateName.reset()
    // }

    const closeDialog = (): void => {
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      validateName.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): IQuality => {
      const body: IQuality = {
        name: quality.value.name.trim(),
        qualityGroupId: quality.value.qualityGroup.id
          ? quality.value.qualityGroup.id
          : quality.value.qualityGroup
      }
      if (quality.value.code) {
        body.code = quality.value.code
      }
      if (quality.value.yomi) {
        body.yomi = quality.value.yomi.trim()
      }
      if (quality.value.nameEng) {
        body.nameEng = quality.value.nameEng.trim()
      }
      if (quality.value.shortName) {
        body.shortName = quality.value.shortName.trim()
      }
      return body
    }

    const createQuality = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        const { data } = await api.post(endpoints.QUALITIES, body)
        $toast.success(root.$t('master.msg.create_successful'))
        $store.commit('setNewDataMaster', toCamelCase(data))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const updateQuality = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.QUALITIES}${quality.value.id}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        closeDialog()
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.QUALITIES}${props.quality.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const searchQualityGroup = (
      item: QualitiesByQualityGroup,
      queryText: string,
      itemText: string
    ): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return itemText.trim().toLowerCase().indexOf(searchText) > -1
    }

    return {
      toYomi,
      closeDialog,
      createQuality,
      updateQuality,
      confirmDelete,
      loading,
      deleteLoading,
      searchQualityGroup
    }
  }
})

export default AddOrEditQualityDialog
