

























import { defineComponent, ref, watch } from '@vue/composition-api'

const SelectInputRow = defineComponent({
  props: {
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number],
      required: false
    },
    errors: {
      type: [String, Array],
      required: false
    },
    required: {
      type: Boolean,
      required: false
    },
    checkKey: {
      type: String,
      required: false,
      default: null
    },
    isShowCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    thisPage: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { root }) {
    const { $store } = root
    const dataCheck = ref([])

    const checkValue = ref(false)
    // console.log($store.state.listStates[props.thisPage], props.thisPage)
    if (props.isShowCheck) {
      dataCheck.value = $store.state.listStates[props.thisPage].filter((key: string) => {
        return props.checkKey.indexOf(key) !== -1
      })
    }
    checkValue.value = dataCheck.value.length !== 0
    watch(
      () => $store.state.listStates[props.thisPage],
      () => {
        if (props.isShowCheck) {
          dataCheck.value = $store.state.listStates[props.thisPage].filter((key: string) => {
            return props.checkKey.indexOf(key) !== -1
          })
          checkValue.value = dataCheck.value.length !== 0
        }
      }
    )

    const clickCheckKey = (key: string) => {
      checkValue.value = !checkValue.value
      const listStates = ref($store.state.listStates)
      const index = listStates.value[props.thisPage].indexOf(key)
      if (index === -1 && checkValue.value) {
        listStates.value[props.thisPage].push(key)
      }
      if (index > -1 && !checkValue.value) {
        listStates.value[props.thisPage].splice(index, 1)
      }
      $store.commit('setListStates', listStates.value)
    }
    return {
      clickCheckKey,
      checkValue
    }
  }
})

export default SelectInputRow
