




















import { defineComponent, toRefs, Ref, ref, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'

import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, toCamelCase } from 'utils'
import { IUnit } from 'typings'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  createUnit: () => void
  updateUnit: () => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
}

const AddOrEditUnitDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    unit: {
      type: Object,
      required: true,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    isAddUnit: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { $toast, $store } = root
    const instance = getCurrentInstance()
    const { unit } = toRefs(props)

    const loading = ref(false)
    const deleteLoading = ref(false)

    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${unit.value.name}`)
        unit.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update yomi  after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    // const refreshUnit = (): void => {
    //   const validateName = refs.name as InstanceType<typeof ValidationProvider>
    //   unit.value.name = ''
    //   unit.value.yomi = ''
    //   unit.value.shortName = ''
    //   validateName.reset()
    // }

    const closeDialog = (): void => {
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      validateName.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): IUnit => {
      const body: IUnit = {
        name: unit.value.name.trim()
      }
      if (unit.value.code) {
        body.code = unit.value.code
      }
      if (unit.value.yomi) {
        body.yomi = unit.value.yomi.trim()
      }
      if (unit.value.shortName) {
        body.shortName = unit.value.shortName.trim()
      }
      return body
    }

    const createUnit = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        const { data } = await api.post(endpoints.UNITS, body)
        $toast.success(root.$t('master.msg.create_successful'))
        $store.commit('setNewDataMaster', toCamelCase(data))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const updateUnit = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.UNITS}${unit.value.id}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.UNITS}${props.unit.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    return {
      toYomi,
      closeDialog,
      createUnit,
      updateUnit,
      confirmDelete,
      loading,
      deleteLoading
    }
  }
})

export default AddOrEditUnitDialog
