




































































































import { defineComponent, Ref, ref, watch, toRefs } from '@vue/composition-api'
import { debounce } from 'lodash'
import { convertToWidthCharacter } from 'utils'
import { Variety, VarietiesByItem, Size, SizesBySizeGroup } from 'typings'

import MasterItemList from '@/components/MasterItemComponent/MasterItemList.vue'
import SortDialog from '@/components/SortDialog/index.vue'
import MasterItemGroupList from '@/components/MasterItemGroupComponent/MasterItemGroupList.vue'
import ConfirmReset from '@/components/ConfirmReset/index.vue'
import SortActionButton from '@/components/SortActionButton/index.vue'
import MasterSpeedGroupDial from '@/components/MasterSpeedGroupDial/index.vue'
import LoaderComponent from '@/components/LoaderComponent/index.vue'
import MasterChangeOrderLists from '@/components/MasterChangeOrderLists/index.vue'
import AddButton from '@/components/AddButton/index.vue'
import MasterBottomSheet from '@/components/MasterBottomSheet/index.vue'

// type of child array (being grouped)
type Combine = Variety | Size

// type of group data
type GroupItem = VarietiesByItem | SizesBySizeGroup

interface SetUp {
  items: Ref<Array<GroupItem>>
  searchedItems: Ref<Array<GroupItem>>
  toggleActionButtonsValue: Ref<boolean>
  showSortDialog: Ref<boolean>
  searchInfo: Ref<string>
  showConfirmReset: Ref<boolean>
  isSorting: Ref<boolean>
  loading: Ref<boolean>
  selectedGroupIndex: Ref<number>
  selectedGroupItem: Ref<GroupItem | null>
  search: () => void
  endSorting: () => void
  cancelSorting: () => void
  handlerSortItemsInGroupAction: () => void
  handlerSortGroupsAction: () => void
  selectGroupToSort: (groupId: number) => void
  confirmReset: (param: string) => void
  openDialog: (item: Combine | null) => void
  toggleGroup: (groupIndex: number) => void
  openGroupDialog: (item: unknown | null) => void
  changeOrderNumLists: Ref<Array<unknown>>
  openGroupByIndex: (index: number) => void
}

const MasterItemGroupComponent = defineComponent({
  components: {
    ConfirmReset,
    SortActionButton,
    MasterSpeedGroupDial,
    LoaderComponent,
    MasterItemList,
    SortDialog,
    MasterItemGroupList,
    MasterChangeOrderLists,
    AddButton,
    MasterBottomSheet
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    searchedItemsProp: {
      type: Array,
      default: () => [],
      required: true
    },
    groupHasNoItemText: {
      type: String,
      default: ''
    },
    searchLabelText: {
      type: String,
      default: '',
      required: true
    },
    listSearchEmptyText: {
      type: String,
      default: '',
      required: true
    },
    groupItemName: {
      type: String,
      default: ''
    },
    loadingProp: {
      type: Boolean,
      default: false,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    isSortingProp: {
      type: Boolean,
      default: false,
      required: true
    },
    draggable: {
      type: Boolean,
      default: true
    },
    bottomSheetListButton: {
      type: Array,
      required: true,
      default: () => []
    },
    // if data is heavy, use this to avoid impact to performance
    useVueVirtualScroller: {
      type: Boolean,
      required: true,
      default: true
    },
    editGroupItemText: {
      type: String,
      default: ''
    },
    addChildItemText: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit, root }): SetUp {
    const { $toast } = root
    const searchInfo = ref('')

    const searchedItems: Ref<Array<GroupItem | any>> = ref([])
    const items: Ref<Array<GroupItem>> = ref([])
    const isSorting = ref(false)
    const toggleActionButtonsValue = ref(false)
    const showSortDialog = ref(false)
    const showConfirmReset = ref(false)
    const loading = ref(false)
    const { data, groupItemName } = toRefs(props)

    // index of chosen group
    const selectedGroupIndex: Ref<number> = ref(-1)

    const selectedGroupItem = ref<GroupItem | null>(null)

    // store the list to prop down to master-change-order-lists to change orderNum
    const changeOrderNumLists: Ref<Array<VarietiesByItem | SizesBySizeGroup>> = ref([])
    // check whether is sort groups or sort items in 1 group
    const isChangeOrderNumForGroups = ref(false)

    const search = debounce(async () => {
      // include search by halfwidth character (convert to fullwidth)
      const searchText = convertToWidthCharacter(searchInfo.value.trim().toLowerCase(), 'full')

      // not search any thing: return original list
      if (!searchText) {
        searchedItems.value = JSON.parse(JSON.stringify(items.value))
        return
      }

      const regex = new RegExp(`^${searchText}|\\|${searchText}`)
      const tempItems = JSON.parse(JSON.stringify(items.value))
      const filterByStartsWith = tempItems.filter((group: GroupItem) => {
        return regex.test(group.searchStr)
      })
      const filterByIncludes = tempItems.filter((group: GroupItem) => {
        let isExist = !!filterByStartsWith.find(
          (groupStartsWith: GroupItem) => group.id === groupStartsWith.id
        )
        if (isExist) {
          return false
        }
        if (group.searchStr.toLowerCase().includes(searchText)) {
          return true
        }

        const tempResultStartsWith = (
          group[groupItemName.value as keyof GroupItem] as unknown as []
        ).filter((item) => {
          const searchStr = (item as unknown as Combine).searchStr.toLowerCase()
          return regex.test(searchStr)
        })
        const tempResultIncludes = (
          group[groupItemName.value as keyof GroupItem] as unknown as []
        ).filter((item) => {
          const searchStr = (item as unknown as Combine).searchStr.toLowerCase()
          return !regex.test(searchStr) && searchStr.includes(searchText)
        })
        const tempResult = [...tempResultStartsWith, ...tempResultIncludes]
        // eslint-disable-next-line no-param-reassign
        group[groupItemName.value as keyof GroupItem] = tempResult as never
        isExist = !!filterByStartsWith.find(
          (groupStartsWith: GroupItem) => group.id === groupStartsWith.id
        )

        return tempResult.length > 0 && !isExist
      })
      searchedItems.value = [...filterByStartsWith, ...filterByIncludes]
      // console.log(searchedItems)
      // auto open first group if found
      // if (searchedItems.value.length > 0) {
      //   searchedItems.value[0].active = true
      // }
    }, 500)

    const startSorting = (): void => {
      toggleActionButtonsValue.value = false
      searchInfo.value = ''
      isSorting.value = true
    }

    const endSorting = (): void => {
      const body = changeOrderNumLists.value.map((el) => {
        return {
          id: el.id,
          orderNum: el.orderNum
        }
      })
      if (isChangeOrderNumForGroups.value) {
        emit('save-groups-order', body)
      } else {
        emit('save-items-order-in-group', body)
      }
      isSorting.value = false
    }

    const cancelSorting = (): void => {
      isSorting.value = false
      emit('on-reload')
    }

    const openDialog = (item: Combine | null): void => {
      toggleActionButtonsValue.value = false
      // edit item: emit with object value
      if (item) {
        emit('open-dialog', item)
      } else if (selectedGroupItem.value) {
        // else (add new item in an opening group): emit current opening group's id to set group field
        emit('open-dialog', selectedGroupItem.value.id)
      } else {
        // otherwise (add new item when not opening any groups)
        emit('open-dialog', 0)
      }
    }

    const confirmReset = async (param: string) => {
      showConfirmReset.value = false
      if (param === 'reset') {
        // return to its first state (get from items)
        if (isChangeOrderNumForGroups.value) {
          changeOrderNumLists.value = JSON.parse(JSON.stringify(items.value))
        } else if (selectedGroupItem.value) {
          changeOrderNumLists.value = JSON.parse(
            JSON.stringify(selectedGroupItem.value[groupItemName.value as keyof GroupItem])
          )
        }
        $toast.info(root.$t('Reset'))
      }
    }

    const handlerSortItemsInGroupAction = (): void => {
      toggleActionButtonsValue.value = false
      isChangeOrderNumForGroups.value = false
      if (selectedGroupIndex.value === -1) {
        showSortDialog.value = true
      } else {
        selectedGroupItem.value = JSON.parse(JSON.stringify(items.value[selectedGroupIndex.value]))
        if (selectedGroupItem.value) {
          changeOrderNumLists.value = JSON.parse(
            JSON.stringify(
              selectedGroupItem.value[
                groupItemName.value as keyof GroupItem
              ] as unknown as GroupItem
            )
          )
        }

        startSorting()
      }
    }

    const handlerSortGroupsAction = (): void => {
      toggleActionButtonsValue.value = false
      isChangeOrderNumForGroups.value = true
      changeOrderNumLists.value = JSON.parse(JSON.stringify(items.value))
      startSorting()
    }

    const selectGroupToSort = (groupId: number) => {
      selectedGroupIndex.value = items.value.findIndex((element) => element.id === groupId)
      // select group from items
      selectedGroupItem.value = JSON.parse(JSON.stringify(items.value[selectedGroupIndex.value]))
      if (selectedGroupItem.value) {
        changeOrderNumLists.value = JSON.parse(
          JSON.stringify(selectedGroupItem.value[groupItemName.value as keyof GroupItem])
        )
      }
      startSorting()
    }

    const openGroupByIndex = (index: number) => {
      searchedItems.value[index].opening = true
    }

    const toggleGroup = (groupIndex: number, isToggle = true): void => {
      // close all other item
      searchedItems.value.forEach((item: GroupItem | any, index: number) => {
        if (index !== groupIndex) {
          // eslint-disable-next-line no-param-reassign
          item.opening = false
        }
      })

      if (searchedItems.value[groupIndex].opening && isToggle) {
        // if opening (about to close), de-select group
        selectedGroupIndex.value = -1
        selectedGroupItem.value = null
      } else {
        // select group from items by index (searchedItems is different than the items)
        selectedGroupIndex.value = groupIndex
        const groupId = searchedItems.value[selectedGroupIndex.value].id
        const initItem = items.value.find((item) => item.id === groupId)
        selectedGroupItem.value = JSON.parse(JSON.stringify(initItem))
      }
    }

    const openGroupDialog = (item: unknown | null): void => {
      toggleActionButtonsValue.value = false
      if (item) {
        emit('open-group-dialog', item)
      } else {
        emit('open-group-dialog')
      }
    }

    watch(
      data,
      (value) => {
        items.value = JSON.parse(JSON.stringify(value)) as Array<GroupItem>
        items.value = items.value.map((item: GroupItem | any) => {
          // eslint-disable-next-line no-param-reassign
          item.opening = item.id === selectedGroupItem.value?.id
          return {
            ...item
          }
        })
        search()
      },
      {
        deep: true
      }
    )

    watch(
      () => props.loadingProp,
      (value) => {
        loading.value = value
      }
    )
    watch(
      () => props.isSortingProp,
      (value) => {
        isSorting.value = value
      }
    )

    return {
      items,
      toggleActionButtonsValue,
      searchInfo,
      searchedItems,
      showConfirmReset,
      loading,
      showSortDialog,
      isSorting,
      selectedGroupIndex,
      selectedGroupItem,
      openDialog,
      endSorting,
      search,
      confirmReset,
      cancelSorting,
      handlerSortItemsInGroupAction,
      handlerSortGroupsAction,
      selectGroupToSort,
      toggleGroup,
      openGroupDialog,
      changeOrderNumLists,
      openGroupByIndex
    }
  }
})

export default MasterItemGroupComponent
