


















import { defineComponent } from '@vue/composition-api'

const MenuGeneralWhenAssigned = defineComponent({
  props: {
    assignAll: {
      type: Boolean,
      required: false,
      default: false
    },
    showDialog: {
      type: Boolean,
      required: true
    },
    ordersCanNotAssign: {
      type: Number,
      required: false
    }
  }
})

export default MenuGeneralWhenAssigned
