var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dialog-container',{attrs:{"label":_vm.isAddCustomer ? _vm.$t('master.customer.add') : _vm.$t('master.customer.edit'),"mode":_vm.isAddCustomer ? 'create' : 'edit',"loading":_vm.loading,"delete-loading":_vm.deleteLoading,"is-full":_vm.showFullScreen},on:{"on-close":_vm.closeDialog,"on-create":_vm.createCustomer,"on-update":_vm.updateCustomer,"on-delete":_vm.confirmDelete},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{attrs:{"name":_vm.$t('master.customer.code'),"rules":{"regex":"^[0-9]*$"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.code'),"error-messages":errors},model:{value:(_vm.customer.code),callback:function ($$v) {_vm.$set(_vm.customer, "code", $$v)},expression:"customer.code"}})]}}],null,true)}),_c('v-select',{attrs:{"label":_vm.$t('master.customer.customer_group'),"items":_vm.customerGroups,"item-text":"name","item-value":"id"},model:{value:(_vm.customer.customerGroup),callback:function ($$v) {_vm.$set(_vm.customer, "customerGroup", $$v)},expression:"customer.customerGroup"}}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('master.customer.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","label":_vm.$t('master.customer.name'),"error-messages":errors},on:{"input":_vm.toYomi},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":_vm.$t('master.yomigana')},model:{value:(_vm.customer.yomi),callback:function ($$v) {_vm.$set(_vm.customer, "yomi", $$v)},expression:"customer.yomi"}}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('master.short_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","label":_vm.$t('master.short_name'),"error-messages":errors},model:{value:(_vm.customer.shortName),callback:function ($$v) {_vm.$set(_vm.customer, "shortName", $$v)},expression:"customer.shortName"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":_vm.$t('master.zip_code')},model:{value:(_vm.customer.zipCode),callback:function ($$v) {_vm.$set(_vm.customer, "zipCode", $$v)},expression:"customer.zipCode"}}),_c('v-text-field',{attrs:{"label":_vm.$t('master.their_code')},model:{value:(_vm.customer.theirCode),callback:function ($$v) {_vm.$set(_vm.customer, "theirCode", $$v)},expression:"customer.theirCode"}}),_c('v-text-field',{attrs:{"label":_vm.$t('master.address_1')},model:{value:(_vm.customer.address1),callback:function ($$v) {_vm.$set(_vm.customer, "address1", $$v)},expression:"customer.address1"}}),_c('v-text-field',{attrs:{"label":_vm.$t('master.address_2')},model:{value:(_vm.customer.address2),callback:function ($$v) {_vm.$set(_vm.customer, "address2", $$v)},expression:"customer.address2"}}),_c('validation-provider',{attrs:{"name":_vm.$t('master.customer.tel'),"rules":{"regex":"^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.tel'),"error-messages":errors},model:{value:(_vm.customer.tel),callback:function ($$v) {_vm.$set(_vm.customer, "tel", $$v)},expression:"customer.tel"}})]}}],null,true)}),_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.fax')},model:{value:(_vm.customer.fax),callback:function ($$v) {_vm.$set(_vm.customer, "fax", $$v)},expression:"customer.fax"}}),_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.email')},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}}),_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.email_invoice_destination')},model:{value:(_vm.customer.invoiceEmail),callback:function ($$v) {_vm.$set(_vm.customer, "invoiceEmail", $$v)},expression:"customer.invoiceEmail"}}),_c('validation-provider',{attrs:{"name":_vm.$t('master.customer.fee_rate'),"rules":{"regex":"^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('master.customer.fee_rate'),"error-messages":errors},model:{value:(_vm.customer.feeRate),callback:function ($$v) {_vm.$set(_vm.customer, "feeRate", $$v)},expression:"customer.feeRate"}})]}}],null,true)}),_c('v-select',{attrs:{"label":_vm.$t('master.customer.closing_group'),"items":_vm.closingGroups,"item-text":"name","item-value":"id"},model:{value:(_vm.customer.closingGroupId),callback:function ($$v) {_vm.$set(_vm.customer, "closingGroupId", $$v)},expression:"customer.closingGroupId"}}),(!_vm.isAddCustomer)?_c('master-customer-member',{attrs:{"customerID":_vm.customerID ? _vm.customerID : _vm.cusID},on:{"list-member":_vm.setListMember}}):_vm._e(),_c('br'),(!_vm.isAddCustomer)?_c('settings-invoice-method-panel',{attrs:{"onClose":_vm.show,"onLoad":_vm.loading,"listMemberData":_vm.listMember,"customer":_vm.customer},on:{"update-invoice-method":function($event){_vm.customer.invoiceMethod = $event},"update-invoice-email-destination":function($event){_vm.customer.invoiceEmailDestination = $event}}}):_vm._e(),_c('br'),(!_vm.isAddCustomer)?_c('div',{on:{"click":function($event){_vm.isShowInvoice = true}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-table-edit")]),_c('span',{staticStyle:{"color":"#1976d2"}},[_vm._v(_vm._s(_vm.$t('master.customer.edit_detail_invoice')))])],1):_vm._e(),_c('customer-detail-invoice-dialog',{attrs:{"is-show":_vm.isShowInvoice,"customer-prop":_vm.customer,"customer-id":_vm.customerID ? _vm.customerID : _vm.cusID},on:{"close-dialog":_vm.closeInvoiceDialog}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }