














import { defineComponent } from '@vue/composition-api'

interface Cell {
  text: string
  value: string
  icon: string
}

interface SetUp {
  onInput: (v: string) => void
  matrix: Array<Array<Cell>>
}

const Calculator = defineComponent({
  props: {
    layout: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const onInput = (v: string) => {
      if (!v) {
        return
      }
      emit('input', v)
    }
    const matrix = (() => {
      return props.layout.split('|').map((row) => {
        return row.split('_').map((cell) => {
          const cmd = cell.split(':')
          if (cmd.length === 1) {
            return {
              text: cmd[0],
              value: cmd[0],
              icon: ''
            }
          }
          if (cmd.length === 2) {
            return {
              text: cmd[1],
              value: cmd[0],
              icon: ''
            }
          }
          return {
            text: cmd[1],
            value: cmd[0],
            icon: cmd[2]
          }
        })
      })
    })()
    return {
      onInput,
      matrix
    }
  }
})
export default Calculator
