





























import { defineComponent } from '@vue/composition-api'

interface SetUp {
  save: () => void
  reset: () => void
  cancel: () => void
}

const SortActionButton = defineComponent({
  props: {
    showReset: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }): SetUp {
    const save = (): void => {
      emit('on-save')
    }
    const reset = (): void => {
      emit('on-reset')
    }
    const cancel = (): void => {
      emit('on-cancel')
    }

    return { save, reset, cancel }
  }
})

export default SortActionButton
