





















import { defineComponent, toRefs, Ref, ref, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'
import { api } from 'plugins'
import { endpoints, toSnakeCase, showError } from 'utils'
import { ISizeGroup } from 'typings'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  createSizeGroup: () => void
  updateSizeGroup: () => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
}

const SizeGroupDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    sizeGroup: {
      type: Object,
      required: true,
      default: null
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    isAddSizeGroup: {
      type: Boolean,
      required: true,
      default: true
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { sizeGroup } = toRefs(props)
    const instance = getCurrentInstance()
    const { $toast } = root
    const loading = ref(false)
    const deleteLoading = ref(false)

    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${sizeGroup.value.name}`)
        sizeGroup.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    // const refreshSizeGroup = (): void => {
    //   const validateName = refs.name as InstanceType<typeof ValidationProvider>
    //   sizeGroup.value.name = ''
    //   sizeGroup.value.yomi = ''
    //   sizeGroup.value.nameEng = ''
    //   sizeGroup.value.shortName = ''
    //   validateName.reset()
    // }

    const closeDialog = (): void => {
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      validateName.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): ISizeGroup => {
      const body: ISizeGroup = {
        name: sizeGroup.value.name.trim()
      }
      if (sizeGroup.value.yomi) {
        body.yomi = sizeGroup.value.yomi.trim()
      }
      if (sizeGroup.value.code) {
        body.code = sizeGroup.value.code
      }
      if (sizeGroup.value.nameEng) {
        body.nameEng = sizeGroup.value.nameEng.trim()
      }
      if (sizeGroup.value.shortName) {
        body.shortName = sizeGroup.value.shortName.trim()
      }
      return body
    }

    const createSizeGroup = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.post(endpoints.SIZE_GROUPS, body)
        $toast.success(root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const updateSizeGroup = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.SIZE_GROUPS}${sizeGroup.value.id}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.SIZE_GROUPS}${props.sizeGroup.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    return {
      toYomi,
      closeDialog,
      createSizeGroup,
      updateSizeGroup,
      confirmDelete,
      loading,
      deleteLoading
    }
  }
})

export default SizeGroupDialog
