


































import { defineComponent, ref } from '@vue/composition-api'

const MasterGroupBottomSheet = defineComponent({
  props: {
    bottomSheetListButton: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const show = ref(false)
    const emitAction = (action: string): void => {
      show.value = false
      emit(action)
    }

    return {
      show,
      emitAction
    }
  }
})

export default MasterGroupBottomSheet
