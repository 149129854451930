
























import { defineComponent, ref, Ref, watch } from '@vue/composition-api'
import { moment } from 'plugins'

interface ClassType {
  'value-type_previous': boolean
}

interface SetUp {
  clickItem: () => void
  datesText: Ref<string>
  getDatesText: () => void
  getPreviousDay: (value: string) => ClassType
}

const ItemListDate = defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit, root }): SetUp {
    const datesText = ref('')

    const clickItem = (): void => {
      if (props.item.date_to >= moment(new Date()).format('YYYY-MM-DD')) {
        emit('click-item')
      }
    }

    const getPreviousDay = (value: string) => {
      return {
        'value-type_previous': value < moment(new Date()).format('YYYY-MM-DD')
      }
    }

    const getDatesText = () => {
      datesText.value =
        props.item.date_from === props.item.date_to
          ? // eslint-disable-next-line prefer-template
            moment(props.item.date_from).format(root.$t('common.time_month_date').toString())
          : // eslint-disable-next-line prefer-template
            moment(props.item.date_from).format(root.$t('common.time_month_date').toString()) +
            '~' +
            moment(props.item.date_to).format(root.$t('common.time_month_date').toString())
      return datesText.value
    }
    datesText.value = getDatesText()

    watch(
      () => props.item,
      () => {
        datesText.value = getDatesText()
      }
    )

    return {
      clickItem,
      datesText,
      getDatesText,
      getPreviousDay
    }
  }
})

export default ItemListDate
