import { watch, Ref } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'

import { showError, Toast } from 'utils'
import { Error } from 'typings'

// Watch error value and show messages error
export const useShowError = (
  toast: Toast,
  error: Ref<Error>,
  mess?: string | TranslateResult
): void => {
  const handleError = (e: Error) => {
    showError(e, toast, mess)
  }

  watch(error, handleError)
}
