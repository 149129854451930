








import { defineComponent, ref, watch, Ref } from '@vue/composition-api'
import { HeaderBar, Drawer } from 'components'
import { api, framebus } from 'plugins'
import { endpoints, showError, toCamelCase, urlPath, frameBusEvent } from 'utils'
import { Auth } from 'aws-amplify'
import Store from './store'
import { memberRole } from './utils/constants'

interface SetUp {
  enableGoBack: Ref<boolean>
  drawer: boolean
  start: Ref<boolean>
}

const App = defineComponent({
  components: {
    HeaderBar,
    Drawer
  },
  props: {},
  setup(props, { root }): SetUp {
    const { $store, $toast } = root
    const enableGoBack = ref(false)
    const drawer = false
    const start = ref(true)
    const varietiesByItemData = ref<any[]>([])
    const inIframe = (): boolean => {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }
    framebus.on(frameBusEvent.ADD_FRAME, () => {
      if (!inIframe()) {
        framebus.emit(frameBusEvent.ITEM_VARIETY, {
          contents: $store.state.common.allVarietyByItem
        })
        framebus.emit(frameBusEvent.DATE, $store.state.common.latestSelectedDates)
        framebus.emit(frameBusEvent.SETTING_MASTER, $store.state.common.settingMaster)
      }
    })
    // wait event item_variety submit from another iframe
    framebus.on(frameBusEvent.ITEM_VARIETY, (data: any) => {
      $store.commit('setAllVarietyByItem', data.contents)
    })
    // wait event update date (auction, paking) submit from another iframe
    framebus.on(frameBusEvent.DATE, (data: any) => {
      $store.commit('setLatestSelectedDates', data)
    })
    const getItemVariety = async () => {
      let token
      try {
        token = await Auth.currentSession().then((res) => {
          const accessToken = res.getAccessToken()
          return accessToken.getJwtToken()
        })
      } catch (e) {
        console.log(e)
      }
      if (token) {
        try {
          const currentUser = await Store.getters.getUserInfo
          if (currentUser.memberRole === memberRole.ROLE_ADMINISTRATOR) {
            const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
            varietiesByItemData.value = toCamelCase(data)
            // eslint-disable-next-line no-restricted-globals
            // parent.postMessage(varietiesByItemData.value, '*')
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItemData.value
            })
          }
        } catch (e) {
          showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
        }
      }
    }

    if ($store.state.common.allVarietyByItem.length === 0 && !inIframe()) {
      getItemVariety()
    }

    const getSettings = async (): Promise<void> => {
      try {
        const { data } = await api.get(endpoints.SETTINGS)
        $store.commit('setSettingMaster', data)
        framebus.emit(frameBusEvent.SETTING_MASTER, data)
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }
    // wait event update setting submit from another iframe
    framebus.on(frameBusEvent.SETTING_MASTER, (data: any) => {
      $store.commit('setSettingMaster', data)
    })

    if ($store.state.common.settingMaster.length === 0) {
      getSettings()
    }

    watch(
      () => root.$route,
      () => {
        const { path } = root.$route
        start.value = path !== '/'

        const isMainUrl = urlPath.DISABLE_NAVIGATION.find((url) => url === path)
        enableGoBack.value = !isMainUrl
      }
    )

    return {
      enableGoBack,
      drawer,
      start
    }
  }
})
export default App
