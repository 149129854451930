import { render, staticRenderFns } from "./MasterItemGroupList.vue?vue&type=template&id=f4fe384e&scoped=true&lang=pug&"
import script from "./MasterItemGroupList.vue?vue&type=script&lang=ts&"
export * from "./MasterItemGroupList.vue?vue&type=script&lang=ts&"
import style0 from "./MasterItemGroupList.vue?vue&type=style&index=0&id=f4fe384e&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4fe384e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomSheet,VBtn,VCol,VDivider,VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VRow,VSheet,VSpacer})
