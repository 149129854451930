
















































import { defineComponent, ref, Ref } from '@vue/composition-api'
// import { AuctionDateEditDialog } from 'components'
// import { ItemListDate } from 'components'
import { AddButton, DialogContainer, LoaderComponent } from 'components'
import { api } from 'plugins'
import { endpoints } from 'utils'

// interface ItemType {
//   id: string
//   from: string
//   to: string
//   note: string
// }

interface SetUp {
  show: Ref<boolean>
  showingPrevious: Ref<boolean>
  listNoSaleDate: Ref<Array<Record<string, any>>>
  openItemDialog: (item: any) => void
  dialogValue: Record<string, any>
  createNoSaleDate: () => void
  clearProps: () => void
  isAddNoSaleDate: Ref<boolean>
  getNoSaleDate: () => void
  getFivePrevious: () => void
  numberOfClickTimes: Ref<number>
  useGetNoSaleDate: () => void
  previousLoading: Ref<boolean>
  hideLoading: Ref<boolean>
  loading: Ref<boolean>
  closeDialog: () => void
}

const MasterNoSaleDate = defineComponent({
  components: {
    AddButton,
    DialogContainer,
    LoaderComponent,
    ItemListDate: () => import('../ItemListDate/index.vue'),
    AuctionDateEditDialog: () => import('../AuctionDateEditDialog/index.vue')
  },
  setup(): SetUp {
    const show = ref(false) // check to show dialog
    const showingPrevious = ref(false) // check showing previous status for hide/unhide button
    const isAddNoSaleDate = ref(true) // check create/edit case
    const dialogValue = ref({}) // 1 item obj has been choose
    const listNoSaleDate = ref([]) // list to display no sale date
    const numberOfClickTimes = ref(0) // times to click "5 day previous"
    const previousLoading = ref(false)
    const hideLoading = ref(false)
    const loading = ref(false)

    // click each item: open 1 item has been choose dialog + get this item info
    const openItemDialog = (item: any | null) => {
      if (item) {
        dialogValue.value = item
        isAddNoSaleDate.value = false
      } else {
        dialogValue.value = {}
        isAddNoSaleDate.value = true
      }
      show.value = true
    }

    // click Create button to create new item
    const createNoSaleDate = (): void => {
      show.value = true
      dialogValue.value = {}
      isAddNoSaleDate.value = true
    }

    const clearProps = () => {
      dialogValue.value = {}
    }

    const getNoSaleDate = async () => {
      if (showingPrevious.value === true) {
        hideLoading.value = true
      }
      loading.value = true
      try {
        const data = await api.get(endpoints.NOSALE_DATE)
        listNoSaleDate.value = data.data
        showingPrevious.value = false
      } catch (e) {
        console.log(e)
      } finally {
        hideLoading.value = false
        loading.value = false
      }
    }
    getNoSaleDate()

    const getFivePrevious = async () => {
      previousLoading.value = true
      await getNoSaleDate()
      const previousDay = numberOfClickTimes.value * 5 + 5
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}?number_of_previous=${previousDay}`)
        listNoSaleDate.value = [...data.data.reverse(), ...listNoSaleDate.value] as any
        showingPrevious.value = true
        numberOfClickTimes.value += 1
      } catch (e) {
        console.log(e)
      } finally {
        previousLoading.value = false
      }
    }

    const useGetNoSaleDate = () => {
      getNoSaleDate()
      numberOfClickTimes.value = 0
    }

    const closeDialog = () => {
      show.value = false
      getNoSaleDate()
    }

    return {
      show,
      listNoSaleDate,
      openItemDialog,
      dialogValue,
      createNoSaleDate,
      clearProps,
      isAddNoSaleDate,
      getNoSaleDate,
      getFivePrevious,
      showingPrevious,
      numberOfClickTimes,
      useGetNoSaleDate,
      previousLoading,
      hideLoading,
      loading,
      closeDialog
    }
  }
})

export default MasterNoSaleDate
