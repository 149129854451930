
























































import {
  defineComponent,
  ref,
  toRefs,
  onMounted,
  getCurrentInstance,
  watch
} from '@vue/composition-api'

import { showError, endpoints, toCamelCase } from 'utils'
import { api } from 'plugins'
import { DefaultUnit, Unit } from 'typings'

const MaterialSelectUnitAndDefaultUnit = defineComponent({
  props: {
    isShow: {
      type: Boolean,
      required: true
    },
    defaultUnit: {
      type: Object,
      required: true
    },
    unitProp: {
      type: Array,
      required: false,
      default: null
    }
  },
  setup(props, { emit, root }) {
    const instance = getCurrentInstance()
    const { $toast } = root
    const units = ref<Array<DefaultUnit>>([])
    const { defaultUnit } = toRefs(props)
    const defaultProp = ref(0)
    const isSelectAll = ref(false)
    const checkBoxId = ref([])
    const close = () => {
      emit('on-close')
    }
    const setCheckById = (id: number, value: boolean) => {
      let count = 0
      units.value.forEach((e: DefaultUnit, index: number) => {
        if (e.id === id) {
          units.value[index].isCheck = value
        }
        if (e.isCheck) {
          count += 1
        }
      })
      isSelectAll.value = count === units.value.length
      instance?.proxy?.$forceUpdate()
    }
    const clickCheckBox = (unit: DefaultUnit) => {
      setCheckById(unit.id, unit.isCheck)
    }
    const getUnitsCheck = () => {
      const result = ref<Array<DefaultUnit>>([])
      units.value.forEach((e: DefaultUnit, index: number) => {
        if (e.isCheck) {
          result.value.push(units.value[index])
        }
      })
      return result.value
    }
    const getNameById = (data: Array<DefaultUnit>, id: number) => {
      const result = data.find((e: DefaultUnit) => e.id === id)
      return {
        id: result?.id ? result.id : null,
        name: result ? result?.name : ''
      }
    }
    const onClickOk = () => {
      emit('on-save', {
        units: getUnitsCheck(),
        default: getNameById(units.value, defaultProp.value)
      })
    }
    const clickRadio = (id: number) => {
      defaultProp.value = id
    }
    const selectAll = () => {
      if (isSelectAll.value) {
        units.value.forEach((e: DefaultUnit) => {
          e.isCheck = true
        })
      } else {
        units.value.forEach((e: DefaultUnit) => {
          e.isCheck = false
        })
      }
    }
    const resetCheckUnit = () => {
      units.value.forEach((unit: DefaultUnit, index: number) => {
        units.value[index].isCheck = false
      })
    }
    const getUnits = async () => {
      try {
        const { data } = await api.get(`${endpoints.MATERIAL_UNITS}`)
        units.value = toCamelCase(data)
        resetCheckUnit()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      }
    }

    const getCheckUnit = () => {
      units.value.forEach((unit: DefaultUnit, index: number) => {
        units.value[index].isCheck = false

        const unitsProp = props.unitProp as Array<Unit>
        unitsProp.forEach((e: Unit) => {
          if (e.id === unit.id) {
            units.value[index].isCheck = true
          }
        })
      })
    }

    const getValueSelectAllButton = () => {
      const unitsCheked = units.value.filter((e: DefaultUnit) => e.isCheck)
      isSelectAll.value = unitsCheked.length === units.value.length
    }

    watch(
      () => props.isShow,
      () => {
        if (props.isShow) {
          defaultProp.value = defaultUnit.value.id
          if (props.unitProp) {
            getCheckUnit()
          } else {
            resetCheckUnit()
          }
          getValueSelectAllButton()
        }
      }
    )

    onMounted(getUnits)

    return {
      close,
      clickCheckBox,
      onClickOk,
      clickRadio,
      isSelectAll,
      selectAll,
      units,
      checkBoxId,
      defaultProp
    }
  }
})

export default MaterialSelectUnitAndDefaultUnit
