





























import { defineComponent, toRefs, Ref, ref, getCurrentInstance } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { debounce } from 'lodash'
import { api } from 'plugins'
import { endpoints, toSnakeCase, showError, toCamelCase } from 'utils'
import { ISize, SizesBySizeGroup } from 'typings'
import DialogContainer from '../DialogContainer/index.vue'

interface SetUp {
  toYomi: () => void
  closeDialog: () => void
  createSize: () => void
  updateSize: () => void
  confirmDelete: (param: string) => void
  loading: Ref<boolean>
  deleteLoading: Ref<boolean>
  searchSizeGroup: (item: SizesBySizeGroup, queryText: string, itemText: string) => boolean
}

const AddOrEditSizeDialog = defineComponent({
  components: {
    DialogContainer
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    size: {
      type: Object,
      required: true,
      default: null
    },
    sizeGroups: {
      type: Array,
      required: true,
      default: () => []
    },
    isAddSize: {
      type: Boolean,
      required: true,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { root, emit, refs }): SetUp {
    const { size } = toRefs(props)
    const instance = getCurrentInstance()
    const { $toast, $store } = root
    const loading = ref(false)
    const deleteLoading = ref(false)
    const toYomi = debounce(async () => {
      try {
        const response = await api.get(`${endpoints.TO_YOMI}?text=${size.value.name}`)
        size.value.yomi = response.data
      } catch (err) {
        console.log(err)
      }
      // force update after input
      instance?.proxy?.$forceUpdate()
    }, 300)

    // const refreshSize = (): void => {
    //   const validateName = refs.name as InstanceType<typeof ValidationProvider>
    //   size.value.name = ''
    //   size.value.yomi = ''
    //   size.value.nameEng = ''
    //   size.value.shortName = ''
    //   validateName.reset()
    // }

    const closeDialog = (): void => {
      const validateName = refs.name as InstanceType<typeof ValidationProvider>
      validateName.reset()
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    const generateBody = (): ISize => {
      const body: ISize = {
        name: size.value.name.trim(),
        sizeGroupId: size.value.sizeGroup.id ? size.value.sizeGroup.id : size.value.sizeGroup
      }
      if (size.value.code) {
        body.code = size.value.code
      }
      if (size.value.yomi) {
        body.yomi = size.value.yomi.trim()
      }
      if (size.value.nameEng) {
        body.nameEng = size.value.nameEng.trim()
      }
      if (size.value.shortName) {
        body.shortName = size.value.shortName.trim()
      }
      return body
    }

    const createSize = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        const { data } = await api.post(endpoints.SIZES, body)
        $toast.success(root.$t('master.msg.create_successful'))
        $store.commit('setNewDataMaster', toCamelCase(data))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.create_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const updateSize = async (): Promise<void> => {
      const validate = await (refs.name as InstanceType<typeof ValidationProvider>).validate()

      if (!validate.valid) {
        $toast.error(root.$t('master.msg.save_name_empty'))
        return
      }

      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.SIZES}${size.value.id}`, body)
        $toast.success(root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.update_failed') as string)
      } finally {
        loading.value = false
      }
    }

    const confirmDelete = async () => {
      deleteLoading.value = true
      try {
        await api.delete(`${endpoints.SIZES}${props.size.id}`)
        $toast.success(root.$t('master.msg.delete_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.delete_failed') as string)
      } finally {
        deleteLoading.value = false
      }
    }

    const searchSizeGroup = (
      item: SizesBySizeGroup,
      queryText: string,
      itemText: string
    ): boolean => {
      const searchText = queryText.trim().toLowerCase()
      return itemText.trim().toLowerCase().indexOf(searchText) > -1
    }

    return {
      toYomi,
      closeDialog,
      createSize,
      updateSize,
      confirmDelete,
      loading,
      deleteLoading,
      searchSizeGroup
    }
  }
})

export default AddOrEditSizeDialog
