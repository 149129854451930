






























import { defineComponent } from '@vue/composition-api'
import { backPage } from 'utils'

interface SetUp {
  goBack: () => void
  getSubTitle: (subtitle: string) => string
  getClass: (routerName: string) => string
}

const HeaderBar = defineComponent({
  props: {
    back: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(): SetUp {
    const goBack = (): void => {
      backPage()
    }

    const getSubTitle = (subtitle: string): string => {
      const { length } = subtitle

      return length > 25 ? `${subtitle.substring(0, 25).trim()}...` : subtitle
    }
    const getClass = (routerName: string) => {
      const assignRouter = [
        'assign.title',
        'assign_order.title',
        'assign_seri.title',
        'assign.create_title'
      ]
      const orderRouter = ['order.menu_title', 'order.input']
      const harvestRouter = ['harvest.result.title', 'harvest.create_screen_title']
      const packingRouter = ['packing_result.date.title', 'packing_result.detail.title']
      const invoiceRouter = ['invoice.title']
      const masterRouter = [
        'master.customer.title',
        'master.title',
        'master.auction_date.title',
        'master.unit.title',
        'master.size.title',
        'master.item.title',
        'product',
        'master.box_type.title',
        'master.quick_input.title'
      ]
      if (assignRouter.includes(routerName)) return 'assign'
      if (orderRouter.includes(routerName)) return 'order'
      if (harvestRouter.includes(routerName)) return 'harvest'
      if (packingRouter.includes(routerName)) return 'packing-result'
      if (invoiceRouter.includes(routerName)) return 'invoice'
      if (masterRouter.includes(routerName)) return 'customer'
      return ''
    }
    return {
      goBack,
      getSubTitle,
      getClass
    }
  }
})
export default HeaderBar
