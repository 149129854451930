

































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import {
  convertToWidthCharacter,
  endpoints,
  getDatesText,
  query,
  setDateRange,
  showError,
  toCamelCase,
  toSnakeCase
} from 'utils'
import { api } from 'plugins'
import { StepButton, InputNumberIncDec, ChooseNumber, DateRange } from 'components'
import { Color } from 'typings'
import { debounce, orderBy } from 'lodash'
import MenuGeneralWhenAssigned from '../MenuGeneralWhenAssigned/index.vue'

const AssignOrderFromPacking = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    packingResultsProps: {
      type: Array,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    },
    packingEmit: {
      type: Object,
      required: true
    },
    assignments: {
      type: Array,
      required: true
    }
  },
  components: {
    StepButton,
    InputNumberIncDec,
    ChooseNumber,
    MenuGeneralWhenAssigned,
    DateRange
  },
  setup(props, { root, emit }) {
    const { $toast } = root
    const searchInfo = ref('')
    const loading = ref(false)
    const packedDateModel = ref(false)
    const packedDateStart = ref(props.startDate)
    const packedDateEnd = ref(props.endDate)
    const dateRange = ref(['', ''])
    const packingResults = ref<Array<any>>([])
    const packingShow = ref<Array<any>>([])
    const showNumberDialog = ref(false)
    const modeEdit = ref('')
    const packingBoxes = ref<any>({})
    dateRange.value = [packedDateEnd.value, packedDateStart.value]
    const orderDetail = ref<any>({})

    const datesText = computed(() => {
      return getDatesText(root, dateRange.value, packedDateStart.value, packedDateEnd.value)
    })

    const getNameProperties = (key: string): string => `assign_order.select_packing.${key}`

    const updatePackedDateStart = () => {
      if (packedDateStart.value > packedDateEnd.value) {
        packedDateEnd.value = packedDateStart.value
      }
    }

    const handleSearchInput = debounce((value) => {
      searchInfo.value = value
      const text = convertToWidthCharacter(value, 'full')
      packingShow.value = packingResults.value.filter((packingResult: any) => {
        const { item, variety, size, quality } = packingResult
        return (
          item.name.includes(text) ||
          variety.name.includes(text) ||
          size.name.includes(text) ||
          quality.name.includes(text)
        )
      })
    }, 500)

    const filerPacking = () => {
      return packingResults.value.map((packingResult: any) => {
        if (
          packingResult.item.id === props.data.item.id &&
          packingResult.variety.id === props.data.variety.id &&
          packingResult.quality.id === props.data.quality.id &&
          packingResult.size.id === props.data.size.id
        )
          return { ...packingResult, orderBy: 1 }
        if (
          packingResult.item.id === props.data.item.id &&
          packingResult.variety.id === props.data.variety.id &&
          packingResult.quality.id === props.data.quality.id &&
          !props.data.size.id
        )
          return { ...packingResult, orderBy: 2 }
        if (
          packingResult.item.id === props.data.item.id &&
          packingResult.variety.id === props.data.variety.id &&
          packingResult.size.id === props.data.size.id &&
          !props.data.quality.id
        )
          return { ...packingResult, orderBy: 3 }
        if (
          packingResult.item.id === props.data.item.id &&
          packingResult.variety.id === props.data.variety.id &&
          !props.data.size.id &&
          !props.data.quality.id
        )
          return { ...packingResult, orderBy: 4 }
        if (
          packingResult.item.id === props.data.item.id &&
          !props.data.variety.id &&
          packingResult.quality.id === props.data.quality.id &&
          packingResult.size.id === props.data.size.id
        )
          return { ...packingResult, orderBy: 5 }
        if (
          packingResult.item.id === props.data.item.id &&
          !props.data.variety.id &&
          !props.data.size.id &&
          packingResult.quality.id === props.data.quality.id
        )
          return { ...packingResult, orderBy: 6 }
        if (
          packingResult.item.id === props.data.item.id &&
          !props.data.variety.id &&
          packingResult.size.id === props.data.size.id &&
          !props.data.quality.id
        )
          return { ...packingResult, orderBy: 7 }
        if (
          packingResult.item.id === props.data.item.id &&
          !props.data.variety.id &&
          !props.data.size.id &&
          !props.data.quality.id
        )
          return { ...packingResult, orderBy: 9 }
        return { ...packingResult, orderBy: 10 }
      })
    }

    const getPackingResultByDate = async () => {
      const queryStr = query.buildQuery({
        start_date: packedDateStart.value,
        end_date: packedDateEnd.value
      })
      try {
        const { data } = await api.get(
          `${endpoints.PACKING_RESULTS}get_packing_result_by_range?${queryStr}`
        )
        packingResults.value = toCamelCase(data)
        packingShow.value = filerPacking()
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    const editAssign = (mode: string) => {
      modeEdit.value = mode
      showNumberDialog.value = true
    }

    const update = (action: string, value: string | number) => {
      if (modeEdit.value === 'buyerInfo') {
        orderDetail.value.buyerInfo = value
      } else {
        orderDetail.value.price = value
      }
      showNumberDialog.value = false
      emit('update-packing-emit', {
        price: orderDetail.value.price,
        buyerInfo: orderDetail.value.buyerInfo,
        boxes: packingBoxes.value
      })
    }

    const setDate = (start: string, end: string) => {
      packedDateStart.value = start
      packedDateEnd.value = end
    }

    const updateBoxes = (id: number, data: number) => {
      packingBoxes.value[id] = data
      emit('update-packing-emit', {
        price: orderDetail.value.price,
        buyerInfo: orderDetail.value.buyerInfo,
        boxes: packingBoxes.value
      })
    }

    const goToDate = async () => {
      await getPackingResultByDate()
    }

    const generateBody = (pck: any) => {
      return {
        auctionDate: props.auctionDate,
        buyerInfo: orderDetail.value.buyerInfo,
        customerId: props.data.customer.id,
        itemId: pck.item.id,
        varietyId: pck.variety.id,
        sizeId: pck.size.id,
        qualityId: pck.quality.id,
        colorIds: pck.colors ? pck.colors.map((color: Color) => color.id) : [],
        unitId: pck.unit.id,
        orderTypeId: props.data.orderType.id,
        boxTypeId: pck.boxType.id,
        quantity: pck.quantity,
        boxes: packingBoxes.value[pck.id],
        stems: pck.quantity * packingBoxes.value[pck.id],
        price: orderDetail.value.price,
        amount: orderDetail.value.price * pck.quantity * packingBoxes.value[pck.id],
        remark: pck.remark,
        orderDetailId: props.data.id,
        packingResultId: pck.id,
        breakdowns: []
      }
    }

    const createAssignWithoutBoxes = async () => {
      try {
        const ids = Object.keys(packingBoxes.value).map((id: string) => Number(id))
        const packingAssign = packingShow.value.filter((e: any) => {
          packingBoxes.value[e.id] =
            packingBoxes.value[e.id] === undefined ? 0 : packingBoxes.value[e.id]
          return (
            ids.indexOf(e.id) !== -1 &&
            packingBoxes.value[e.id] > 0 &&
            e.assignedBoxes + packingBoxes.value[e.id] <= e.boxes
          )
        })
        const dataBody = packingAssign.map((e: any) => toSnakeCase(generateBody(e)))
        const { data } = await api.post(`${endpoints.ASSIGNMENTS}create_from_packing`, {
          assignments: dataBody
        })
        emit('re-load')
        emit('on-close', true, data.length > 0)
        emit('update-packing-emit', null)
        $toast.success(root.$t('assign_order.assign_successful', { assigned: data.length }))
      } catch (e) {
        showError(e, $toast, root.$t('assign_order.create_failed') as string)
      }
    }

    watch(
      () => dateRange.value,
      () => {
        const result = setDateRange(dateRange.value)
        packedDateStart.value = result.dateStart
        packedDateEnd.value = result.dateEnd
      }
    )

    watch(
      () => props.value,
      () => {
        if (props.value) {
          let productName = ''
          searchInfo.value = ''
          if (props.data.variety.name) {
            productName = props.data.variety.name.startsWith(props.data.item.name)
              ? props.data.variety.name
              : `${props.data.item.name} ${props.data.variety.name}`
          } else {
            productName = props.data.item.name
          }
          productName = `${productName} ${props.data.quality.name}${props.data.size.name} ${props.data.quantity}${props.data.unit.name} ${props.data.boxType.name}`
          orderDetail.value = {
            product: productName,
            customer: props.data.customerName,
            buyerInfo: props.packingEmit[props.data.id]
              ? props.packingEmit[props.data.id].buyerInfo
              : props.data.buyerInfo,
            price: props.packingEmit[props.data.id]
              ? props.packingEmit[props.data.id].price
              : props.data.price,
            boxes: props.data.boxes,
            stems: props.data.stems,
            assigned: root.$t('assign_order.assigned', {
              boxes: props.data.assignBoxes,
              stems: props.data.assignStems
            })
          }
          packingBoxes.value = props.packingEmit[props.data.id]
            ? props.packingEmit[props.data.id].boxes
            : {}
          packedDateStart.value = props.startDate
          packedDateEnd.value = props.endDate
          packingResults.value = props.packingResultsProps
          packingShow.value = orderBy(filerPacking(), 'orderBy')
        }
      }
    )

    return {
      loading,
      datesText,
      packedDateModel,
      packedDateStart,
      packedDateEnd,
      dateRange,
      updatePackedDateStart,
      goToDate,
      updateBoxes,
      orderDetail,
      packingShow,
      filerPacking,
      packingResults,
      editAssign,
      showNumberDialog,
      modeEdit,
      update,
      createAssignWithoutBoxes,
      packingBoxes,
      setDate,
      getNameProperties,
      searchInfo,
      handleSearchInput
    }
  }
})

export default AssignOrderFromPacking
