import { ref, Ref, isRef, watch } from '@vue/composition-api'
import { Method } from 'axios'

import { api } from 'plugins'
import { toCamelCase, toSnakeCase } from 'utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMutation = <T>(method: Method, url: string | Ref<string>): Array<any> => {
  const data = ref<T | null>(null)
  const loading = ref(false)
  const error = ref(null)
  const urlPath = ref('')

  if (isRef(url)) {
    urlPath.value = url.value

    // if url is reactive
    watch(url, (value) => {
      urlPath.value = value
    })
  } else {
    urlPath.value = url
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callApi = async (body: any = null): Promise<any> => {
    loading.value = true
    try {
      const { data: result } = await api.request({
        method,
        url: urlPath.value,
        data: toSnakeCase(body)
      })
      data.value = toCamelCase(result)
    } catch (e) {
      error.value = e
      throw e
    } finally {
      loading.value = false
    }

    return data.value
  }

  return [callApi, { data, loading, error }]
}
