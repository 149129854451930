<template lang="pug">
  div
    v-row.pa-0.ma-0.mt-0
      v-col.pa-0.ma-0
        v-progress-linear(v-if="isLoading" :indeterminate='true')
        v-data-table.mb-1(
          ref="expandableTable"
          :headers="headers"
          :items="items"
          :expanded.sync="expanded"
          :item-key="itemKey"
          :show-expand="showExpand"
          :items-per-page="-1"
          :no-data-text="$t('common.no_data')"
          hide-default-footer
          hide-default-header
          class="elevation-1"
          mobile-breakpoint="0"
        )
          template(v-slot:header='{ props: { headers } }')
            thead
              tr.border-checkbox
                th(
                  v-for='(header, index) in headers', :key='index' :class="getClassHeader(header.align, header.width, header.value)"
                  :colspan="header.colspan"
                  )
                  slot(:name="`header_${header.value}`" :headers="headers")
                    span.font-size-large(:class="header.value !== 'start-table' && header.value !== 'end-table' ") {{header.text}}

          template(v-slot:item='{ item, expand, isExpanded, index, headers }')
            tr(@click="showExpand ? expand(!isExpanded) : ''" :class="item.active === false ? 'item--disable' : ''")
              td(
                v-for='(header, idx) in headers', :key='idx' :class="getClassHeader(header.align, header.width, header.value)"
                :colspan="header.colspan"
                )
                slot(v-if="header.value === 'data-table-expand'" :name="`item_${header.value}`" :index="index")
                  v-btn(icon)
                    v-icon
                      | {{ isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}

                slot(v-else :name="`item_${header.value}`" :item="item" :headers="headers" :isExpanded="isExpanded" :expand="expand" :index="index")
                  span {{ item[header.value] }}

          template(v-slot:expanded-item='{item }')
            slot(v-if="isHeaderExpand !== false" name="expand-header" :item="item")
              th.color-expand-header(
                v-for='(header, index) in headersExpand', :key='`expandedHeader_${item.customerName}_${index}`'
                :class="getClassHeader(header.align)"
                :colspan="header.colspan"
                )
                slot(:name="`expand_header_${header.value}`")
                  span.font-size-large {{header.text}}
            slot(:name="'expanded-item-body'" :item="item" :header="headersExpand")
              tr(v-for='(expandItem) in item.expandItems' :key='`expanded_${JSON.stringify(expandItem)}`')
                td(v-for='(header, index) in headers', :key='index' :class="header.value==='checkbox' ? 'small-width' : 'color-expand-body'")
                  slot(v-if="header.value === 'data-table-expand'" :name="`expand_${header.value}`" :item="expandItem" :header="headers")
                    v-menu(bottom left min-width="9vw")
                      template(v-slot:activator='{ on }')
                        v-btn(
                          color='primary',
                          dark,
                          class='mr-auto',
                          v-on='on',
                          icon,
                          max-height="20px"
                          max-width="20px"
                        )
                          v-icon mdi-dots-vertical
                  slot(v-else :name="`expand_${header.value}`" :item="expandItem" :header="header")
                    span {{ expandItem[header.value] }}
</template>
<script>
import { defineComponent } from '@vue/composition-api'

const ExpandableTable = defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    },
    headersExpand: {
      type: Array,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: false,
      default: 'id'
    },
    isHeaderExpand: {
      default: false
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const expanded = []
    const getClassHeader = (align, width, value) => {
      const classInit = {}
      if (align) classInit[`text-${align}`] = true
      if (width) classInit[`w${width}per`] = true
      classInit[`${value}-column`] = true
      return classInit
    }
    return {
      expanded,
      getClassHeader
    }
  }
})

export default ExpandableTable
</script>
<style lang="sass" scoped>
@import '@/styles/common.sass'
.text-start
  padding: 0
  text-align: start !important
.text-end
  padding: 0
  text-align: right !important
.text-center
  padding: 0
  text-align: center !important
.v-data-table td .v-btn
  width: 30px !important
  display: block
  margin-left: auto
::v-deep table > thead > tr > th
  background-color: #E6E6E6 !important
  border-color: white
  color: white !important
  font-size: 20px
  padding: 0 !important
  span
    color: black !important
    font-size: 16px
::v-deep table > tbody > tr > td
  padding: 0 !important
  font-size: 16px !important
.color-expand-header
  background-color: #bed8ff
.color-expand-body
  background-color: white
.small-width
  width: 80px
.checkbox-column
  width: 20px !important
.end_table-column
  width: 15px !important
  box-sizing: border-box
  padding: 0 !important
.start_table-column
  width: 15px !important
  box-sizing: border-box
  padding: 0 !important
::v-deep .v-btn.v-btn--disabled .v-icon
  color: #9e9e9e !important
::v-deep table > tbody > tr.item--disable:hover
  background-color: #eeeeee !important
::v-deep table > tbody > tr.item--disable
  background-color: #eeeeee !important
.border-checkbox i
  background-color: white !important
  border-radius: 5px
</style>
