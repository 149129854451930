























































import { defineComponent, ref, Ref, watch } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

import { readFile } from 'utils'
import { Image } from 'typings'

interface SetUp {
  isUpload: Ref<boolean>
  listImage: Ref<Array<Image>>
  selectImage: () => void
  uploadImages: () => Promise<void>
  removeImage: (image: Image) => void
}

const ImageList = defineComponent({
  props: {
    images: {
      type: Array,
      required: false,
      default: () => []
    },
    max: {
      type: Number,
      required: false,
      default: 100
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { root, refs, emit }): SetUp {
    const { $toast } = root
    const images = (props.images as Array<Image>).map((image: Image) => {
      return { ...image, token: uuidv4() }
    })

    const isUpload = ref(false)
    const listImage = ref(images)

    const selectImage = (): void => {
      const input = refs.input as HTMLInputElement
      if (isUpload.value === false) {
        input.click()
      }
    }

    const uploadImages = async (): Promise<void> => {
      const input = refs.input as HTMLInputElement
      const files = input.files ? Object.values(input.files) : []

      if (listImage.value.length + files.length > 4) {
        $toast.error(root.$t('maximum_number_photos'))
        return
      }

      const body = await Promise.all(
        files.map(async (file) => {
          const payload = await readFile(file)
          return {
            payload,
            id: null,
            token: uuidv4(),
            name: file.name,
            type: file.type,
            size: file.size,
            url: URL.createObjectURL(file)
          }
        })
      )
      listImage.value = [...listImage.value, ...body]
      emit('on-change', listImage.value)
    }

    const removeImage = (image: Image) => {
      const { token } = image
      listImage.value = listImage.value.filter((i: Image) => i.token !== token)
      emit('on-change', listImage.value)
      if (image.id) {
        emit('on-remove', image.id)
      }
    }

    const refresh = (): void => {
      listImage.value = []
    }

    watch(
      () => props.images,
      () => {
        if (props.images.length === 0) {
          refresh()
        }
      }
    )

    return { isUpload, listImage, selectImage, uploadImages, removeImage }
  }
})

export default ImageList
