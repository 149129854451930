







































import { defineComponent, Ref, ref } from '@vue/composition-api'
import { checkIsOnPC } from 'utils'
import { Color } from 'typings'

import ColorCircle from '../ColorCircle/index.vue'

interface SetUp {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  textField: any
  showDialog: Ref<boolean>
  isOnPC: boolean
  next: () => void
  prev: () => void
  colors: Array<Color>
  isSelectedColor: (item: Color) => boolean
  updateSelectedItem: (color: Color) => void
  onTyping: (input: any) => void
}

const ChooseColorSingle = defineComponent({
  components: {
    ColorCircle
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    selectedItem: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }): SetUp {
    const showDialog = ref(false)
    const colors = props.items as Array<Color>
    const selectedColor = ref<Color | null>(props.selectedItem as Color)
    const textField: any = ref(null)
    const isOnPC = checkIsOnPC()

    const isSelectedColor = (item: Color): boolean => {
      if (!selectedColor.value) return false
      return selectedColor.value.code === item.code
    }

    const next = () => {
      emit('on-change', 'next', selectedColor)
    }

    const prev = () => {
      emit('on-change', 'prev', selectedColor)
    }

    const updateSelectedItem = (color: Color) => {
      selectedColor.value = color.id === props.selectedItem?.id ? null : color
      if (isOnPC) {
        textField.value.focus()
      }
      next()
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 27) {
        // Presc ESC
        emit('input', false)
      } else if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        next()
      } else if (event.keyCode === 13 && event.shiftKey) {
        // Press shift + Enter
        prev()
      }
    }

    return {
      showDialog,
      next,
      prev,
      colors,
      isSelectedColor,
      updateSelectedItem,
      onTyping,
      textField,
      isOnPC
    }
  }
})

export default ChooseColorSingle
