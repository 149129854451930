






































import { ref, Ref, defineComponent, onMounted } from '@vue/composition-api'
import { Auth } from 'aws-amplify'
import { constants } from 'utils'
import { MENU, CHILD_GROWER_MENU } from './menu'

interface SubItem {
  title: string
  path?: string
}
interface Item {
  title: string
  icon: string
  path?: string
  children?: Array<SubItem>
}

interface SetUp {
  menus: Ref<Array<Item>>
  group: Ref<Array<string> | null>
  logout: () => void
}

const Drawer = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }): SetUp {
    const group = ref(null)
    const menus: Ref<Array<Item>> = ref([])
    const { $store } = root

    const getMenuByRole = async (): Promise<void> => {
      const userInfo = await $store.getters.getUserInfo
      const enableAdminMenu = userInfo.memberRole !== constants.memberRole.ROLE_CHILD_GROWER
      menus.value = enableAdminMenu ? MENU : CHILD_GROWER_MENU
    }

    const inIframe = (): boolean => {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }

    const logout = (): void => {
      if (inIframe()) {
        // eslint-disable-next-line no-restricted-globals
        parent.logout()
      } else {
        Auth.signOut().then(() => {
          $store.commit('resetInfoState')
          console.log('logout')
        })
      }
    }
    onMounted(getMenuByRole)

    return {
      menus,
      group,
      logout
    }
  }
})

export default Drawer
